import React, { useEffect, useState } from "react";
import "../../Subscription.scss";
import DataGrid from "../../../../../../../../Framework/Components/Common/DataGrid/DataGrid";
import Modal from "../../../../../../../../Framework/Components/Layout/Modal/Modal";
import UnAssignedSubscription from "./UnAssignedSubscription/UnAssignedSubscription";
import PageBar from "../../../../../../../../Framework/Components/Layout/Pagebar/PageBar";
import classNames from "classnames";

const AddSubscription = (props) => {
  const toggleAssignSubscriptionPopup = props.toggleAssignSubscriptionPopup;
  let selectedCompanyMaster = props.selectedCompanyMaster;
  let address = props.address;
  let setMsgAlert = props.setMsgAlert;
  const setConfirmAlert = props.setConfirmAlert;
  debugger;
  const [selectedLocationType, setSelectedLocationType] = useState({});

  // useEffect(() => {
  //   setSelectedLocationType(locationTypeList.currentTab);
  //   getCompanyAddressAssignedToCompanyAddress(
  //     address.CompanyAddressID,
  //     locationTypeList.currentTab.value,
  //     selectedCompanyMaster.CompanyMasterID
  //   );
  // }, []);

  const [assignedAddressList, setAssignedAddressList] = useState([]);
  const [isLoadingAssignedAddress, setIsLoadingAssignedAddress] =
    useState(false);
  const [filteredAssignedAddressList, setFilteredAssignedAddressList] =
    useState([]);

  // const getCompanyAddressAssignedToCompanyAddress = async (accountingBranchId, pType, companyMasterId) => {
  //   debugger;
  //   try {
  //     setIsLoadingAssignedAddress(true);
  //     let warehouseMasterId = address.CompanyAddressID;
  //     console.log(address.CompanyAddressID, "warehouseMasterId");
  //     let viewMode = "";
  //     if (pType === "ACCWAR") {
  //       viewMode = "WASSIGN";
  //     } else if (pType === "PRDWAR") {
  //       viewMode = "PPASSIGN";
  //     }
  //     debugger;
  //     const result = await getCompanyAddressRelationSelect(accountingBranchId ? accountingBranchId : 0, companyMasterId ? companyMasterId : 0, viewMode, warehouseMasterId);
  //     setIsLoadingAssignedAddress(false);
  //     if (result.responseCode === 1) {
  //       if (result && result.responseData.length > 0) {
  //         setAssignedAddressList(result.responseData);
  //         setFilteredAssignedAddressList(result.responseData);
  //         if (searchTextAssigendAddress && searchTextAssigendAddress.toLowerCase().includes("#")) {
  //           onSearchAssignedAddress("");
  //         }
  //         console.log("result.responseData.Value1", result.responseData);
  //       } else {
  //         setAssignedAddressList([]);
  //         setFilteredAssignedAddressList([]);
  //       }
  //     } else {
  //       setAssignedAddressList([]);
  //       setFilteredAssignedAddressList([]);
  //       setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
  //       console.log(result.responseMessage);
  //     }
  //   } catch (error) {
  //     setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
  //     console.log(error);
  //   }
  // };

  const [assignedSubscriptionGridApi, setAssignedSubscriptionGridApi] =
    useState();
  const onAssignedSubscriptionGridReady = (params) => {
    setAssignedSubscriptionGridApi(params.api);
  };

  const [searchTextAssigendAddress, setSearchTextAssigendAddress] =
    useState("");
  const onSearchAssignedAddress = (val) => {
    setSearchTextAssigendAddress(val);
    assignedSubscriptionGridApi.setQuickFilter(val);
    assignedSubscriptionGridApi.refreshCells();
  };

  const [unAssignedAddressPopup, setUnAssignedAddressPopup] = useState(false);
  const toggleUnAssignedAddressPopup = () => {
    setUnAssignedAddressPopup(!unAssignedAddressPopup);
  };

  // //   const [deletedAssignedAddress, setDeletedAssignedAddress] = useState();
  // //   const onClickDeleteAssignedAddress = async (data) => {
  // //     setUnAssignedAddressPopup(false);
  // //     console.log("data", data);
  // //     try {
  // //       setIsLoadingAssignedAddress(true);
  // //       const requestjson = {
  // //         Action: "UNASSIGN",
  // //         AddressRelationID: data.AddressRelationID.toString(),
  // //         AddressRelationType: data.AddressRelationType.toString(),
  // //         CompanyMasterID: selectedCompanyMaster.CompanyMasterID.toString(),
  // //         AccountingBranchID: "0",
  // //         WarehouseMasterID: props.address.CompanyAddressID.toString(),
  // //         ProductionPlantID: "0",
  // //         StoreID: "0",
  // //       };

  // //       console.log("requestjson", requestjson);
  // //       const result = await companyAddressRelationInsert(requestjson);
  // //       console.log("result", result);

  // //       setIsLoadingAssignedAddress(false);
  // //       if (result.responseCode === 1) {
  // //         console.log(result.responseData);
  // //         const newObj = {
  // //           AddressDisplayName: data.AddressDisplayName,
  // //           AddressRelationID: data.AddressRelationID,
  // //           CompanyMasterID: data.CompanyMasterID,
  // //           CompanyAddressID: data.CompanyAddressID,
  // //           AddressRelationTyp: data.AddressRelationTyp,
  // //           IsNewlyAdded: true,
  // //         };

  // //         updateAssignedAddressList(newObj);
  // //         let oldData = assignedAddressList;
  // //         let FilterData = oldData.filter((x) => x.AddressRelationID !== data.AddressRelationID);
  // //         setAssignedAddressList([]);
  // //         setAssignedAddressList(FilterData);
  // //         setFilteredAssignedAddressList([]);
  // //         setFilteredAssignedAddressList(FilterData);
  // //         if (assignedSubscriptionGridApi) {
  // //           assignedSubscriptionGridApi.setRowData(FilterData);
  // //         }
  // //         setMsgAlert({
  // //           open: true,
  // //           type: "success",
  // //           msg: result.responseMessage,
  // //         });
  // //       } else {
  // //         setMsgAlert({
  // //           open: true,
  // //           type: "success",
  // //           msg: result.responseMessage,
  // //         });
  // //         return;
  // //       }
  // //     } catch (error) {
  // //       //  setMsgAlert({ open: true, type: 'success', msg: result.responseMessage });
  // //       console.log(error);
  // //     }
  // //   };

  // const updateAssignedAddressList = (data) => {
  //   console.log("updateUnAssignedAddressList", data);
  //   debugger;

  //   if (data && data.length > 0) {
  //     data.forEach((data) => {
  //       assignedAddressList.unshift(data);
  //     });
  //   }
  //   setAssignedAddressList([]);
  //   setAssignedAddressList(assignedAddressList);

  //   if (assignedSubscriptionGridApi) {
  //     assignedSubscriptionGridApi.setRowData(assignedAddressList);
  //   }

  //   // console.log(assignedList);
  //   // if (assignedList && assignedList.length > 0) {
  //   //   assignedList.forEach((data) => {
  //   //     if (!assignedAddressList.some((x) => x.CompanyAddressID.toString() === data.CompanyAddressID.toString())) {
  //   //       assignedAddressList.unshift(data);
  //   //     }
  //   //   });
  //   // }
  //   // setAssignedAddressList([]);
  //   // setAssignedAddressList(assignedAddressList);
  //   // setFilteredAssignedAddressList([]);
  //   // setFilteredAssignedAddressList(assignedAddressList);
  // };
  const newAddedDataCellStyle = (data) => {
    if (data.data.IsNewlyAdded) {
      return { background: "#ffc176", color: "rgba(52, 73, 94)" };
    }
    return null;
  };

  const companyAddressColnDef = [
    {
      field: "#",
      headerName: "Sr No.",
      width: 80,
      valueGetter: "node.rowIndex + 1",
      lockPosition: "1",
    },
    { field: "AddressDisplayName", headerName: "Display Name", width: 150 },
  ];
  return (
    <React.Fragment>
      {unAssignedAddressPopup ? (
        <UnAssignedSubscription
          //   deletedAssignedAddress={deletedAssignedAddress}
          // updateAssignedAddressList={updateAssignedAddressList}
          toggleUnAssignedAddressPopup={toggleUnAssignedAddressPopup}
          setMsgAlert={props.setMsgAlert}
          // selectedCompanyMaster={selectedCompanyMaster}
          // address={address}
          // selectedLocationType={selectedLocationType}
        />
      ) : null}
      <Modal
        varient={"bottom"}
        title={
          "Subscription Assigned List: " +
          (props.selectedCompanyMaster &&
          props.selectedCompanyMaster.AddressDisplayName
            ? `${props.selectedCompanyMaster.AddressDisplayName}`
            : "")
        }
        show={toggleAssignSubscriptionPopup(false, null)}
        right={0}
        width={"49.5vw"}
        top={0}
      >
        <Modal.Header>
          <div className={"ModalHeadSearchBox"}>
            <PageBar.Search
              value={searchTextAssigendAddress}
              onChange={(e) => onSearchAssignedAddress(e.target.value)}
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="Card">
            <div
              className={classNames(
                "CardBack",
                "DynBiz__Customer_AddPopup_FormContentSectionHead"
              )}
            >
              <div></div>
              <PageBar.Button onClick={() => toggleUnAssignedAddressPopup()}>
                Import
              </PageBar.Button>
            </div>
            <DataGrid
              onGridReady={onAssignedSubscriptionGridReady}
              setConfirmAlert={setConfirmAlert}
              getRowStyle={function (data) {
                if (data.data.IsNewlyAdded) {
                  return { background: "#d5a10e" };
                }
                return { background: "" };
              }}
              rowData={[{}, {}, {}]}
              columnDefs={companyAddressColnDef}
              // onClickDeleteAssignedAddress={onClickDeleteAssignedAddress}
            />
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default AddSubscription;
