import { useState } from "react";
import Modal from "../../../../Framework/Components/Layout/Modal/Modal";
import { Buffer } from "buffer";

function JsonViewer({ type, data, closePopup }) {
  const [jsonData] = useState(() => {
    if (type === 1) {
      var buffer = Buffer.from(data.RequestJson?.data || []);
      const json = JSON.parse(buffer.toString());
      return JSON.stringify(json, null, 2);
    } else if (type === 2) {
      const json = JSON.parse(data.APIResponse);
      return JSON.stringify(json, null, 2);
    }
  });
  return (
    <>
      <Modal
        title={type === 1 ? "Request JSON" : "Response Data"}
        index={2}
        varient={"center"}
        show={() => closePopup()}
        width={"800px"}
        height={"500px"}
      >
        <Modal.Body>
          <textarea
            readOnly={true}
            style={{
              height: "100%",
              width: "100%",
              padding: "10px",
              border: "none",
            }}
          >
            {jsonData}
          </textarea>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default JsonViewer;
