import React, { useEffect, useState } from "react";
import { CustomMsg } from "../../../../../../Configration/Utilities/customMessage";
import DataGrid from "../../../../../../Framework/Components/Common/DataGrid/DataGrid";
import ConfirmDialog from "../../../../../../Framework/OldFramework/ConfirmDialog/ConfirmDialog";
import ErrorMessage from "../../../../../../Framework/OldFramework/ErrorAlert/ErrorMessage";
import Footer from "../../../../../../Framework/OldFramework/Footer/Footer";
import Loader from "../../../../../../Framework/OldFramework/Loader/Loader";
import { AiOutlineFileAdd } from "react-icons/ai";
import AddSubscription from "./Views/AddSubscription/AddSubscription";
import { MdDeleteForever, MdOutlineModeEdit } from "react-icons/md";
import APIEndpoints from "../../../../../../APIMethods/APIEndpoints/APIEndpoints";
import { DeleteSubscription } from "../Customer/Resources/CustomerService";

const SubScription = (props) => {
  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [confirmAlert, setConfirmAlert] = useState({
    open: false,
    title: "",
    msg: "",
    onConfirm: null,
    button: { confirmText: "", abortText: "", Color: "" },
  });

  const [isLoadingSubscriptionData, setIsLoadingSubscriptionData] =
    useState(false);
  let toggleAddSubscriptionPopup = props.toggleAddSubscriptionPopup;
  let onGridReady = props.onGridReady;
  let selectedCustomerMaster = props.selectedCustomerMaster;
  let customerSubscriptionList = props.customerSubscriptionList;
  let isLoadingSubscriptionList = props.isLoadingSubscriptionList;
  let getCustomerSubscriptionList = props.getCustomerSubscriptionList;
  const [addSubcriptionPopup, setAddSubcriptionPopup] = useState(false);
  const toggleAssignSubscriptionPopup = () => {
    setAddSubcriptionPopup(!addSubcriptionPopup);
  };

  // const [searchMappedData, setSearchMappedData] = useState("");
  // const onSearchMappingData = (val) => {
  //   setSearchMappedData(val);
  //   gridApi.setQuickFilter(val);
  //   gridApi.refreshCells();
  // };
  const deleteSubscriber = async (
    subscriptionId,
    customerMasterId,
    apiMasterId
  ) => {
    try {
      let result = await DeleteSubscription(
        subscriptionId,
        customerMasterId,
        apiMasterId,
        `${APIEndpoints.Customer.DeleteSubsciption}?subscriptionId=${subscriptionId}&customerMasterId=${customerMasterId}&apiMasterId=${apiMasterId}`
      );

      console.log("result", result);

      if (result.responseCode === 1) {
        setMsgAlert({
          open: true,
          type: "success",
          msg: result.responseMessage,
        });
        getCustomerSubscriptionList(
          selectedCustomerMaster.CustomerMasterID,
          0,
          0
        );
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        console.log(result.responseMessage);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };
  const CompanyParameterColumnDef = [
    {
      field: "#",
      headerName: "Action",
      width: 120,
      pinned: "left",
      lockPosition: 1,
      cellRenderer: "actionTemplate",
      cellRendererParams: {
        toggleAssignSubscriptionPopup,
        setConfirmAlert,
        deleteSubscriber,
        selectedCustomerMaster,
        toggleAddSubscriptionPopup,
      },
    },
    { field: "APIName", headerName: "API Name", width: 150 },
    // { field: "CustomerName", headerName: "Customer Name", width: 150 },
    { field: "NoOfCalls", headerName: "No Of Calls", width: 150 },
    { field: "InvoiceMode", headerName: "Invoice Mode", width: 150 },
  ];
  return (
    <React.Fragment>
      {msgAlert.open && (
        <ErrorMessage msgAlert={msgAlert} setMsgAlert={setMsgAlert} />
      )}
      {confirmAlert.open && (
        <ConfirmDialog
          confirmAlert={confirmAlert}
          setConfirmAlert={setConfirmAlert}
        />
      )}
      {addSubcriptionPopup ? (
        <AddSubscription
          toggleAssignSubscriptionPopup={toggleAssignSubscriptionPopup}
          setMsgAlert={setMsgAlert}
        />
      ) : null}
      <div style={{ display: "grid" }}>
        {/* <PageTitle Title="Subscription">
          <PageSearch
            focus={true}
            //   onClick={() => getModuleMasterData()}
            //   value={searchMappedData}
            //   onChange={(e) => onSearchMappingData(e.target.value)}
          />
          <PageBar.Button
            // onClick={toggleAssignSubscriptionPopup}
            isDisabled={true}
          >
            Add
          </PageBar.Button>
        </PageTitle> */}
        <DataGrid
          rowData={customerSubscriptionList}
          // rowData={[{}, {}]}
          columnDefs={CompanyParameterColumnDef}
          toggleAssignSubscriptionPopup={toggleAssignSubscriptionPopup}
          onGridReady={onGridReady}
          loader={isLoadingSubscriptionList ? <Loader /> : null}
          getRowStyle={function (data) {
            if (data.data.IsSelected) {
              return { background: "#ffc176" };
            } else {
              if (data.data.IsNewlyAdded) {
                return { background: "#d5a10e" };
              }
              return { background: "" };
            }
          }}
          frameworkComponents={{
            actionTemplate: cellTemplate,
          }}
        />

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default SubScription;
const cellTemplate = (props) => {
  const toggleAssignSubscriptionPopup = () => {
    console.log("***********Selected Data***********", props.data);
    props.toggleAssignSubscriptionPopup(props.data);
  };

  const setConfirmAlert = props.setConfirmAlert;
  const selectedCustomerMasterId =
    props.selectedCustomerMaster.CustomerMasterID;
  const deleteSubscriber = () => {
    props.deleteSubscriber(
      props.data.APISubscriptionID,
      selectedCustomerMasterId,
      props.data.APIMasterID
    );
  };

  return (
    <div style={{ display: "flex", paddingLeft: "1px", gap: "8px" }}>
      {/* <span
        style={{
          cursor: "poiImEyenter",
          display: "grid",
          marginRight: "7px",
          marginTop: "1px",
        }}
        title="Assign File"
      >
        <AiOutlineFileAdd
          style={{ fontSize: "15px", color: "#34495E", marginTop: "1px" }}
          onClick={() => toggleAssignSubscriptionPopup()}
        />
      </span> */}
      <span
        style={{
          cursor: "poiImEyenter",
          display: "grid",
          marginRight: "7px",
          marginTop: "1px",
        }}
        title="Edit Subscription"
      >
        <MdOutlineModeEdit
          style={{ fontSize: "15px", color: "#34495E", marginTop: "1px" }}
          onClick={() => props.toggleAddSubscriptionPopup(true, props.data)}
        />
      </span>
      <span
        title="Delete"
        style={{
          cursor: "pointer",
          display: "grid",
          paddingTop: "2px",
          display: "flex",
        }}
      >
        <MdDeleteForever
          className="Biz__Icon"
          style={{ fontSize: "15px", color: "#f54e60" }}
          onClick={() =>
            setConfirmAlert({
              open: true,
              title: "Delete",
              msg: "Are you sure to delete ?",
              button: {
                confirmText: "Delete",
                abortText: "Cancel",
                Color: "Danger",
              },
              onConfirm: () => deleteSubscriber(),
            })
          }
        />
      </span>
    </div>
  );
};
