import React, { useEffect, useState } from "react";
import { MemoryRouter as Router, Routes, Route } from "react-router-dom";
import { checkAuthExist, setSessionStorage } from "../../APIMethods/Auth/auth";
import Header from "../../Components/Layout/Header/Header";
import Page from "./Page";
import PageAuthenticator from "../../Configration/PageRouter/PageAuthenticator/PageAuthenticator";
import Login from "../../Components/Layout/Common/Login/Login";
import Home from "../../Components/Layout/Common/Home/Home";
import Notfound from "../../Components/Layout/ExceptionHandler/Notfound/notfound";
import CompanyMaster from "../../Components/Setup/Company/Company_Setup/CompanyMaster/CompanyMaster";
import Customer from "../../Components/Setup/Company/Company_Setup/CompanyMaster/Customer/Customer";
import CustomerGridView from "../../Components/Setup/Company/Company_Setup/CompanyMaster/Customer/CustomerGridView";
import SubScription from "../../Components/Setup/Company/Company_Setup/CompanyMaster/Subscription/SubScription";
import APIReport from "../../Components/MIS/Report/APIReport/APIReport";

function PageRouter() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedRole, setSelectedRole] = useState({});
  const [selectedTeamType, setSelectedTeamType] = useState(null);
  const loginHandler = (data) => {
    setIsLoggedIn(data);
    setSessionStorage("IsLoggedIn", data);
  };

  useEffect(() => {
    checkAuthExist();
    if (checkAuthExist()) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);
  return (
    <Router>
      {isLoggedIn ? <Header loginHandler={loginHandler} /> : null}
      <Routes>
        <Route exact path="/" element={<PageAuthenticator />} />
        <Route
          exact
          path="/login"
          element={<Login loginHandler={loginHandler} />}
        />
        <Route
          exact
          path="/home"
          element={<Page component={<Home />} title="Home" />}
        />
        <Route
          exact
          path="/companymaster"
          element={
            <Page component={<CompanyMaster />} title="company Master" />
          }
        />
        <Route
          path="/Customer"
          element={<Page component={<Customer />} title="Customer" />}
        />

        <Route
          path="/customergridView"
          element={
            <Page component={<CustomerGridView />} title="Customer GridView" />
          }
        />
        <Route
          path="/subscription"
          element={<Page component={<SubScription />} title="Subscription" />}
        />
        <Route
          path="/apiReport"
          element={<Page component={<APIReport />} title="apiReport" />}
        />
        <Route
          path="*"
          element={<Page component={<Notfound />} title="Not found" />}
        />
      </Routes>
    </Router>
  );
}

export default PageRouter;
