import APIEndpoints from "../../../../../../../APIMethods/APIEndpoints/APIEndpoints";
import ApiCalling, {
  ApiCalling2,
  ApiCalling3,
  ApiCalling4,
} from "../../../../../../../APIMethods/Utilities/ApiCalling/ApiCalling";

export const getCustomerMasterSelect = async (customerMasterID, endpoint) => {
  const requestData = {
    customerMasterId: customerMasterID,
    objCommon: {
      insertUserId: 1,
      insertIpAddress: "0.00.00.00",
    },
  };

  const response = await ApiCalling2(requestData, endpoint);
  return response;
};

export const getCustomerGSTListSelect = async (customerGSTID, endpoint) => {
  const requestData = {
    customerGSTID: customerGSTID,
    objCommon: {
      insertUserId: 1,
      insertIpAddress: "0.00.00.00",
    },
  };

  const response = await ApiCalling2(requestData, endpoint);
  return response;
};

export const getAPIMasterListSelect = async (apiMasterID, endpoint) => {
  const requestData = {
    apiMasterID: apiMasterID,
    objCommon: {
      insertUserId: 1,
      insertIpAddress: "0.00.00.00",
    },
  };

  const response = await ApiCalling2(requestData, endpoint);
  return response;
};

export const getStateMasterSelect = async (stateMasterID, endpoint) => {
  const requestData = {
    stateMasterID: stateMasterID,
    searchText: "",
    objCommon: {
      insertUserId: 1,
      insertIpAddress: "0.00.00.00",
    },
  };

  const response = await ApiCalling2(requestData, endpoint);
  return response;
};

export const getCustomerAddressListSelect = async (
  customerMasterID,
  endpoint
) => {
  const requestData = {
    customerMasterID: customerMasterID,
    objCommon: {
      insertUserId: 1,
      insertIpAddress: "0.00.00.00",
    },
  };

  const response = await ApiCalling2(requestData, endpoint);
  return response;
};

export const getCustomerSubscriptionListSelect = async (
  customerMasterID,
  endpoint
) => {
  const requestData = {
    customerMasterID: customerMasterID,
    objCommon: {
      insertUserId: 1,
      insertIpAddress: "0.00.00.00",
    },
  };

  const response = await ApiCalling2(requestData, endpoint);
  return response;
};
export const getCustomerReportList = async (customerMasterID, endpoint) => {
  const requestData = {
    customerMasterID: customerMasterID,
    objCommon: {
      insertUserId: 1,
      insertIpAddress: "0.00.00.00",
    },
  };

  const response = await ApiCalling2(requestData, endpoint);
  return response;
};

export const createCustomerAddress = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(
    requestData,
    APIEndpoints.Customer.AddAddress
  );
  return response;
};

export const updteCustomerAddress = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(
    requestData,
    APIEndpoints.Customer.UpdateAddress
  );
  return response;
};

export const updateCustomerAddress = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling4(
    requestData,
    APIEndpoints.Customer.UpdateAddress
  );
  return response;
};

export const CreateGST = async (formData) => {
  console.log("create gst", formData);
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Customer.AddGST);
  return response;
};

export const updateCustomerGST = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling4(
    requestData,
    APIEndpoints.Customer.UpdateGST
  );
  return response;
};

export const updateSubscription = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling4(
    requestData,
    APIEndpoints.Customer.UpdateSubscription
  );
  return response;
};

export const CreateSubscription = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(
    requestData,
    APIEndpoints.Customer.AddSubscription
  );
  return response;
};

export const CreateAddress = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(
    requestData,
    APIEndpoints.Customer.AddAddress
  );
  return response;
};

export const AddressSelect = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(
    requestData,
    APIEndpoints.Customer.GetAddress
  );
  return response;
};

export const SubscriptionSelect = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(
    requestData,
    APIEndpoints.Customer.GetSubscription
  );
  return response;
};

export const GSTSelect = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(requestData, APIEndpoints.Customer.GetGST);
  return response;
};

export const getCity = async (countryId, stateId) => {
  const requestData = {
    countryMasterId: countryId,
    stateMasterId: stateId,
  };
  const response = await ApiCalling(
    requestData,
    APIEndpoints.Customer.GetCityList
  );
  return response;
};

export const DeleteCusutomerMaster = async (customerMasterID, endpoint) => {
  const requestData = {
    customerMasterID: customerMasterID,
    objCommon: {
      insertUserId: 1,
      insertIpAddress: "0.00.00.00",
    },
  };

  const response = await ApiCalling3(requestData, endpoint);
  return response;
};

export const DeleteSubscription = async (
  subscriptionId,
  customerMasterId,
  apiMasterId,
  endpoint
) => {
  const requestData = {
    subscriptionId: subscriptionId,
    customerMasterId: customerMasterId,
    apiMasterId: apiMasterId,
    objCommon: {
      insertUserId: 1,
      insertIpAddress: "0.00.00.00",
    },
  };

  const response = await ApiCalling3(requestData, endpoint);
  return response;
};

export const DeleteAddress = async (CustomerAddressID, endpoint) => {
  const requestData = {
    CustomerAddressID: CustomerAddressID,
    objCommon: {
      insertUserId: 1,
      insertIpAddress: "0.00.00.00",
    },
  };

  const response = await ApiCalling3(requestData, endpoint);
  return response;
};

export const DeleteGSTMaster = async (customerGSTID, endpoint) => {
  const requestData = {
    CustomerGSTID: customerGSTID,
    objCommon: {
      insertUserId: 1,
      insertIpAddress: "0.00.00.00",
    },
  };

  const response = await ApiCalling3(requestData, endpoint);
  return response;
};

export const CustomerActiveStatus = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling4(
    requestData,
    APIEndpoints.Customer.ActivateCustomer
  );
  return response;
};

export const resetCustomerPassword = async (formData) => {
  const requestData = {
    ...formData,
  };

  const response = await ApiCalling4(
    requestData,
    APIEndpoints.Customer.ResetCustomerPassword
  );
  return response;
};
