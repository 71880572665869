import { useState } from "react";
import { AlertMessage } from "../../../../../Framework/Components/Widgets/Notification/NotificationProvider";
import { useNavigate } from "react-router-dom";
import { setSessionStorage } from "../../../../../APIMethods/Auth/auth";
import APIEndpoints from "../../../../../APIMethods/APIEndpoints/APIEndpoints";
import { authenticate, OTPVerifiyApiCalling } from "../Services/Method";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";

function AddLoginLogics(props) {
  const setAlertMessage = AlertMessage();
  const navigate = useNavigate();
  const [ActiveTab, setActiveTab] = useState("LG");
  const [responseKey, setResponseKey] = useState(null);
  const [otptimer, setOtpTimer] = useState(60);
  const [otpAuthenticationFormError, setOtpAuthenticationFormError] =
    useState();
  const [isLoadingOtpAuthentication, setIsLoadingOtpAuthentication] =
    useState(false);
  const [formValues, setFormValues] = useState({
    txtLoginId: "",
    txtPassword: "",
    txtotp: "",
  });

  // ************************** Code For Sign Up First Form Start *************************** //

  const [validationFormError, setValidationFormError] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const validateField = (name, value) => {
    let errorsMsg = "";
    if (name === "txtLoginId") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Username Cannot be empty";
      }
    } else if (name === "txtPassword") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Password Cannot be empty";
      }
    }
    return errorsMsg;
  };

  const handleValidation = () => {
    try {
      const errors = {};
      let formIsValid = true;
      errors["txtLoginId"] = validateField("txtLoginId", formValues.txtLoginId);
      errors["txtPassword"] = validateField(
        "txtPassword",
        formValues.txtPassword
      );
      if (Object.values(errors).join("").toString()) {
        formIsValid = false;
      }
      setValidationFormError(errors);
      return formIsValid;
    } catch (error) {
      setAlertMessage({ type: "error", message: "Something went wrong!" });
      return false;
    }
  };

  const updateState = (name, value) => {
    if (!isLoading) {
      validationFormError[name] = validateField(name, value);
      console.log(value);
      setFormValues((values) => ({
        ...values,
        [name]: value,
      }));
    }
  };

  const otpAuthenticationValidateField = (name, value) => {
    let errorsMsg = "";
    if (name === "txtotp") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "One Time Password Cannot be empty";
      }
    }
    return errorsMsg;
  };

  const otpAuthenticationHandleValidation = () => {
    try {
      const errors = {};
      let formIsValid = true;
      errors["txtotp"] = otpAuthenticationValidateField(
        "txtotp",
        formValues.txtotp
      );
      if (Object.values(errors).join("").toString()) {
        formIsValid = false;
      }
      setOtpAuthenticationFormError(errors);
      return formIsValid;
    } catch (error) {
      setAlertMessage({
        open: true,
        type: "error",
        msg: "Something went wrong!",
      });
      return false;
    }
  };

  const updateOTPFormState = (name, value) => {
    if (!isLoadingOtpAuthentication) {
      otpAuthenticationFormError[name] = otpAuthenticationValidateField(
        name,
        value
      );
      setFormValues((values) => ({
        ...values,
        [name]: value,
      }));
    }
  };

  // const handleLogin = async (e) => {
  //   e.preventDefault();
  //   if (!handleValidation()) {
  //     return;
  //   }
  //   debugger;
  //   try {
  //     setIsLoading(true);
  //     const result = await authenticate(
  //       formValues.txtLoginId,
  //       formValues.txtPassword
  //     );
  //     setIsLoading(false);
  //     console.log(result);
  //     if (result.responseCode === 1) {
  //       if (result.responseData.key) {
  //         setActiveTab("OTP");
  //         setResponseKey(result.responseData);
  //         if (result.responseData.otpExpiryTime) {
  //           const OTPTime = result.responseData.otpExpiryTime.split(" ");
  //           if (OTPTime[0] && OTPTime[1]) {
  //             setOtpTimer(Number(OTPTime[0]));
  //           }
  //         }
  //       } else {
  //         setAlertMessage({
  //           type: "error",
  //           message: "Key is missing in the response",
  //         });
  //       }
  //       setAlertMessage({ type: "success", message: result.responseMessage });
  //     } else {
  //       setAlertMessage({ type: "error", message: result.responseMessage });
  //     }
  //   } catch (error) {
  //     setAlertMessage({
  //       type: "error",
  //       message: "Something went wrong",
  //     });
  //   }
  // };

  const onClickOTPVerifiy = async () => {
    // if (!otpAuthenticationHandleValidation()) {
    //   return;
    // }
    // debugger;
    // try {
    //   const formData = {
    //     username: formValues.txtLoginId,
    //     otp: formValues.txtotp,
    //     key: responseKey.key,
    //   };
    //   setIsLoadingOtpAuthentication(true);
    //   const result = await OTPVerifiyApiCalling(
    //     formData,
    //     APIEndpoints.loginPage.otpVerify
    //   );
    //   setIsLoadingOtpAuthentication(false);
    //   console.log(result);
    //   if (result.responseCode === 1) {
    //     if (!result.responseData.token) {
    //       setAlertMessage({
    //         type: "error",
    //         message: "Token is missing in the response",
    //       });
    //       return;
    //     }
    //     const user = {
    //       ...result.responseData,
    //     };
    //     setSessionStorage("user", user);
    //     navigate("/home");
    //     setAlertMessage({ type: "success", message: result.responseMessage });
    //   } else {
    //     setAlertMessage({ type: "error", message: result.responseMessage });
    //   }
    // } catch (error) {
    //   setAlertMessage({
    //     type: "error",
    //     message: error,
    //   });
    // }
  };

  const requestOtpAgain = async () => {
    // try {
    //   const formData = {
    //     username: formValues.txtLoginId,
    //   };
    //   setIsLoading(true);
    //   const result = await OTPVerifiyApiCalling(
    //     formData,
    //     APIEndpoints.loginPage.resendOTP
    //   );
    //   setIsLoading(false);
    //   if (result.responseCode === 1) {
    //     setResponseKey(result.responseData);
    //     if (result.responseData.otpExpiryTime) {
    //       const OTPTime = result.responseData.otpExpiryTime.split(" ");
    //       if (OTPTime[0] && OTPTime[1]) {
    //         setOtpTimer(Number(OTPTime[0]));
    //       }
    //     }
    //     setAlertMessage({ type: "success", message: result.responseMessage });
    //   } else {
    //     setAlertMessage({ type: "error", message: result.responseMessage });
    //   }
    // } catch (error) {
    //   setAlertMessage({
    //     type: "error",
    //     message: error,
    //   });
    // }
  };

  const SearchByHandleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (ActiveTab === "LG") {
        // handleLogin();
      } else if (ActiveTab === "OTP") {
        onClickOTPVerifiy();
      }
    }
  };

  const otpFormProps = {
    formValues,
    updateOTPFormState,
    onClickOTPVerifiy,
    isLoading,
    otpAuthenticationFormError,
    otptimer,
    SearchByHandleKeyDown,
    setOtpTimer,
    requestOtpAgain,
  };

  return {
    formValues,
    setFormValues,
    updateState,
    // handleLogin,
    SearchByHandleKeyDown,
    isLoading,
    validationFormError,
    ActiveTab,
    setActiveTab,
    otpFormProps,
  };
}

export default AddLoginLogics;
