import React, { useEffect, useState } from "react";
import "../../../Subscription.scss";
// import {
//   companyAddressRelationInsert,
//   getCompanyAddressRelationSelect,
// } from "../../../Resources/companyRegistrationService";
import { CustomMsg } from "../../../../../../../../../Configration/Utilities/customMessage";
import Modal from "../../../../../../../../../Framework/Components/Layout/Modal/Modal";
import DataGrid from "../../../../../../../../../Framework/Components/Common/DataGrid/DataGrid";
import Loader from "../../../../../../../../../Framework/OldFramework/Loader/Loader";
import PageBar from "../../../../../../../../../Framework/Components/Layout/Pagebar/PageBar";
import Button from "../../../../../../../../../Framework/Components/Widgets/Button/Button";
const UnAssignedSubscription = (props) => {
  const toggleUnAssignedAddressPopup = props.toggleUnAssignedAddressPopup;
  const selectedCompanyMaster = props.selectedCompanyMaster;
  const address = props.address;
  const selectedLocationType = props.selectedLocationType;
  const deletedAssignedAddress = props.deletedAssignedAddress;
  const setMsgAlert = props.setMsgAlert;

  const [unAssignedSubscriptionList, setUnAssignedSubscriptionList] = useState(
    []
  );
  const [
    filteredUnAssignedSubscriptionList,
    setFilteredUnAssignedSubscriptionList,
  ] = useState([]);
  const [isLoadingUnAssignedSubscription, setIsLoadingUnAssignedSubscription] =
    useState(false);
  console.log("props", props);
  // const getUnAssignedCompanyAddressToCompanyAddress = async (
  //   accountingBranchId,
  //   pType,
  //   companyMasterId
  // ) => {
  //   console.log("props", props);
  //   debugger;
  //   try {
  //     setIsLoadingUnAssignedSubscription(true);
  //     let warehouseMasterId = address.CompanyAddressID;
  //     let viewMode = "";
  //     if (pType === "ACCWAR") {
  //       viewMode = "WUNASSIGN";
  //     } else if (pType === "PRDWAR") {
  //       viewMode = "PPUNASSIGN";
  //     }
  //     debugger;
  //     const result = await getCompanyAddressRelationSelect(
  //       accountingBranchId ? accountingBranchId : 0,
  //       companyMasterId ? companyMasterId : 0,
  //       viewMode,
  //       warehouseMasterId
  //     );
  //     console.log("result", result);
  //     setIsLoadingUnAssignedSubscription(false);
  //     if (result.responseCode === 1) {
  //       if (result && result.responseData.length > 0) {
  //         const mappedData = result.responseData.map((data, i) => {
  //           return { ...data, IsChecked: false };
  //         });
  //         setUnAssignedSubscriptionList(mappedData);
  //         setFilteredUnAssignedSubscriptionList(mappedData);
  //         console.log("mappedData", mappedData);
  //       } else {
  //         setUnAssignedSubscriptionList([]);
  //         setFilteredUnAssignedSubscriptionList([]);
  //       }
  //     } else {
  //       setUnAssignedSubscriptionList([]);
  //       setFilteredUnAssignedSubscriptionList([]);
  //       setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
  //       console.log(result.responseMessage);
  //     }
  //   } catch (error) {
  //     setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
  //     console.log(error);
  //   }
  // };

  const [formValues, setFormValues] = useState({
    txtAccountingBranchList: "",
  });

  const [gridApi, setGridApi] = useState(null);
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const [searchTextUnAssigendAddress, setSearchTextUnAssigendAddress] =
    useState("");
  const onSearchUnAssignedAddress = (val) => {
    setSearchTextUnAssigendAddress(val);
    gridApi.setQuickFilter(val);
    gridApi.refreshCells();
  };

  // useEffect(() => {
  //   if (props.selectedLocationType && props.selectedLocationType.code !== "") {
  //     if (props.address && props.address.CompanyAddressID)
  //       getUnAssignedCompanyAddressToCompanyAddress(props.address.CompanyAddressID, selectedLocationType.value, selectedCompanyMaster.CompanyMasterID);
  //     //updateUnAssignedLocationList(deletedAssignedAddress);
  //   }
  // }, [deletedAssignedAddress]);

  // useEffect(() => {
  //   debugger;
  //   //   getCompanyAddressAccountingBranch(address.BMCAddressCode,selectedCompanyMaster.CompanyMasterID,selectedLocationType.code,'Select');
  // }, [selectedLocationType]);

  const getSelectedRowData = () => {
    const selectedNodes = gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    return selectedData;
  };

  const [btnLoaderActive, setBtnLoaderActive] = useState(false);
  const handleSave = async (e) => {
    // try {
    //   if (e) e.preventDefault();
    //   const checkedItem = getSelectedRowData();
    //   if (checkedItem && checkedItem.length === 0) {
    //     //     AssinedUser(checkedItem, updateAssignedUserProfileRightList);
    //     setMsgAlert({
    //       open: true,
    //       type: "warning",
    //       msg: "Please select atleast one location.",
    //     });
    //     return;
    //   } else {
    //     debugger;
    //     const aasignID = checkedItem
    //       .map((data) => data.CompanyAddressID)
    //       .join(",");
    //     const formData = {
    //       action: "Assign",
    //       addressRelationId: "0",
    //       addressRelationType: props.selectedLocationType.value.toString(),
    //       companyMasterId: selectedCompanyMaster.CompanyMasterID,
    //       accountingBranchId: props.address.CompanyAddressID,
    //       warehouseMasterId: aasignID,
    //       productionPlantId: "0",
    //       storeId: 0,
    //       objCommon: {
    //         insertUserId: 11,
    //         insertIpAddress: "0.00.00.00",
    //       },
    //     };
    //     console.log("formData", formData);
    //     const result = await companyAddressRelationInsert(formData);
    //     console.log("result", result);
    //     setBtnLoaderActive(false);
    //     if (result.responseCode === 1) {
    //       console.log(result.responseData);
    //       resetUnassignedPropertyList(result);
    //       setMsgAlert({
    //         open: true,
    //         type: "success",
    //         msg: result.responseMessage,
    //       });
    //     } else {
    //       resetUnassignedPropertyList(result);
    //       setMsgAlert({
    //         open: true,
    //         type: "success",
    //         msg: result.responseMessage,
    //       });
    //       return;
    //     }
    //   }
    // } catch (error) {
    //   //  setMsgAlert({ open: true, type: 'success', msg: result.responseMessage });
    //   console.log(error);
    // }
  };

  // const resetUnassignedPropertyList = (result) => {
  //   if (result.responseData) {
  //     const responseAssignedIds = result.responseData.ResponseObject.AddressRelationID ? result.responseData.ResponseObject.AddressRelationID.split(",") : [];
  //     let assignedIds = [];
  //     if (responseAssignedIds.length > 0) {
  //       assignedIds = responseAssignedIds.reduce((assignmentIdList, data) => {
  //         const splitData = data.split("|");
  //         if (splitData.length > 0 && splitData[0] && splitData[1]) {
  //           if (props.selectedLocationType.value === "ACCWAR") {
  //             assignmentIdList.push({
  //               AddressRelationID: splitData[1],
  //               AccountingBranchID: splitData[0],
  //             });
  //           }
  //           if (props.selectedLocationType.value === "PRDWAR") {
  //             assignmentIdList.push({
  //               AddressRelationID: splitData[1],
  //               WarehouseMasterID: splitData[0],
  //             });
  //           }
  //         }
  //         console.log("assignmentIdList", assignmentIdList);
  //         return assignmentIdList;
  //       }, []);
  //     }

  //     if (assignedIds.length > 0) {
  //       const filteredAssignedList = filteredUnAssignedSubscriptionList.reduce((assignedList, data) => {
  //         let resultData;
  //         if (props.selectedLocationType.value === "ACCWAR") {
  //           resultData = assignedIds.find((x) => x.AccountingBranchID === data.CompanyAddressID);
  //         } else {
  //           resultData = assignedIds.find((x) => x.WarehouseMasterID === data.CompanyAddressID);
  //         }

  //         if (resultData) {
  //           assignedList.push({
  //             AddressDisplayName: data.AddressDisplayName,
  //             AddressRelationType: selectedLocationType.value,
  //             AddressRelationID: resultData.AddressRelationID,
  //             CompanyAddressID: data.CompanyAddressID,
  //             CompanyMasterID: data.CompanyMasterID,
  //             IsNewlyAdded: "true",

  //             /*   "BMCAddressCode": data.BMCAddressCode,
  //                             "AddressCode": data.AddressCode,
  //                             "AddressDisplayName": data.AddressDisplayName,
  //                             "CompanyAddressID": data.CompanyAddressID,
  //                             "CompanyMasterID": data.CompanyMasterID,
  //                             "LocationTypeCode": data.LocationTypeCode,
  //                             IsNewlyAdded: "true",
  //                        */
  //           });
  //         }
  //         return assignedList;
  //       }, []);
  //       console.log(filteredAssignedList, "filteredAssignedList");
  //       //  updateUnAssignedSubscriptionList(filteredAssignedList);
  //       props.updateAssignedAddressList(filteredAssignedList);
  //       // props.updateAssignedPropertyList(filteredAssignedList);

  //       debugger;
  //       let updatedUnAssignedList = unAssignedSubscriptionList.filter(function (value) {
  //         if (props.selectedLocationType.value === "ACCWAR") {
  //           return assignedIds.length === 0 || !assignedIds.some((x) => x.AccountingBranchID === value.CompanyAddressID);
  //         } else {
  //           return assignedIds.length === 0 || !assignedIds.some((x) => x.WarehouseMasterID === value.CompanyAddressID);
  //         }
  //       });

  //       const unAssignedIds = result.responseData.AddressRelationID ? result.responseData.AddressRelationID.split(",") : [];
  //       debugger;
  //       if (unAssignedIds.length > 0) {
  //         updatedUnAssignedList = updatedUnAssignedList.map((data, i) => {
  //           if (unAssignedIds.includes(data.AddressRelationID)) {
  //             data.FailedAssigned = true;
  //             data.IsChecked = false;
  //           }
  //           return { ...data };
  //         });
  //       }
  //       setUnAssignedSubscriptionList([]);
  //       setFilteredUnAssignedSubscriptionList([]);
  //       setUnAssignedSubscriptionList(updatedUnAssignedList);
  //       setFilteredUnAssignedSubscriptionList(updatedUnAssignedList);
  //       if (unAssignedIds.length > 0)
  //         setMsgAlert({
  //           open: true,
  //           type: "success",
  //           msg: result.responseMessage,
  //         });
  //       else if (unAssignedIds.length > 0) setMsgAlert({ open: true, type: "error", msg: "Already Assigned." });
  //     } else {
  //       setMsgAlert({
  //         open: true,
  //         type: "success",
  //         msg: result.responseMessage,
  //       });
  //     }
  //   }
  // };

  const companyAddressColnDef = [
    {
      lockPosition: "1",
      pinned: "left",
      headerName: "Select",
      field: "",
      width: 90,
      checkboxSelection: true,
    },
    {
      field: "#",
      headerName: "Sr No.",
      width: 80,
      valueGetter: "node.rowIndex + 1",
      lockPosition: "1",
    },
    { field: "AddressDisplayName", headerName: "Display Name", width: 150 },
  ];
  return (
    <Modal
      onSubmit={handleSave}
      varient={"bottom"}
      title="Subscription Un-Assigned List"
      show={toggleUnAssignedAddressPopup}
      left={0}
      width={"49.5vw"}
      top={0}
    >
      <Modal.Body>
        <div className="Card">
          <PageBar>
            <PageBar.Search
              value={searchTextUnAssigendAddress}
              onChange={(e) => onSearchUnAssignedAddress(e.target.value)}
            />
          </PageBar>
          <DataGrid
            rowData={[{}, {}, {}, {}, {}]}
            // suppressRowClickSelection={true}
            // rowSelection={"multiple"}
            onGridReady={onGridReady}
            loader={isLoadingUnAssignedSubscription ? <Loader /> : null}
            columnDefs={companyAddressColnDef}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button varient={"secondary"} type="submit" trigger={btnLoaderActive}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UnAssignedSubscription;
