import React, { useState, useRef, useEffect, useCallback } from "react";
import PageTitle, {
  PageButton,
  PageSearch,
  PageSelect,
} from "../../../../../../Framework/OldFramework/PageTitle/PageTitle";
import Footer, {
  FooterBox,
} from "../../../../../../Framework/OldFramework/Footer/Footer";
import DataGrid from "../../../../../../Framework/Components/Common/DataGrid/DataGrid";
import Loader from "../../../../../../Framework/OldFramework/Loader/Loader";
import ConfirmDialog from "../../../../../../Framework/OldFramework/ConfirmDialog/ConfirmDialog";
import ErrorMessage from "../../../../../../Framework/OldFramework/ErrorAlert/ErrorMessage";
import AddCustomerPopup from "./Modal/AddCustomer/AddCustomerPopup";
import { HiEye, HiPencil } from "react-icons/hi";
import { FaRegTrashAlt } from "react-icons/fa";
import getApiData, {
  GetApiFormData,
} from "../../../../../../Configration/Utilities/getApiData";
import APIEndpoints from "../../../../../../APIMethods/APIEndpoints/APIEndpoints";

function CustomerGridView(props) {
  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [confirmAlert, setConfirmAlert] = useState({
    open: false,
    title: "",
    msg: "",
    onConfirm: null,
    button: { confirmText: "", abortText: "", Color: "" },
  });

  const [addCustomerPopupActive, setAddCustomerPopupActive] = useState({
    IsOpen: false,
    IsEditMode: false,
    EditCustomer: null,
  });

  const [gridApi, setGridApi] = useState();
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const [isLoadingCustomerList, setIsLoadingCustomerList] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [isLoadingDeleteItemDetails, setIsLoadingDeleteItemDetails] =
    useState(false);
  const [formValues, setFormValues] = useState({
    txtCompanyMaster: null,
  });

  const toggleAddCustomerPopup = (isOpen, isEdit, editData) => {
    setAddCustomerPopupActive({
      IsOpen: isOpen,
      IsEditMode: isEdit,
      EditCustomer: editData,
    });
  };

  const [editCustomerPopupActive, setEditCustomerPopupActive] = useState({
    IsOpen: false,
    EditCustomer: null,
  });

  const toggleEditCustomerPopup = (isOpen, isEdit, editData) => {
    // if (customerDetails && customerDetails.length > 0) {
    //   let customerEditValues = {
    //     txtCustomerName: customerDetails[0].BusinessRelationName,
    //     txtDisplayName: customerDetails[0].BusinessRelationName,
    //     txtCountry: {
    //       CountryName: customerDetails[0].CountryName,
    //       CountryMasterID: Number(customerDetails[0].CountryMasterID),
    //     },
    //     txtIndustryType: {
    //       CommonMasterValue: customerDetails[0].IndustryType,
    //       BMCGCode: Number(customerDetails[0].IndustryBMCGCode),
    //       CommonMasterValueID: Number(customerDetails[0].IndustryTypeID),
    //       CommonMasterCode: Number(customerDetails[0].IndustryTypeCode),
    //     },
    //     txtCurrency: {
    //       CommonMasterValue: customerDetails[0].CurrencyName,
    //       BMCGCode: Number(customerDetails[0].CurrencyBMCGCode),
    //       CommonMasterValueID: Number(customerDetails[0].CurrencyID),
    //       CommonMasterCode: Number(customerDetails[0].CurrencyCode),
    //     },
    //     txtBRCode: customerDetails[0].CustomerCode,
    //   };
    //   setEditCustomerPopupActive({
    //     IsOpen: isOpen,
    //     EditCustomer: customerEditValues,
    //   });
    // } else {
    //   setMsgAlert({
    //     open: true,
    //     type: "warning",
    //     msg: "Please select customer for edit",
    //   });
    //   return;
    // }
  };

  const updateCustomerList = (customer) => {
    customerList.unshift(customer);
    setCustomerList([]);
    setCustomerList(customerList);
    if (gridApi) {
      const itemsToUpdate = [];

      gridApi.setRowData(customerList);
    }
    toggleAddCustomerPopup(false, false, null);
  };

  const [viewCustomerPopup, setViewCustomerPopup] = useState({
    IsOpen: false,
    Data: null,
  });
  const toggleViewCustomerPopupPopup = (data) => {
    setViewCustomerPopup({ IsOpen: !viewCustomerPopup.IsOpen, Data: data });
  };

  const [searchMappedData, setSearchMappedData] = useState("");
  const onSearchMappingData = (val) => {
    setSearchMappedData(val);
    gridApi.setQuickFilter(val);
    gridApi.refreshCells();
  };

  const onClickSerachMappingData = () => {
    //   if (formValues.txtCompanyMaster) {
    //     if (searchMappedData && searchMappedData.length >= 3) {
    //       getCompanyMasterData(formValues.txtCompanyMaster);
    //     } else {
    //       setMsgAlert({
    //         open: true,
    //         type: "warning",
    //         msg: "Please type at least 3 character.",
    //       });
    //       return;
    //     }
    //   } else {
    //     setMsgAlert({
    //       open: true,
    //       type: "warning",
    //       msg: "Please Select Ledger type First",
    //     });
    //     return;
    //   }
  };

  const gridColumnDefs = [
    {
      field: "#",
      headerName: "Action",
      width: 120,
      pinned: "left",
      lockPosition: "1",
      cellRenderer: "actionTemplate",
    },
    {
      field: "#",
      headerName: "Sr.No.",
      width: 80,
      pinned: "left",
      lockPosition: "2",
      valueGetter: "node.rowIndex + 1",
    },
    { field: "CustomerCode", headerName: "Customer Code", width: 180 },
    { field: "CustomerName", headerName: "Customer Name", width: 180 },
    {
      field: "DateOfRegistration",
      headerName: "Date Of Registration",
      width: 200,
    },
    {
      field: "EmailAddress",
      headerName: "Email Adress",
      width: 240,
    },
    {
      field: "PANNo",
      headerName: "PAN Number",
      width: 150,
    },
  ];

  const DeleteFile = (data) => {
    setConfirmAlert({
      open: true,
      title: "Delete",
      msg: "Are you sure to Delete ?",
      button: { confirmText: "Delete", abortText: "Cancel", Color: "Danger" },
      onConfirm: () => deleteListItems(data),
    });
  };

  const deleteListItems = (data) => {
    console.log("data", data);
    let formData = {
      main: {
        CustomerMasterID:
          data && data.CustomerMasterID ? data.CustomerMasterID : 0,
      },
    };

    const Func = (responseData) => {
      console.log(responseData);
    };

    const updateFunc = (responseData) => {
      console.log("ResponseData", responseData);
      if (responseData) {
        if (gridApi) {
          gridApi.applyTransaction({ remove: [data] });
        }
      }
    };

    const request = {
      setDatalist: Func,
      setLoad: setIsLoadingDeleteItemDetails,
      requestData: formData,
      apiPath: APIEndpoints.Customer.DeleteCustomer,
      setAlert: setMsgAlert,
      fun: updateFunc,
      message: true,
    };

    getApiData(request);
  };

  useEffect(() => {}, []);

  return (
    <React.Fragment>
      {msgAlert.open && (
        <ErrorMessage msgAlert={msgAlert} setMsgAlert={setMsgAlert} />
      )}
      {confirmAlert.open && (
        <ConfirmDialog
          confirmAlert={confirmAlert}
          setConfirmAlert={setConfirmAlert}
        />
      )}
      {addCustomerPopupActive.IsOpen ? (
        <AddCustomerPopup
          addCustomerPopupActive={addCustomerPopupActive}
          selectedCustomer={addCustomerPopupActive.EditCustomer}
          isEditMode={addCustomerPopupActive.IsEditMode}
          setConfirmAlert={setConfirmAlert}
          toggleAddCustomerPopup={toggleAddCustomerPopup}
          setMsgAlert={setMsgAlert}
          updateCustomerList={updateCustomerList}
        />
      ) : null}

      <div className={`Biz_Listing_PageStart`}>
        <PageTitle Title="Customer GridView">
          <PageSearch
            focus={true}
            onClick={() => onClickSerachMappingData()}
            value={searchMappedData}
            onChange={(e) => onSearchMappingData(e.target.value)}
          />
          <PageButton onClick={() => toggleAddCustomerPopup(true, false, null)}>
            Add
          </PageButton>
        </PageTitle>
        <DataGrid
          rowData={customerList}
          columnDefs={gridColumnDefs}
          onGridReady={onGridReady}
          toggleViewCustomerPopupPopup={toggleViewCustomerPopupPopup}
          toggleEditCustomerPopup={toggleEditCustomerPopup}
          DeleteFile={DeleteFile}
          loader={isLoadingCustomerList ? <Loader /> : null}
          getRowStyle={function (data) {
            if (data.data.IsSelected) {
              return { background: "#ffc176" };
            } else {
              if (data.data.IsNewlyAdded) {
                return { background: "#d5a10e" };
              }
              return { background: "" };
            }
          }}
          frameworkComponents={{
            actionTemplate: cellTemplate,
          }}
        />

        <Footer>
          <FooterBox Aligned={"right"}></FooterBox>
        </Footer>
      </div>
    </React.Fragment>
  );
}

export default CustomerGridView;
const cellTemplate = (props) => {
  const toggleEditCustomerPopup = () => {
    props.agGridReact.props.toggleEditCustomerPopup(props.data);
  };
  const toggleViewCustomerPopupPopup = () => {
    props.agGridReact.props.toggleViewCustomerPopupPopup(props.data);
  };
  const handleDeleteFile = () => {
    props.agGridReact.props.DeleteFile(props.data);
  };
  console.log(props.data);
  return (
    <div style={{ display: "flex", gap: "8px" }}>
      <HiPencil
        style={{ fontSize: "18px", color: "#34495E", marginTop: "1px" }}
        title="Edit File"
        onClick={() => toggleEditCustomerPopup()}
      />
      <HiEye
        style={{ fontSize: "18px", color: "#34495E", marginTop: "1px" }}
        onClick={() => toggleViewCustomerPopupPopup()}
        title="View File"
      />
      <FaRegTrashAlt
        style={{ fontSize: "18px", color: "#34495E", marginTop: "1px" }}
        onClick={() => handleDeleteFile()}
        title="Delete File"
      />
    </div>
  );
};
