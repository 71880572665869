import React, { useState, useEffect, useRef } from "react";
import { resetCustomerPassword } from "../../Resources/CustomerService";
import Modal from "../../../../../../../../Framework/Components/Layout/Modal/Modal";
import Form from "../../../../../../../../Framework/Components/Layout/FormGroup/FormGroup";
import Button from "../../../../../../../../Framework/OldFramework/Button/Button";
import { CustomMsg } from "../../../../../../../../Configration/Utilities/customMessage";

const ResetPassword = (props) => {
  let toggleResetPasswordPopup = props.toggleResetPasswordPopup;
  let setMsgAlert = props.setMsgAlert;
  let selectedCustomerMaster = props.selectedCustomerMaster;
  const [isLoadingCustomerList, setIsLoadingCustomerList] = useState(false);
  const [formValues, setFormValues] = useState({
    txtOldPassword: "",
    txtNewPassword: "",
  });

  const [formValidationError, setFormValidationError] = useState({});

  const firstTextInput = useRef();
  useEffect(() => {
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  }, []);

  const updateState = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (
      !formValues.txtOldPassword ||
      typeof formValues.txtOldPassword === "undefined"
    ) {
      formIsValid = false;
      errors["txtOldPassword"] = "Cannot be empty";
    }
    if (
      !formValues.txtNewPassword ||
      typeof formValues.txtNewPassword === "undefined"
    ) {
      formIsValid = false;
      errors["txtNewPassword"] = "Cannot be empty";
    }
    setFormValidationError(errors);
    return formIsValid;
  };

  const handleSave = async (e) => {
    try {
      if (e) {
        e.preventDefault();
      }
      if (!handleValidation()) {
        return;
      }
      props.setConfirmAlert({
        open: true,
        title: "Confirmation",
        msg: "Are you sure to reset ?",
        button: {
          confirmText: "Update",
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () => onSubmit(),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const onSubmit = async () => {
    try {
      setIsLoadingCustomerList(true);
      let formData = {
        CustomerClientID: selectedCustomerMaster.CustomerClientID
          ? selectedCustomerMaster.CustomerClientID
          : 0,
        OldCustSecurityKey: formValues.txtOldPassword,
        NewCustSecurityKey: formValues.txtNewPassword,
      };

      let result = await resetCustomerPassword(formData);
      setIsLoadingCustomerList(false);
      if (result.responseCode === 1) {
        setMsgAlert({
          open: true,
          type: "success",
          msg: result.responseMessage,
        });
        props.getCustomerList();
        toggleResetPasswordPopup();
        clearForm();
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error, "error");
    }
  };

  const clearForm = () => {
    setFormValues({
      txtOldPassword: "",
      txtNewPassword: "",
    });
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  return (
    <Modal
      varient={"center"}
      title="Reset Customer Password"
      onSubmit={handleSave}
      show={() => toggleResetPasswordPopup()}
    >
      <Modal.Body>
        <Form>
          <Form.Group controlwidth={"320px"}>
            <Form.InputGroup
              errorMsg={formValidationError["txtOldPassword"]}
              label="Recent Password"
              req={true}
            >
              <Form.InputControl
                control="input"
                name="txtOldPassword"
                label="Recent Password"
                type="text"
                value={formValues.txtOldPassword}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>
            <Form.InputGroup
              errorMsg={formValidationError["txtNewPassword"]}
              label="New Password"
              req={true}
            >
              <Form.InputControl
                control="input"
                label="New Password"
                name="txtNewPassword"
                type="text"
                value={formValues.txtNewPassword}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" type="submit">
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ResetPassword;
