import React, { useState, useEffect, useRef } from "react";
import "../../Customer.scss";
import { CustomMsg } from "../../../../../../../../Configration/Utilities/customMessage";
import { Success } from "../../../../../../../../Configration/Utilities/Constants";
import Modal from "../../../../../../../../Framework/Components/Layout/Modal/Modal";
import { hasAllowedSpecialChar } from "../../../../../../../../Configration/Utilities/utils";
import Button from "../../../../../../../../Framework/OldFramework/Button/Button";
import {
  saveNewCustomer,
  updateCustomer,
} from "../../../../../../../../APIMethods/Customer/CustomerAdaptor";
import { dateToSpecificFormat } from "../../../../../../../../Configration/Utilities/dateFormat";
import moment from "moment";
import Form from "../../../../../../../../Framework/Components/Layout/FormGroup/FormGroup";
import { publicIpv4 } from "public-ip";
import { getSessionStorage } from "../../../../../../../../APIMethods/Auth/auth";

const AddCustomerPopup = (props) => {
  const setConfirmAlert = props.setConfirmAlert;
  const addCustomerPopupActive = props.addCustomerPopupActive;
  const toggleAddCustomerPopup = props.toggleAddCustomerPopup;
  let isEditMode = props.isEditMode;
  let editData = props.selectedCustomer;
  const setMsgAlert = props.setMsgAlert;
  const updateCustomerList = props.updateCustomerList;
  const editCustomerPopupData = props.editCustomerPopupData;
  const setSelectedCustomer = props.setSelectedCustomer;

  const [activeTabId, setActiveTabId] = useState("");

  const [customerCodeList, setCustomerCodeList] = useState([]);
  const [isLoadingCustomerCodeList, setIsLoadingCustomerCodeList] =
    useState(false);
  const [btnLoaderActive, setBtnLoaderActive] = useState(false);
  const [formValues, setFormValues] = useState({
    txtCustomerName: "",
    txtDateOfReg: moment().format("YYYY-MM-DD"),
    txtEmailAddress: "",
    txtPanNo: "",
    txtCustomerCode: "",
    txtCustomerClientID: "",
    txtCustomerSecurityKey: "",
  });
  const [formValidationError, setFormValidationError] = useState({});

  const updateState = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const validateField = (name, value) => {
    let errorsMsg = "";
    if (name === "txtPanNo") {
      const panPattern = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
      if (!value || typeof value === "undefined") {
        errorsMsg = "PAN No Cannot be empty";
      } else if (!panPattern.test(value)) {
        errorsMsg = "Enter Valid Pan No";
      }
    }
    return errorsMsg;
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (
      !formValues.txtCustomerName ||
      typeof formValues.txtCustomerName === "undefined"
    ) {
      formIsValid = false;
      errors["txtCustomerName"] = "Cannot be empty";
    }
    if (
      !formValues.txtDateOfReg ||
      typeof formValues.txtDateOfReg === "undefined"
    ) {
      formIsValid = false;
      errors["txtDateOfReg"] = "Cannot be empty";
    }
    if (
      !formValues.txtEmailAddress ||
      typeof formValues.txtEmailAddress === "undefined"
    ) {
      formIsValid = false;
      errors["txtEmailAddress"] = "Cannot be empty";
    }
    // if (!formValues.txtPanNo || typeof formValues.txtPanNo === "undefined") {
    //   formIsValid = false;
    //   errors["txtPanNo"] = "Cannot be empty";
    // }
    errors["txtPanNo"] = validateField("txtPanNo", formValues.txtPanNo);
    setFormValidationError(errors);
    return formIsValid;
  };

  const handleSave = async (e) => {
    try {
      if (e) e.preventDefault();
      if (!handleValidation()) {
        return;
      }
      const text = editCustomerPopupData ? "Update" : "Add";
      setConfirmAlert({
        open: true,
        title: text,
        msg: `Are you sure to ${text} ?`,
        button: {
          confirmText: text,
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () =>
          editCustomerPopupData ? updateCustomerData() : SaveCustomer(),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const SaveCustomer = async () => {
    try {
      const formData = {
        CustomerCode: formValues.txtCustomerCode
          ? formValues.txtCustomerCode
          : "",
        CustomerName: formValues.txtCustomerName
          ? formValues.txtCustomerName
          : "",
        DateOfRegistration: formValues.txtDateOfReg
          ? moment(formValues.txtDateOfReg).format("YYYY-MM-DD")
          : "",
        CustomerClientID: formValues.txtCustomerClientID
          ? formValues.txtCustomerClientID
          : "",
        CustomerSecurityKey: formValues.txtCustomerSecurityKey
          ? formValues.txtCustomerSecurityKey
          : "",
        EmailAddress: formValues.txtEmailAddress
          ? formValues.txtEmailAddress
          : "",
        PANNo: formValues.txtPanNo ? formValues.txtPanNo : "",
      };

      setBtnLoaderActive(true);
      const result = await saveNewCustomer(formData);

      setBtnLoaderActive(false);
      if (result.responseCode === 1) {
        const newlyAddedCustomer = {
          CustomerCode: formValues.txtCustomerCode
            ? formValues.txtCustomerCode
            : "",
          CustomerName: formValues.txtCustomerName
            ? formValues.txtCustomerName
            : "",
          DateOfRegistration: formValues.txtDateOfReg
            ? moment(formValues.txtDateOfReg).format("YYYY-MM-DD")
            : "",
          CustomerClientID: formValues.txtCustomerClientID
            ? formValues.txtCustomerClientID
            : "",
          CustomerSecurityKey: formValues.txtCustomerSecurityKey
            ? formValues.txtCustomerSecurityKey
            : "",
          EmailAddress: formValues.txtEmailAddress
            ? formValues.txtEmailAddress
            : "",
          PANNo: formValues.txtPanNo ? formValues.txtPanNo : "",
          IsNewlyAdded: true,
        };
        updateCustomerList(newlyAddedCustomer);
        props.updateSelectedCustomerList(newlyAddedCustomer);
        console.log(updateCustomerList, "newlyAddedCustomer");

        setMsgAlert({
          open: true,
          type: "success",
          msg: result.responseMessage,
        });
        clearForm();
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const updateCustomerData = async () => {
    console.log("update customer");
    const formData = {
      CustomerMasterID: editCustomerPopupData.CustomerMasterID,
      CustomerCode: formValues.txtCustomerCode || "",
      CustomerName: formValues.txtCustomerName || "",
      DateOfRegistration: formValues.txtDateOfReg || "",
      EmailAddress: formValues.txtEmailAddress || "",
      PANNo: formValues.txtPanNo,
    };

    const result = await updateCustomer(formData);
    if (result.responseCode === 1) {
      setSelectedCustomer({
        CustomerCode: formValues.txtCustomerCode,
        CustomerName: formValues.txtCustomerName,
        DateOfRegistration: formValues.txtDateOfReg,
        EmailAddress: formValues.txtEmailAddress,
        PANNO: formValues.txtPanNo,
      });

      setMsgAlert({
        open: true,
        type: "success",
        msg: result.responseMessage,
      });
      toggleAddCustomerPopup();
    } else {
      setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
    }
  };

  const clearForm = () => {
    setFormValues({
      txtCustomerName: "",
      txtDateOfReg: "",
      txtEmailAddress: "",
      txtPanNo: "",
      txtCustomerCode: "",
      txtCustomerClientID: "",
      txtCustomerSecurityKey: "",
    });
  };

  const firstTextInput = useRef();

  const handleBlur = (name, value) => {
    if (name === "txtCustomerName") {
      // Send an API request to check if email is already taken.
      setFormValues({ ...formValues, txtDateOfReg: value });
    } else {
    }
  };

  useEffect(() => {
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
    if (editCustomerPopupData) {
      console.log("edit data", editCustomerPopupData);
      setFormValues({
        txtCustomerCode: editCustomerPopupData.CustomerCode,
        txtCustomerName: editCustomerPopupData.CustomerName,
        txtDateOfReg: moment(editCustomerPopupData.DateOfRegistration).format(
          "YYYY-MM-DD"
        ),
        txtEmailAddress: editCustomerPopupData.EmailAddress,
        txtCustomerClientID: editCustomerPopupData.CustomerClientID,
        txtCustomerSecurityKey: "*****",
        txtPanNo: editCustomerPopupData.PANNO,
      });
    }
  }, []);

  return (
    <Modal
      title={`${editCustomerPopupData ? "Update" : "Add"} Customer Master`}
      index={2}
      varient={"center"}
      // onSubmit={handleSave}
      show={() => toggleAddCustomerPopup()}
    >
      <Modal.Body>
        <Form>
          <Form.Group controlwidth={"420px"} column="1">
            <Form.InputGroup
              errorMsg={formValidationError["txtCustomerCode"]}
              label="Customer Code"
              req={true}
            >
              <Form.InputControl
                control="input"
                name="txtCustomerCode"
                value={formValues.txtCustomerCode}
                type="text"
                label="Customer Code"
                onChange={(e) => updateState(e.target.name, e.target.value)}
                ref={firstTextInput}
              />
            </Form.InputGroup>
            <Form.InputGroup
              errorMsg={formValidationError["txtCustomerName"]}
              label="Customer Name"
              req={true}
            >
              <Form.InputControl
                control="input"
                type="text"
                // onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                name="txtCustomerName"
                value={formValues.txtCustomerName}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                label="Customer Name"
              />
            </Form.InputGroup>

            <Form.InputGroup
              errorMsg={formValidationError["txtDateOfReg"]}
              label="Date of Registration"
              req={true}
            >
              <Form.InputControl
                control="input"
                type="date"
                name="txtDateOfReg"
                value={formValues.txtDateOfReg}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                label="Display Name"
              />
            </Form.InputGroup>

            <Form.InputGroup
              errorMsg={formValidationError["txtEmailAddress"]}
              label={"Email ID"}
              req={false}
            >
              <Form.InputControl
                control="input"
                label={"Email ID"}
                type="text"
                autoComplete="off"
                name="txtEmailAddress"
                value={formValues.txtEmailAddress}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup
              errorMsg={formValidationError["txtCustomerClientID"]}
              label="Security Name"
              req={false}
            >
              <Form.InputControl
                control="input"
                type="text"
                maxLength="10"
                name="txtCustomerClientID"
                value={formValues.txtCustomerClientID}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                label="Security Name"
                disabled={editCustomerPopupData}
              />
            </Form.InputGroup>

            <Form.InputGroup
              errorMsg={formValidationError["txtCustomerSecurityKey"]}
              label="Security Key"
              req={false}
            >
              <Form.InputControl
                control="input"
                type="text"
                // maxLength="10"
                name="txtCustomerSecurityKey"
                value={formValues.txtCustomerSecurityKey}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                label="Security Key"
                disabled={editCustomerPopupData}
              />
            </Form.InputGroup>

            <Form.InputGroup
              errorMsg={formValidationError["txtPanNo"]}
              label="PAN No"
              req={true}
            >
              <Form.InputControl
                control="input"
                type="text"
                maxLength="10"
                name="txtPanNo"
                value={formValues.txtPanNo}
                onChange={(e) => updateState(e.target.name, e.target.value)}
                label="PAN No"
              />
            </Form.InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          varient="secondary"
          onClick={(e) => handleSave(e)}
        >
          {editCustomerPopupData ? "Update" : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCustomerPopup;
