import React, { useState, useEffect } from "react";
import "./Header.css";
import { useNavigate, Link, NavLink } from "react-router-dom";
import BiznextLogo from "../../../Framework/Assets/images/Logo/BizNextLogo";
import { getSessionStorage } from "../../../APIMethods/Auth/auth";
import { publicIpv4 } from "public-ip";
import axios from "axios";
import { FiLogOut } from "react-icons/fi";
import ConfirmDialog from "Framework/OldFramework/ConfirmDialog/ConfirmDialog";

function Header(props) {
  let navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState({ __html: "" });
  const [activeMenuId, setActiveMenuId] = useState("0");
  const [activeSubMenuId, setActiveSubMenuId] = useState("0");
  const [userName, setUserName] = useState("");
  const [subMenuList, setSubMenuList] = useState([]);

  const [confirmAlert, setConfirmAlert] = useState({
    open: false,
    title: "",
    msg: "",
    onConfirm: null,
    button: { confirmText: "Confirm", abortText: "Cancel", Color: "" },
  });

  const logout = async () => {
    // try {
    //   const ip = await publicIpv4();
    //   let userData = getSessionStorage("session");
    //   let requestData = {
    //     AppAccessID: userData.User.AppAccessID,
    //     AppAccessSessionID: userData.User.AppSessionID,
    //     commonTrackingDto: {
    //       InsertUserID: userData.User.AppAccessID,
    //       InsertIPAddress: ip,
    //     },
    //   };
    //   let response = await axios.post(MainUrl.Logout, requestData, {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Accept: "application/json",
    //       Authorization: userData.Token,
    //     },
    //   });
    //   if (response.status === 200) {
    //     const result = await response.data;
    //     if (result.ResponseCode === 1) {
    //       return {
    //         responseCode: 1,
    //         responseData: result,
    //         responseMessage: "Success",
    //       };
    //     } else {
    //       return {
    //         responseCode: 0,
    //         responseData: result,
    //         responseMessage: result.ResponseMessage,
    //       };
    //     }
    //   }
    //   return { responseCode: 0, responseData: null, responseMessage: "" };
    // } catch (error) {
    //   return { responseCode: 0, responseData: null, responseMessage: "" };
    // }
  };

  useEffect(() => {
    let userData = getSessionStorage("session");
    if (userData && userData.User && userData.User.AppAccessDisplayName) {
      setUserName(userData.User.AppAccessDisplayName);
    } else {
      setUserName("");
    }
    let defaultMenu = MenuList.find((x) => x.menuno === "1");
    if (defaultMenu) {
      setActiveMenuId(defaultMenu.menuno);
      setSubMenuList(defaultMenu.submenu);
    }
  }, []);

  const onLogoutClick = async () => {
    await logout();
    sessionStorage.clear();
    navigate("/");
    props.loginHandler(false);
  };

  const MenuList = [
    {
      menuno: "1",
      menuname: "SETUP",
      submenu: [
        {
          submenuno: "1-1",
          name: "Customer Registration",
          url: "/customer",
        },
        // {
        //   submenuno: "1-3",
        //   name: "Customer GridView",
        //   url: "/customergridView",
        // },
        // {
        //   submenuno: "1-2",
        //   name: "company Registration",
        //   url: "/companymaster",
        // },
        // {
        //   submenuno: "1-3",
        //   name: "subscription",
        //   url: "/subscription",
        // },
      ],
    },
    {
      menuno: "2",
      menuname: "MIS",
      submenu: [
        {
          submenuno: "2-1",
          name: "Report",
          url: "/apiReport",
        },
        // {
        //   submenuno: "2-2",
        //   name: "Company Registration",
        //   url: "/company_registration",
        // },
      ],
    },
  ];

  const toggleMenu = (menu) => {
    if (activeMenuId === menu.menuno) {
      return;
    }
    setActiveMenuId(menu.menuno);
    if (menu.submenu && menu.submenu.length > 0) {
      setSubMenuList(menu.submenu);
    } else {
      setSubMenuList([]);
    }
  };

  const onClickSubMenu = (submenu) => {
    setActiveSubMenuId(submenu.submenuno);
  };

  const onClickLogout = () => {
    setConfirmAlert({
      open: true,
      title: "Logout",
      msg: "Are you sure to logout?",
      onConfirm: () => onLogoutClick(),
      button: { confirmText: "Confirm", abortText: "Cancel", Color: "" },
    });
  };

  const [userDisplayName] = useState(() => {
    const user = getSessionStorage("user");
    return user.UserData?.UserDisplayName || "";
  });

  return (
    <React.Fragment>
      {confirmAlert.open && (
        <ConfirmDialog
          confirmAlert={confirmAlert}
          setConfirmAlert={setConfirmAlert}
        />
      )}
      <header className="Biz_Bm_Header">
        <div className="Biz_Bm_Header__MainBar">
          <div className="Header__ClientLogoBar">
            <Link to="/">
              <BiznextLogo className="Biz__Icon" />
            </Link>
          </div>
          <div className="Header__MainMenuBar">
            <ul className="Header__UlModule">
              {MenuList &&
                MenuList.map((data, r) => {
                  return (
                    <li
                      onClick={() => toggleMenu(data)}
                      className={
                        activeMenuId === data.menuno ? "Header_ActiveMMenu" : ""
                      }
                    >
                      <a className="Header__MenuTxt">{data.menuname}</a>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="Header__ProfileBar">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  fontSize: "15px",
                  fontWeight: "500",
                  color: "white",
                }}
              >
                {process.env.REACT_APP_API_BASE_URL}
                {userDisplayName}
              </div>
              <button className="Btn_Logout" onClick={() => onClickLogout()}>
                <FiLogOut />
              </button>
            </div>
          </div>
          {/* <button className="Btn_Logout" onClick={() => onLogoutClick()}>
            LogOut
          </button> */}
        </div>
        <div className="Biz_Bm_Header__SubBar">
          <ul className="SubMenu__UlModule">
            {subMenuList && subMenuList.length > 0
              ? subMenuList.map((data, i) => {
                  return (
                    <NavLink
                      onClick={() => onClickSubMenu(data)}
                      key={i}
                      to={data.url}
                      activeClassName={
                        activeSubMenuId === data.submenuno
                          ? "SubHeader_ActiveMMenu"
                          : ""
                      }
                      className="SubHeader_MMenu"
                    >
                      <span className="Header__MenuTxt">{data.name}</span>
                    </NavLink>
                  );
                })
              : null}
          </ul>
        </div>
      </header>
    </React.Fragment>
  );
}
export default Header;
