import axios from "axios";
import { getSessionStorage } from "../../../../../APIMethods/Auth/auth";
import MainUrl from "../../../../../APIMethods/APIEndpoints/MainUrl.json";
import { publicIpv4 } from "public-ip";
import APIEndpoints from "../../../../../APIMethods/APIEndpoints/APIEndpoints";

export const authenticate = async (userName, password) => {
  try {
    let requestData = {
      username: userName,
      password: password,
    };
    debugger;
    console.log(requestData);
    console.log("MainUrl.Authenticate", MainUrl.Authenticate);

    let response = await axios.post(MainUrl.Authenticate, requestData);
    console.log(response, "response");
    if (response.status === 200) {
      const result = await response.data;
      if (result.code === 200) {
        return {
          responseCode: 1,
          responseData: result.data,
          responseMessage: result.message,
        };
      } else {
        return {
          responseCode: 0,
          responseData: result.data,
          responseMessage: result.message,
        };
      }
    }
    return {
      responseCode: 0,
      responseData: null,
      responseMessage: "Login Error",
    };
  } catch (error) {
    return {
      responseCode: 0,
      responseData: null,
      responseMessage: "Login Error",
    };
  }
};

export async function newAuthenticate(userName, password, companyCode) {
  try {
    const ip = await publicIpv4();

    const requestData = {
      accessMode: "BMC",
      appTypeId: 1,
      menuTypeId: 103,
      companyCode: companyCode ?? "",
      companyAccessId: "",
      companyAccessKey: "",
      userLoginId: userName ?? "",
      userPassword: password ?? "",
      userAccessToken: "",
      userImeiNo: "testimeinumber",
      userIpAddress: ip ?? "",
      userMacAddress: "testmacaddress",
      userMobileNo: "9911491058",
    };
    console.log(JSON.stringify(requestData));
    const response = await axios.post(
      APIEndpoints.NewAuthentication,
      requestData,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json; charset=utf-8",
        },
      }
    );
    console.log("response ====> ", response);
    if (response.status === 200) {
      const result = await response.data;
      console.log("ppppp", result);
      return {
        responseCode: 1,
        // P  responseData: result.data,
        responseData: result,
        responseMessage: "Success",
      };
    }
    if (
      response.status === 401 &&
      response &&
      response.Unauthorized &&
      response.Unauthorized.Errors &&
      response.Unauthorized.Errors[0] &&
      response.Unauthorized.Errors[0].ErrorMessage
    ) {
      return {
        responseCode: 0,
        responseData: null,
        responseMessage: response.Unauthorized.Errors[0].ErrorMessage,
      };
    }
    return {
      responseCode: 0,
      responseData: null,
      responseMessage: "Login Error",
    };
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      return {
        responseCode: 401,
        responseData: null,
        responseMessage: "Unauthorized",
      };
    }

    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      return {
        responseCode: 0,
        responseData: null,
        responseMessage:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
            ? error.response.data.message
            : "Something went wrong",
      };
    }

    return {
      responseCode: 0,
      responseData: null,
      responseMessage:
        error && error.message ? error.message : "Something went wrong",
    };
  }
}

export const logout = async () => {
  try {
    const ip = await publicIpv4();
    let userData = getSessionStorage("session");
    let requestData = {
      AppAccessID: userData.User.AppAccessID,
      AppAccessSessionID: userData.User.AppSessionID,
      commonTrackingDto: {
        InsertUserID: userData.User.AppAccessID,
        InsertIPAddress: ip,
      },
    };

    let response = await axios.post(MainUrl.Logout, requestData, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userData.Token,
      },
    });

    if (response.status === 200) {
      const result = await response.data;
      if (result.ResponseCode === 1) {
        return {
          responseCode: 1,
          responseData: result,
          responseMessage: "Success",
        };
      } else {
        return {
          responseCode: 0,
          responseData: result,
          responseMessage: result.ResponseMessage,
        };
      }
    }
    return { responseCode: 0, responseData: null, responseMessage: "" };
  } catch (error) {
    return { responseCode: 0, responseData: null, responseMessage: "" };
  }
};

export const OTPVerifiyApiCalling = async (request, ApiPath) => {
  debugger;
  // try {
  //   const requestData = {
  //     ...request,
  //   };

  //   const response = await axios.post(
  //     APIEndpoints.baseUrl + ApiPath,
  //     requestData
  //   );
  //   if (response.status === 200) {
  //     const result = await response.data;
  //     if (result.code === 200) {
  //       return {
  //         responseCode: 1,
  //         responseData: result.data,
  //         responseMessage: result.message,
  //       };
  //     }
  //     return {
  //       responseCode: result.code,
  //       responseData: null,
  //       responseMessage: result.message,
  //     };
  //   }
  //   return {
  //     responseCode: 0,
  //     responseData: null,
  //     responseMessage: response.message,
  //   };
  // } catch (error) {
  //   return {
  //     responseCode: 0,
  //     responseData: null,
  //     responseMessage: error.response.data.message,
  //   };
  // }
};
