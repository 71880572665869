import React from "react";
import PageRouter from "./Configration/PageRouter/PageRouter";

function App() {
  return (
    <React.Fragment>
      <PageRouter />
    </React.Fragment>
  );
}

export default App;
