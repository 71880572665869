import React, { useState, useRef, useEffect, useCallback } from "react";
import PageTitle from "../../../../Framework/OldFramework/PageTitle/PageTitle";
import Footer, {
  FooterBox,
} from "../../../../Framework/OldFramework/Footer/Footer";
import DataGrid from "../../../../Framework/Components/Common/DataGrid/DataGrid";
import { CustomMsg } from "../../../../Configration/Utilities/customMessage";
import Loader from "../../../../Framework/OldFramework/Loader/Loader";
import ConfirmDialog from "../../../../Framework/OldFramework/ConfirmDialog/ConfirmDialog";
import ErrorMessage from "../../../../Framework/OldFramework/ErrorAlert/ErrorMessage";
// import { getMasterDataFile, getCompanyMasterList } from "../../../../../APIMethods/Setup/Finance/CompanyMasterAdaptor";
import PageBar from "../../../../Framework/Components/Layout/Pagebar/PageBar";
import {
  getCustomerGSTListSelect,
  getCustomerMasterSelect,
  getCustomerReportList,
  getCustomerSubscriptionListSelect,
} from "../../../Setup/Company/Company_Setup/CompanyMaster/Customer/Resources/CustomerService";
import APIEndpoints from "../../../../APIMethods/APIEndpoints/APIEndpoints";
import moment from "moment";
import { FaFileUpload } from "react-icons/fa";
import JsonViewer from "../JsonViewer/JsonViewer";
import Form from "Framework/Components/Layout/FormGroup/FormGroup";

function APIReport() {
  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [confirmAlert, setConfirmAlert] = useState({
    open: false,
    title: "",
    msg: "",
    onConfirm: null,
    button: { confirmText: "", abortText: "", Color: "" },
  });

  const filterRef = useRef([]);

  /* Customer List */

  const [customerList, setCustomerList] = useState([]);
  const [isLoadingCustomerList, setIsLoadingCustomerList] = useState(false);

  const getCustomerList = async () => {
    try {
      setIsLoadingCustomerList(true);
      let result = await getCustomerMasterSelect(
        0,
        `${
          APIEndpoints.Customer.GetCustomerMaster
        }?customerMasterID=${0}&customerClientID=${""}&customerCode`
      );
      setIsLoadingCustomerList(false);
      if (result.responseCode === 1) {
        if (result.responseData && result.responseData.length > 0) {
          setCustomerList(result.responseData);
        } else {
          setCustomerList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        setCustomerList([]);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  /* Customer GST List */

  const [customerGSTList, setCustomerGSTList] = useState([]);
  const [isLoadingCustomerGSTList, setIsLoadingCustomerGSTList] =
    useState(false);

  const getCustomerGSTList = async (customerMasterID) => {
    try {
      setIsLoadingCustomerGSTList(true);
      let result = await getCustomerGSTListSelect(
        0,
        `${
          APIEndpoints.Customer.GetGST
        }?customerGSTID=${0}&customerMasterID=${customerMasterID}`
      );
      setIsLoadingCustomerGSTList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setCustomerGSTList(result.responseData);
        } else {
          setCustomerGSTList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  /* Customer Subscription List */

  const [customerSubscriptionList, setCustomerSubscriptionList] = useState([]);
  const [isLoadingSubscriptionList, setIsLoadingSubscriptionList] =
    useState(false);

  const getCustomerSubscriptionList = async (
    customerMasterID,
    subscriptionId,
    apiMasterId
  ) => {
    try {
      setIsLoadingSubscriptionList(true);
      let result = await getCustomerSubscriptionListSelect(
        0,
        `${APIEndpoints.Customer.GetSubscription}?subscriptionId=${subscriptionId}&customerMasterId=${customerMasterID}&apiMasterId=${apiMasterId}`
      );
      setIsLoadingSubscriptionList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setCustomerSubscriptionList(result.responseData);
        } else {
          setCustomerSubscriptionList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  /* ViewMode */

  const [viewModeOptions] = useState([
    { label: "Summary", value: "S" },
    { label: "Detail", value: "D" },
  ]);

  /* Form Values */

  const [formValues, setFormValues] = useState({
    txtCustomer: null,
    txtGSTIN: null,
    txtSubscription: null,
    txtViewMode: viewModeOptions[0],
    txtFromDate: moment().set("date", 1).format("YYYY-MM-DD"),
    txtToDate: moment().format("YYYY-MM-DD"),
  });

  const updateState = (name, value) => {
    const updates = { [name]: value };
    if (name === "txtCustomer" && value) {
      getCustomerGSTList(value.CustomerMasterID);
      getCustomerSubscriptionList(value.CustomerMasterID, 0, 0);
      if (filterRef.current["txtGSTIN"]) {
        filterRef.current["txtGSTIN"].focus();
      }
      setCustomerGSTList([]);
      setCustomerSubscriptionList([]);
      updates["txtGSTIN"] = null;
      updates["txtSubscription"] = null;
    } else if (name === "txtGSTIN" && value) {
      if (filterRef.current["txtSubscription"]) {
        filterRef.current["txtSubscription"].focus();
      }
    }
    setFormValues({ ...formValues, ...updates });
    setCustomerReportList([]);
  };

  /* Report List */
  const [customerReportList, setCustomerReportList] = useState([]);
  const [isLoadingReportList, setIsLoadingReportList] = useState(false);

  const getReportList = async (
    viewMode,
    customerMasterID,
    customerGSTID,
    apiID,
    fromDate,
    toDate
  ) => {
    try {
      setIsLoadingReportList(true);
      let result = await getCustomerReportList(
        null,
        `${APIEndpoints.Customer.GetApiCallingReport}?customerMasterID=${customerMasterID}&customerGSTID=${customerGSTID}&apiMasterID=${apiID}&viewMode=${viewMode}&fromDate=${fromDate}&toDate=${toDate}`
      );
      setIsLoadingReportList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setCustomerReportList(result.responseData);
        } else {
          setCustomerReportList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const onClickSearchReport = () => {
    if (formValues.txtCustomer) {
      const GSTIN = formValues.txtGSTIN?.CustomerGSTID || 0;
      const subscription = formValues.txtSubscription?.APIMasterID || 0;
      getReportList(
        formValues.txtViewMode.value,
        formValues.txtCustomer.CustomerMasterID,
        GSTIN,
        subscription,
        formValues.txtFromDate,
        formValues.txtToDate
      );
    } else {
      setMsgAlert({
        open: true,
        type: "warning",
        msg: "Please Select Customer.",
      });
    }
  };

  /* Show Json Data */

  const [jsonViewerData, setJsonViewerData] = useState(null);

  const toggleJsonViewer = (values) => {
    if (values) {
      setJsonViewerData(values);
    } else {
      setJsonViewerData(null);
    }
  };

  const gridColumnDefsSummary = [
    /* {
      field: "#",
      headerName: "Action",
      width: 80,
      pinned: "left",
      lockPosition: "1",
    }, */
    {
      field: "#",
      headerName: "Sr.No.",
      width: 80,
      pinned: "left",
      lockPosition: "2",
      valueGetter: "node.rowIndex + 1",
    },
    /* { field: "CustomerGSTID", headerName: "CustomerGSTID", width: 150 },
    { field: "APIMasterID", headerName: "APIMaster ID", width: 120 }, */
    {
      field: "CustomerCode",
      headerName: "Customer Code",
      width: 150,
    },
    {
      field: "CustomerName",
      headerName: "Customer Name",
      width: 250,
    },
    {
      field: "CustomerClientID",
      headerName: "Customer ClientID",
      width: 170,
    },
    {
      field: "PANNO",
      headerName: "PAN No",
      width: 140,
    },
    {
      field: "GSTNo",
      headerName: "GST No",
      width: 170,
    },
    {
      field: "GSTUserID",
      headerName: "GST UserID",
      width: 150,
    },
    {
      field: "APIName",
      headerName: "API Name",
      width: 160,
    },
    {
      field: "SubscriptionNoOfCalls",
      headerName: "SubscriptionNoOfCalls",
      width: 170,
    },
    {
      field: "InvoiceMode",
      headerName: "Invoice Mode",
      width: 130,
    },
    {
      field: "SubscriptionStatus",
      headerName: "Subscription Status",
      width: 170,
    },
    {
      field: "ConsumedNoOfCalls",
      headerName: "ConsumedNoOfCalls",
      width: 180,
    },
  ];

  const gridColumnDefsDetails = [
    {
      field: "#",
      headerName: "Sr.No.",
      width: 80,
      pinned: "left",
      lockPosition: "2",
      valueGetter: "node.rowIndex + 1",
    },
    {
      field: "CustomerCode",
      headerName: "Customer Code",
      width: 150,
    },
    {
      field: "CustomerName",
      headerName: "Customer Name",
      width: 250,
    },
    {
      field: "CustomerClientID",
      headerName: "Customer ClientID",
      width: 170,
    },
    {
      field: "PANNO",
      headerName: "PAN No",
      width: 140,
    },
    {
      field: "StateName",
      headerName: "State Name",
      width: 170,
    },
    {
      field: "GSTNo",
      headerName: "GST No",
      width: 170,
    },
    {
      field: "GSTUserID",
      headerName: "GST UserID",
      width: 150,
    },
    {
      field: "APIName",
      headerName: "API Name",
      width: 160,
    },
    {
      field: "RequestTime",
      headerName: "Request Time",
      width: 140,
      valueFormatter: (param) =>
        param.value ? moment(param.value).format("DD-MM-YYYY") : "--",
    },
    {
      field: "ResponseTime",
      headerName: "Response Time",
      width: 140,
      valueFormatter: (param) =>
        param.value ? moment(param.value).format("DD-MM-YYYY") : "--",
    },
    {
      field: "APIResponseMessage",
      headerName: "Response Message",
      width: 200,
    },
    {
      field: "#",
      headerName: "Request",
      width: 100,
      cellRenderer: "requestViewTemplate",
      cellRendererParams: {
        toggleJsonViewer: toggleJsonViewer,
      },
    },
    {
      field: "#",
      headerName: "Response",
      width: 100,
      cellRenderer: "responseViewTemplate",
      cellRendererParams: {
        toggleJsonViewer: toggleJsonViewer,
      },
    },
  ];

  /* Search */
  const [searchText, setSearchText] = useState("");
  const [gridApi, setGridApi] = useState();
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    getCustomerList();
    if (filterRef.current["txtCustomer"]) {
      filterRef.current["txtCustomer"].focus();
    }
  }, []);

  useEffect(() => {
    console.log("end point", process.env.REACT_APP_APIBASEURL);
  }, []);

  return (
    <>
      {msgAlert.open && (
        <ErrorMessage msgAlert={msgAlert} setMsgAlert={setMsgAlert} />
      )}
      {confirmAlert.open && (
        <ConfirmDialog
          confirmAlert={confirmAlert}
          setConfirmAlert={setConfirmAlert}
        />
      )}

      {jsonViewerData && (
        <JsonViewer
          {...jsonViewerData}
          closePopup={() => {
            setJsonViewerData(null);
          }}
        />
      )}

      <div className={`Biz_Listing_PageStart`}>
        <PageTitle Title="Api Calling Report">
          <PageBar.Input
            type="date"
            style={{ minWidth: "130px" }}
            name="txtToDate"
            value={formValues.txtFromDate}
            onChange={(e) => {
              updateState("txtFromDate", e.target.value);
            }}
            max={moment().format("YYYY-MM-DD")}
          />
          <PageBar.Input
            type="date"
            style={{ minWidth: "130px" }}
            name="txtToDate"
            value={formValues.txtToDate}
            onChange={(e) => {
              updateState("txtToDate", e.target.value);
            }}
          />

          <Form.InputControl
            control="select"
            label="View Mode"
            name="txtCompanyType"
            menuPlacement="auto"
            isClearable={false}
            width={"160px"}
            getOptionLabel={(option) => `${option.label}`}
            value={formValues.txtViewMode}
            getOptionValue={(option) => `${option}`}
            options={viewModeOptions}
            onChange={(val) => updateState("txtViewMode", val)}
          />
          <Form.InputControl
            control="select"
            label="Customer"
            isClearable={false}
            width={"160px"}
            getOptionLabel={(option) => `${option.CustomerName}`}
            value={formValues.txtCustomer}
            getOptionValue={(option) => `${option}`}
            options={customerList}
            onChange={(val) => updateState("txtCustomer", val)}
          />
          <Form.InputControl
            control="select"
            label="GSTIN"
            isClearable={true}
            width={"160px"}
            getOptionLabel={(option) => `${option.StateName}`}
            value={formValues.txtGSTIN}
            getOptionValue={(option) => `${option}`}
            options={customerGSTList}
            onChange={(val) => updateState("txtGSTIN", val)}
          />

          <Form.InputControl
            control="select"
            label="Api Master"
            isClearable={true}
            width={"160px"}
            getOptionLabel={(option) => `${option.APIName}`}
            value={formValues.txtSubscription}
            getOptionValue={(option) => `${option}`}
            options={customerSubscriptionList}
            onChange={(val) => updateState("txtSubscription", val)}
          />

          <PageBar.Search
            focus={true}
            onClick={onClickSearchReport}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              gridApi.setQuickFilter(e.target.value);
            }}
            style={{ width: "120px" }}
          />
        </PageTitle>
        <DataGrid
          rowData={customerReportList}
          columnDefs={
            formValues.txtViewMode?.value === "S"
              ? gridColumnDefsSummary
              : gridColumnDefsDetails
          }
          onGridReady={onGridReady}
          loader={isLoadingReportList ? <Loader /> : null}
          frameworkComponents={{
            requestViewTemplate: RequestViewTemplate,
            responseViewTemplate: ResponseViewTemplate,
          }}
        />

        <Footer>
          <FooterBox Aligned={"right"}></FooterBox>
        </Footer>
      </div>
    </>
  );
}

export default APIReport;

const RequestViewTemplate = (props) => {
  //debug this function
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => {
          props.toggleJsonViewer({ type: 1, data: props.data });
        }}
        title="View Request Json"
      >
        <FaFileUpload style={{}} />
      </div>
    </div>
  );
};

const ResponseViewTemplate = (props) => {
  //debug this function
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          transform: "rotate(180deg)",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => {
          props.toggleJsonViewer({ type: 2, data: props.data });
        }}
        title="View Response Data"
      >
        <FaFileUpload style={{}} />
      </div>
    </div>
  );
};
