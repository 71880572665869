import axios from "axios";
import { checkAuthExist, getSessionStorage } from "../../Auth/auth";
import { CustomMsg } from "../../../Configration/Utilities/customMessage";
import { Buffer } from "buffer";
import APIEndpoints from "../../APIEndpoints/APIEndpoints";
import { Success } from "../../../Configration/Utilities/Constants";
import * as Sentry from "@sentry/react";
import { publicIpv4 } from "public-ip";
import { getIpAddress } from "../utils";
const pako = require("pako");

//*********************************************Axios.POST*******************************************//
export const ApiCalling = async (
  requestApiData,
  apiPath,
  commonName,
  header
) => {
  try {
    const ip = await getIpAddress();
    const user = getSessionStorage("user");

    const requestData = {
      ...requestApiData,
      Common: {
        InsertUserID:
          user && user.UserData && user.UserData.UserID
            ? user.UserData.UserID
            : 0,
        InsertIPAddress: ip ? ip : "0",
      },
    };

    const response = await axios.post(
      APIEndpoints.baseUrl + apiPath,
      requestData,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: user.Token,
          ...header,
        },
      }
    );

    if ((response && response.status === 200) || response.status === 201) {
      console.log(response, "response");
      const result = await response.data;
      if (result.ResponseCode === 200) {
        if (result.ResponseData) {
          return {
            responseCode: 1,
            responseData: result.ResponseData,
            responseMessage: result.ResponseMessage,
          };
        }
        return {
          responseCode: 1,
          responseData: null,
          responseMessage: result.ResponseMessage,
        };
      }
      return {
        responseCode: result.ResponseCode,
        responseData: null,
        responseMessage: result.ResponseMessage,
      };
    }
  } catch (error) {
    Sentry.captureException(error);
    if (
      !checkAuthExist() &&
      error &&
      error.response &&
      error.response.status === 401
    ) {
      return {
        responseCode: 401,
        responseData: null,
        responseMessage: CustomMsg.UnAuthorized,
      };
    } else if (error && error.response && error.response.status === 400) {
      return {
        ResponseCode: 0,
        responseData: null,
        responseMessage:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.ResponseMessage
            ? error.response.data.ResponseMessage
            : CustomMsg.Error,
      };
    }

    return {
      ResponseCode: 0,
      responseData: null,
      responseMessage:
        error &&
        error.response &&
        error.response.data &&
        error.response.data.ResponseMessage
          ? error.response.data.ResponseMessage
          : CustomMsg.Error,
    };
  }
};
export default ApiCalling;

export const ApiCallingUpdate = async (
  requestApiData,
  apiPath,
  commonName,
  header
) => {
  try {
    const ip = await getIpAddress();
    const user = getSessionStorage("user");

    const requestData = {
      ...requestApiData,
      Common: {
        InsertUserID:
          user && user.UserData && user.UserData.UserID
            ? user.UserData.UserID
            : 0,
        InsertIPAddress: ip ? ip : "0",
      },
    };

    const response = await axios.put(
      APIEndpoints.baseUrl + apiPath,
      requestData,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: user.Token,
          ...header,
        },
      }
    );

    if ((response && response.status === 200) || response.status === 201) {
      console.log(response, "response");
      const result = await response.data;
      if (result.ResponseCode === 200) {
        if (result.ResponseData) {
          return {
            responseCode: 1,
            responseData: result.ResponseData,
            responseMessage: result.ResponseMessage,
          };
        }
        return {
          responseCode: 1,
          responseData: null,
          responseMessage: result.ResponseMessage,
        };
      }
      return {
        responseCode: result.ResponseCode,
        responseData: null,
        responseMessage: result.ResponseMessage,
      };
    }
  } catch (error) {
    Sentry.captureException(error);
    if (
      !checkAuthExist() &&
      error &&
      error.response &&
      error.response.status === 401
    ) {
      return {
        responseCode: 401,
        responseData: null,
        responseMessage: CustomMsg.UnAuthorized,
      };
    } else if (error && error.response && error.response.status === 400) {
      return {
        ResponseCode: 0,
        responseData: null,
        responseMessage:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.ResponseMessage
            ? error.response.data.ResponseMessage
            : CustomMsg.Error,
      };
    }

    return {
      ResponseCode: 0,
      responseData: null,
      responseMessage:
        error &&
        error.response &&
        error.response.data &&
        error.response.data.ResponseMessage
          ? error.response.data.ResponseMessage
          : CustomMsg.Error,
    };
  }
};

//*********************************************Axios.GET*********************************************//
export const ApiCalling2 = async (
  requestApiData,
  apiPath,
  commonName,
  header
) => {
  const user = getSessionStorage("user");

  try {
    const response = await axios.get(APIEndpoints.baseUrl + apiPath, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: user.Token,
        ...header,
      },
    });

    if ((response && response.status === 200) || response.status === 201) {
      const result = await response.data;
      if (result.ResponseCode === 1) {
        if (result.ResponseData) {
          return {
            responseCode: 1,
            responseData: result.ResponseData,
            responseMessage: result.ResponseMessage,
          };
        }
        return {
          responseCode: 1,
          responseData: null,
          responseMessage: result.ResponseMessage,
        };
      }
      return {
        responseCode: result.ResponseCode,
        responseData: null,
        responseMessage: result.ResponseMessage,
      };
    }
  } catch (error) {
    Sentry.captureException(error);
    console.log(error, "catchBlockError");
    if (
      !checkAuthExist() &&
      error &&
      error.response &&
      error.response.status === 401
    ) {
      return {
        responseCode: 401,
        responseData: null,
        responseMessage: CustomMsg.UnAuthorized,
      };
    } else if (error && error.response && error.response.status === 400) {
      return {
        ResponseCode: 0,
        responseData: null,
        responseMessage:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.ResponseMessage
            ? error.response.data.ResponseMessage
            : CustomMsg.Error,
      };
    }

    return {
      ResponseCode: 0,
      responseData: null,
      responseMessage:
        error &&
        error.response &&
        error.response.data &&
        error.response.data.ResponseMessage
          ? error.response.data.ResponseMessage
          : CustomMsg.Error,
    };
  }
};

//*********************************************Axios.DELETE******************************************//
export const ApiCalling3 = async (
  requestApiData,
  apiPath,
  commonName,
  header
) => {
  const user = getSessionStorage("user");
  const requestData = {
    ...requestApiData,
  };

  try {
    const response = await axios.delete(
      APIEndpoints.baseUrl + apiPath,
      requestData,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: user.Token,
          ...header,
        },
      }
    );

    if ((response && response.status === 200) || response.status === 201) {
      console.log(response, "response");
      const result = await response.data;
      if (result.ResponseCode === 200) {
        if (result.ResponseData) {
          return {
            responseCode: 1,
            responseData: result.ResponseData,
            responseMessage: result.ResponseMessage,
          };
        }
        return {
          responseCode: 1,
          responseData: null,
          responseMessage: result.ResponseMessage,
        };
      }
      return {
        responseCode: result.ResponseCode,
        responseData: null,
        responseMessage: result.ResponseMessage,
      };
    }
  } catch (error) {
    Sentry.captureException(error);
    if (
      !checkAuthExist() &&
      error &&
      error.response &&
      error.response.status === 401
    ) {
      return {
        responseCode: 401,
        responseData: null,
        responseMessage: CustomMsg.UnAuthorized,
      };
    } else if (error && error.response && error.response.status === 400) {
      return {
        ResponseCode: 0,
        responseData: null,
        responseMessage:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.ResponseMessage
            ? error.response.data.ResponseMessage
            : CustomMsg.Error,
      };
    }

    return {
      ResponseCode: 0,
      responseData: null,
      responseMessage:
        error &&
        error.response &&
        error.response.data &&
        error.response.data.ResponseMessage
          ? error.response.data.ResponseMessage
          : CustomMsg.Error,
    };
  }
};

//*********************************************Axios.PUT**********************************************//
export const ApiCalling4 = async (
  requestApiData,
  apiPath,
  commonName,
  header
) => {
  const user = getSessionStorage("user");
  const requestData = {
    ...requestApiData,
  };

  try {
    const response = await axios.put(
      APIEndpoints.baseUrl + apiPath,
      requestData,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: user.Token,
          // apiaccesscode: apiPath.accessCode,
          ...header,
        },
      }
    );

    if ((response && response.status === 200) || response.status === 201) {
      console.log(response, "response");
      const result = await response.data;
      if (result.ResponseCode === 1) {
        return {
          responseCode: 1,
          responseData: null,
          responseMessage: result.ResponseMessage,
        };
      }
      return {
        responseCode: result.ResponseCode,
        responseData: null,
        responseMessage: result.ResponseMessage,
      };
    }
  } catch (error) {
    Sentry.captureException(error);
    if (
      !checkAuthExist() &&
      error &&
      error.response &&
      error.response.status === 401
    ) {
      return {
        responseCode: 401,
        responseData: null,
        responseMessage: CustomMsg.UnAuthorized,
      };
    } else if (error && error.response && error.response.status === 400) {
      return {
        ResponseCode: 0,
        responseData: null,
        responseMessage:
          error &&
          error.response &&
          error.response.data &&
          error.response.data.ResponseMessage
            ? error.response.data.ResponseMessage
            : CustomMsg.Error,
      };
    }

    return {
      ResponseCode: 0,
      responseData: null,
      responseMessage:
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
          ? error.response.data.message
          : CustomMsg.Error,
    };
  }
};

// ************************************** Code For Approval  ************************************//
export const getMasterDataFileList = async (request) => {
  const requestData = {
    main: {
      masterDataCode: request ? request : 0,
    },
    objCommon: {},
  };

  const response = await ApiCalling(
    requestData,
    APIEndpoints.ItemMaster.GetMasterData
  );
  return response;
};

//********************************* BMC Api Calling Start ***********************************//

export const BMCApiCalling = async (requestApiData, apiPath, header) => {
  try {
    if (!checkAuthExist()) {
      return {
        ResponseCode: 401,
        responseData: null,
        responseMessage: "Session Expired",
      };
    }
    const ip = await publicIpv4();
    const user = getSessionStorage("user");
    const session = getSessionStorage("bmsession");
    const requestData = {
      ...requestApiData.main,
      objCommon: {
        ...requestApiData.objCommon,
        insertedUserID:
          user && user.UserData && user.UserData.UserID
            ? user.UserData.UserID.toString()
            : "",
        insertedIPAddress: ip,
        dateShort:
          user && user.UserData && user.UserData.DateShort
            ? user.UserData.DateShort.toString()
            : "",
        dateLong:
          user && user.UserData && user.UserData.DateLong
            ? user.UserData.DateLong.toString()
            : "",
      },
    };
    const response = await axios.post(
      "https://api.spiral.bmcontroller.com/" + apiPath,
      requestData,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: session.Token,
          ...header,
        },
      }
    );

    if (response && response.status === 201) {
      const result = await response.data;
      if (result.ResponseCode.toString() === Success) {
        if (result.ResponseDynamic === "" || result.ResponseDynamic === null) {
          return {
            ResponseCode: result.ResponseCode.toString(),
            responseData: [],
            responseMessage: result.ResponseMessage,
          };
        } else {
          const buff = Buffer.from(
            result.responseDynamic || result.ResponseDynamic
              ? result.responseDynamic || result.ResponseDynamic
              : "",
            "base64"
          );
          if (buff.length !== 0) {
            const Data = JSON.parse(pako.inflate(buff, { to: "string" }));
            return {
              ResponseCode: result.ResponseCode.toString(),
              responseData: Data,
              responseMessage: result.responseMessage,
            };
          }
          return {
            ResponseCode: result.ResponseCode.toString(),
            responseData: [],
            responseMessage: result.ResponseMessage,
          };
        }
      }
      return {
        ResponseCode: result.ResponseCode.toString(),
        responseData: null,
        responseMessage: result.ResponseMessage,
      };
    }
    return {
      ResponseCode: 0,
      responseData: null,
      responseMessage: CustomMsg.Error,
    };
  } catch (error) {
    Sentry.captureException(error);
    if (
      !checkAuthExist() &&
      error &&
      error.response &&
      error.response.status === 401
    ) {
      return {
        ResponseCode: 401,
        responseData: null,
        responseMessage: CustomMsg.UnAuthorized,
      };
    }
    return {
      ResponseCode: 0,
      responseData: null,
      responseMessage:
        error &&
        error.response &&
        error.response.data &&
        error.response.data.ResponseMessage
          ? error.response.data.ResponseMessage
          : CustomMsg.Error,
    };
  }
};

export const apiCallingWithFormData = async (requestApiData, apiPath) => {
  try {
    if (!checkAuthExist()) {
      return {
        ResponseCode: 401,
        responseData: null,
        responseMessage: "Session Expired",
      };
    }

    const user = getSessionStorage("user");

    const response = await axios({
      method: "post",
      url: user.BaseURL + apiPath,
      data: requestApiData,
      headers: {
        "Content-Type": "multipart/form-data",
        apiToken:
          user && user.ApiToken && user.ApiToken.ApiToken
            ? user.ApiToken.ApiToken
            : "",
        apiClientName: user && user.ClientName ? user.ClientName : "",
        apiUserName: user && user.AppAccessUID ? user.AppAccessUID : "",
        "Access-Control-Allow-Origin": "*",
      },
    });

    if (response && response.status === 200) {
      const result = await response.data;
      if (result.ResponseCode.toString() === "1") {
        const buff = Buffer.from(
          result.responseDynamic ? result.responseDynamic : "",
          "base64"
        );
        if (buff.length !== 0) {
          const data = JSON.parse(pako.inflate(buff, { to: "string" }));
          return {
            ResponseCode: 1,
            responseData: data,
            responseMessage: result.responseMessage,
          };
        }
        return {
          ResponseCode: 1,
          responseData: [],
          responseMessage: result.responseMessage,
        };
      }
      return {
        ResponseCode: Number(result.ResponseCode),
        responseData: null,
        responseMessage: result.responseMessage,
      };
    }
    return { ResponseCode: 0, responseData: null, responseMessage: "" };
  } catch (error) {
    Sentry.captureException(error);
    if (
      !checkAuthExist() &&
      error &&
      error.response &&
      error.response.status === 401
    ) {
      return {
        ResponseCode: 401,
        responseData: null,
        responseMessage: "Something went wrong",
      };
    }
    console.log(error);
    return {
      ResponseCode: 0,
      responseData: null,
      responseMessage:
        error &&
        error.response &&
        error.response.data &&
        error.response.statusText
          ? error.response.statusText
          : "Something went wrong",
    };
  }
};
