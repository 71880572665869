import { checkAuthExist } from "../../../APIMethods/Auth/auth";
import React from "react";
import { Navigate } from "react-router-dom";

function PageAuthenticator() {
  if (checkAuthExist()) {
    return <Navigate to="/home" />;
  }

  return <Navigate to="/login" />;
}

export default PageAuthenticator;
