import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../../../../../../Framework/Components/Layout/Modal/Modal";
import Form from "../../../../../../../../Framework/Components/Layout/FormGroup/FormGroup";
import Button from "../../../../../../../../Framework/OldFramework/Button/Button";
import {
  CreateGST,
  CreateSubscription,
  getAPIMasterListSelect,
  updateSubscription,
} from "../../Resources/CustomerService";
import { CustomMsg } from "../../../../../../../../Configration/Utilities/customMessage";
import APIEndpoints from "../../../../../../../../APIMethods/APIEndpoints/APIEndpoints";

const AddSubscription = (props) => {
  let popupData = props.popupData;
  let menuData = props.menuData;
  let isEditMode = props.isEditMode;
  let updategetCustomerSubscriptionList =
    props.updategetCustomerSubscriptionList;
  let setMsgAlert = props.setMsgAlert;
  let toggleAddSubscriptionPopup = props.toggleAddSubscriptionPopup;
  let getCustomerSubscriptionList = props.getCustomerSubscriptionList;

  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const [formValues, setFormValues] = useState({
    txtCustomerMasterID: 0,
    txtAPIMasterID: 0,
    txtPackageMasterID: 0,
    txtNoOfCalls: "",
    txtInvoiceMode: "",
  });

  const firstTextInput = useRef();
  const [apiMasterrList, setAPIMasterrList] = useState([]);
  const [isLoadingAPIMasterList, setIsLoadingAPIMasterList] = useState(false);
  const [formValidationError, setFormValidationError] = useState({});
  const [editCustomerSubscription, setEditCustomerSubscription] = useState();

  const getAPIMasterList = async () => {
    debugger;
    try {
      setIsLoadingAPIMasterList(true);
      let result = await getAPIMasterListSelect(
        0,
        `${
          APIEndpoints.Customer.GetAPIMasterList
        }?apiMasterID=${0}&apiName=${""}`
      );
      console.log("getCustomerList", result);
      setIsLoadingAPIMasterList(false);
      if (result.responseCode === 1) {
        if (result.responseData && result.responseData.length > 0) {
          console.log(result.responseData, "result.responseData");
          setAPIMasterrList(result.responseData);
        } else {
          setAPIMasterrList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        setAPIMasterrList([]);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const invoiceModeOptions = [
    { Name: "PrePaid", Value: "PrePaid" },
    { Name: "PostPaid", Value: "PostPaid" },
  ];

  useEffect(() => {
    if (isEditMode) {
      if (menuData) {
        setEditCustomerSubscription(popupData);
      }
    }
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  }, []);

  useEffect(() => {
    if (
      editCustomerSubscription &&
      editCustomerSubscription.APISubscriptionID
    ) {
      setFormValues((existingValue) => ({
        ...existingValue,
        txtAPIMasterID: apiMasterrList.find(
          (x) => x.APIMasterID === editCustomerSubscription.APIMasterID
        ),
      }));
    }
  }, [apiMasterrList]);

  useEffect(() => {
    if (editCustomerSubscription) {
      console.log(editCustomerSubscription, "editCustomerSubscription");
      setFormValues({
        txtAPIMasterID:
          editCustomerSubscription.APIName && apiMasterrList.length > 0
            ? apiMasterrList.find(
                (x) => x.Name === editCustomerSubscription.APIName
              )
            : null,
        txtNoOfCalls: editCustomerSubscription.NoOfCalls
          ? editCustomerSubscription.NoOfCalls
          : 0,
        txtInvoiceMode:
          editCustomerSubscription.InvoiceMode && invoiceModeOptions.length > 0
            ? invoiceModeOptions.find(
                (x) => x.Name === editCustomerSubscription.InvoiceMode
              )
            : null,
      });
    }
    console.log(editCustomerSubscription, "editCustomerSubscription");
  }, [editCustomerSubscription]);

  const validateField = (name, value) => {
    let errorsMsg = "";
    if (name === "txtAPIMasterID") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Cannot be empty";
      }
    } else if (name === "txtPackageMasterID") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Cannot be empty";
      }
    } else if (name === "txtNoOfCalls") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Cannot be empty";
      }
    } else if (name === "txtInvoiceMode") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Cannot be empty";
      }
    }

    return errorsMsg;
  };

  const updateState = (name, value) => {
    console.log("name", name, "value", value);
    setFormValues({ ...formValues, [name]: value });
    formValidationError[name] = validateField(name, value);
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (!formValues.txtAPIMasterID) {
      formIsValid = false;
      errors["txtAPIMasterID"] = "Cannot be empty";
    }

    // if (typeof formValues.txtPackageMasterID === "undefined") {
    //   formIsValid = false;
    //   errors["txtPackageMasterID"] = "Cannot be empty";
    // }
    if (typeof formValues.txtNoOfCalls === "undefined") {
      formIsValid = false;
      errors["txtNoOfCalls"] = "Cannot be empty";
    }
    if (typeof formValues.txtInvoiceMode === "undefined") {
      formIsValid = false;
      errors["txtInvoiceMode"] = "Cannot be empty";
    }

    setFormValidationError(errors);
    return formIsValid;
  };

  const handleSave = async (e) => {
    debugger;
    try {
      if (e) {
        e.preventDefault();
      }
      if (!handleValidation()) {
        return;
      }
      props.setConfirmAlert({
        open: true,
        title: "Confirmation",
        msg: isEditMode ? "Are you sure to Update" : "Are you sure to Save ?",
        button: {
          confirmText: isEditMode ? "Update" : "Save",
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () => onSubmit(),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const onSubmit = async () => {
    try {
      setBtnloaderActive(true);
      let requestData = {};
      if (isEditMode) {
        requestData = {
          APISubscriptionID: editCustomerSubscription.APISubscriptionID,
          CustomerMasterID: menuData.CustomerMasterID,
          APIMasterID: Number(formValues.txtAPIMasterID.APIMasterID)
            ? Number(formValues.txtAPIMasterID.APIMasterID)
            : 0,
          PackageMasterID: 1,
          NoOfCalls: Number(formValues.txtNoOfCalls),
          // InvoiceMode:
          //   formValues.txtInvoiceMode && formValues.txtInvoiceMode.Value
          //     ? formValues.txtInvoiceMode.Value.toString()
          //     : 0,
          /* Common: {
            InsertUserID: insertUserID,
            InsertIPAddress: ip,
          }, */
        };
      } else {
        requestData = {
          CustomerMasterID: menuData.CustomerMasterID,
          APIMasterID: Number(formValues.txtAPIMasterID.APIMasterID)
            ? Number(formValues.txtAPIMasterID.APIMasterID)
            : 0,
          PackageMasterID: 1,
          NoOfCalls: Number(formValues.txtNoOfCalls),
          InvoiceMode:
            formValues.txtInvoiceMode && formValues.txtInvoiceMode.Value
              ? formValues.txtInvoiceMode.Value.toString()
              : 0,
        };
      }

      let fun = isEditMode ? updateSubscription : CreateSubscription;
      let result = await fun(requestData);
      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        setMsgAlert({
          open: true,
          type: "success",
          msg: result.responseMessage,
        });

        if (result) {
          /* let subMenu = {
            APISubscriptionID: isEditMode
              ? editCustomerSubscription.APISubscriptionID
              : result &&
                result.responseData &&
                result.responseData.APISubscriptionID
              ? result.responseData.APISubscriptionID
              : 0,
            CustomerMasterID: menuData.CustomerMasterID,
            CustomerName: menuData.CustomerName,
            APIName: formValues.txtAPIMasterID.APIName.toString(),
            NoOfCalls: formValues.txtNoOfCalls,
            InvoiceMode: formValues.txtInvoiceMode.Name,
            IsNewlyAdded: true,
          }; */
          updategetCustomerSubscriptionList();
          toggleAddSubscriptionPopup(false, null);
        } else {
          setMsgAlert({
            open: true,
            type: "error",
            msg: result.responseMessage,
          });
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const clearForm = () => {
    setFormValues({
      txtCustomerMasterID: 0,
      txtAPIMasterID: "",
      txtPackageMasterID: 0,
      txtNoOfCalls: "",
      txtInvoiceMode: "",
      txtBizNextSecurityID: "",
      txtGSTToken: "",
    });

    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  useEffect(() => {
    getAPIMasterList();
  }, []);
  return (
    <Modal
      varient={"center"}
      title={
        isEditMode
          ? "Update Customer Subscription"
          : "Add Customer Subscription"
      }
      onSubmit={handleSave}
      show={() => toggleAddSubscriptionPopup(false, null)}
    >
      <Modal.Body>
        <Form>
          <Form.Group controlwidth={"320px"}>
            <Form.InputGroup
              errorMsg={formValidationError["txtAPIMasterID"]}
              label="API Master"
              req={true}
            >
              <Form.InputControl
                getOptionLabel={(option) => `${option.APIName}`}
                value={formValues.txtAPIMasterID}
                getOptionValue={(option) => `${option}`}
                options={apiMasterrList}
                onChange={(e) => updateState("txtAPIMasterID", e)}
                isSearchable={true}
                isLoading={isLoadingAPIMasterList}
                label="API Master"
                control="select"
              />
            </Form.InputGroup>

            <Form.InputGroup
              errorMsg={formValidationError["txtNoOfCalls"]}
              label="No Of Calls"
              req={true}
            >
              <Form.InputControl
                control="input"
                label="No Of Calls"
                name="txtNoOfCalls"
                type="text"
                value={formValues.txtNoOfCalls}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            {/* <Form.InputGroup
              errorMsg={formValidationError["txtNoOfCalls"]}
              label="No Of Calls"
              req={true}
            >
              <Form.InputControl
                label="No Of Calls"
                control="select"
                name="txtNoOfCalls"
                type="text"
                value={formValues.txtNoOfCalls}
                getOptionLabel={(option) => `${option.Name}`}
                getOptionValue={(option) => `${option}`}
                onChange={(e) => updateState("txtNoOfCalls", e)}
              />
            </Form.InputGroup> */}

            <Form.InputGroup
              errorMsg={formValidationError["txtInvoiceMode"]}
              req={true}
              label="Invoice Mode"
            >
              <Form.InputControl
                label="Invoice Mode"
                control="select"
                name="txtInvoiceMode"
                isDisabled={isEditMode}
                value={formValues.txtInvoiceMode}
                getOptionLabel={(option) => `${option.Name}`}
                getOptionValue={(option) => `${option.Value}`}
                options={invoiceModeOptions}
                onChange={(e) => updateState("txtInvoiceMode", e)}
              />
            </Form.InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" type="submit" trigger={false}>
          {isEditMode ? "Update" : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddSubscription;
