import APIEndpoints from "../../APIMethods/APIEndpoints/APIEndpoints";
import ApiCalling, {
  ApiCallingUpdate,
} from "../../APIMethods/Utilities/ApiCalling/ApiCalling";

export const saveNewCustomer = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCalling(
    requestData,
    APIEndpoints.Customer.AddCustomer
  );
  return response;
};
export const updateCustomer = async (formData) => {
  const requestData = {
    ...formData,
  };
  const response = await ApiCallingUpdate(
    requestData,
    APIEndpoints.Customer.UpdateCustomerData
  );
  return response;
};
