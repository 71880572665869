import React, { useState, useEffect, useRef } from "react";
import "./Customer.scss";
import { BsThreeDots } from "react-icons/bs";
import { GoPlus } from "react-icons/go";
import { IoClose } from "react-icons/io5";
import { BiEditAlt, BiListUl, BiPowerOff, BiTrashAlt } from "react-icons/bi";
import Footer from "../../../../../../Framework/OldFramework/Footer/Footer";
import { PageSelect } from "../../../../../../Framework/OldFramework/PageTitle/PageTitle";
import {
  MdDeleteForever,
  MdOutlineLocationOn,
  MdOutlineModeEdit,
} from "react-icons/md";
import AddCustomerPopup from "./Modal/AddCustomer/AddCustomerPopup";
import CustomerGridView from "./CustomerGridView";
import SubScription from "../Subscription/SubScription";
import AddCustomerAddress from "./Modal/AddCustomerAddress/AddCustomerAddress";
import AddGst from "./Modal/AddGst/AddGst";
import ConfirmDialog from "../../../../../../Framework/OldFramework/ConfirmDialog/ConfirmDialog";
import ErrorMessage from "../../../../../../Framework/OldFramework/ErrorAlert/ErrorMessage";
import { formatDate } from "../../../../../../Configration/Utilities/dateFormat";
import {
  SkeletonCustomerDataV1,
  SkeletonCustomerList,
  SkeletonDtboxData,
} from "../../../../../../Framework/Components/Widgets/Skeleton/skeleton";
import Loader from "../../../../../../Framework/OldFramework/Loader/Loader";
import AddSubscription from "./Modal/AddSubscription/AddSubscription";
import {
  CustomerActiveStatus,
  DeleteAddress,
  DeleteCusutomerMaster,
  DeleteGSTMaster,
  getCustomerAddressListSelect,
  getCustomerGSTListSelect,
  getCustomerMasterSelect,
  getCustomerSubscriptionListSelect,
} from "./Resources/CustomerService";
import { CustomMsg } from "../../../../../../Configration/Utilities/customMessage";
import APIEndpoints from "../../../../../../APIMethods/APIEndpoints/APIEndpoints";
import ResetPassword from "./Modal/ResetPassword/ResetPassword";

function Customer() {
  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [confirmAlert, setConfirmAlert] = useState({
    open: false,
    title: "",
    msg: "",
    onConfirm: null,
    button: { confirmText: "", abortText: "" },
  });
  const [activeTabId, setActiveTabId] = useState("GST");
  // const [alphaFilter, setAlphaSelect] = useState([]);
  const [gridviewActive, setGridviewActive] = useState(false);
  const [isLoadingCustomerList, setIsLoadingCustomerList] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [filteredCustomerList, setFilteredCustomerList] = useState([]);
  const [selectedCustomerMaster, setSelectedCustomerMaster] = useState("");

  const [customerViewResponseMessage, setCustomerViewResponseMessage] =
    useState("");
  const [GSTList, setGSTList] = useState([]);
  const [isLoadingGstList, setIsLoadingGstList] = useState(false);
  const [gstFilteredList, setGstFilteredList] = useState([]);
  const [CustomerAddressList, setCustomerAddressList] = useState([]);
  const [isLoadingAddressList, setIsLoadingAddressList] = useState(false);
  const [customerAddressFilterdList, setCustomerAddressFilterdList] = useState(
    []
  );
  const [selectedAddress, setSelectedAddress] = useState("");
  const [customerSubscriptionList, setCustomerSubscriptionList] = useState([]);
  const [isLoadingSubscriptionList, setIsLoadingSubscriptionList] =
    useState(false);

  const getCustomerList = async () => {
    setFilterText("");
    try {
      setIsLoadingCustomerList(true);
      let result = await getCustomerMasterSelect(
        0,
        `${
          APIEndpoints.Customer.GetCustomerMaster
        }?customerMasterID=${0}&customerClientID=${""}&customerCode`
      );
      setIsLoadingCustomerList(false);
      if (result.responseCode === 1) {
        if (result.responseData && result.responseData.length > 0) {
          setCustomerList(result.responseData);
          setFilteredCustomerList(result.responseData);
        } else {
          setCustomerList([]);
          setFilteredCustomerList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        setCustomerList([]);
        setFilteredCustomerList([]);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const setCustomerActiveStatus = async () => {
    try {
      let formData = {
        CustomerMasterID: selectedCustomerMaster.CustomerMasterID,
        ActveStatus: selectedCustomerMaster.ActveStatus === 0 ? 1 : 0,
      };
      let result = await CustomerActiveStatus(formData);

      if (result.responseCode === 1) {
        setMsgAlert({
          open: true,
          type: "success",
          msg: result.responseMessage,
        });
        getCustomerList();
        setSelectedCustomerMaster({
          ...selectedCustomerMaster,
          ActveStatus: selectedCustomerMaster.ActveStatus === 0 ? 1 : 0,
        });
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const getCustomerGSTList = async (customerMasterID) => {
    try {
      setIsLoadingGstList(true);
      let result = await getCustomerGSTListSelect(
        0,
        `${
          APIEndpoints.Customer.GetGST
        }?customerGSTID=${0}&customerMasterID=${customerMasterID}`
      );
      setIsLoadingGstList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setGSTList(result.responseData);
          setGstFilteredList(result.responseData);
        } else {
          setGSTList([]);
          setGstFilteredList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const getCustomerAddressList = async (customerMasterID) => {
    try {
      setIsLoadingAddressList(true);
      let result = await getCustomerAddressListSelect(
        0,
        `${APIEndpoints.Customer.GetAddress}?customerMasterID=${customerMasterID}`
      );
      setIsLoadingAddressList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setCustomerAddressList(result.responseData);
          setCustomerAddressFilterdList(result.responseData);
        } else {
          setCustomerAddressList([]);
          setCustomerAddressFilterdList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const getCustomerSubscriptionList = async (
    customerMasterID,
    subscriptionId,
    apiMasterId
  ) => {
    try {
      // setIsLoadingSubscriptionList(true);
      let result = await getCustomerSubscriptionListSelect(
        0,
        `${APIEndpoints.Customer.GetSubscription}?subscriptionId=${subscriptionId}&customerMasterId=${customerMasterID}&apiMasterId=${apiMasterId}`
      );
      // setIsLoadingSubscriptionList(false);
      if (result.responseCode === 1) {
        if (result && result.responseData.length > 0) {
          setCustomerSubscriptionList(result.responseData);
        } else {
          setCustomerSubscriptionList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  useEffect(() => {
    getCustomerList();
  }, []);

  useEffect(() => {
    if (selectedCustomerMaster) {
      if (activeTabId == "AD") {
        getCustomerAddressList(selectedCustomerMaster.CustomerMasterID);
      }
      if (activeTabId == "GST") {
        getCustomerGSTList(selectedCustomerMaster.CustomerMasterID);
      }
      if (activeTabId == "SB") {
        getCustomerSubscriptionList(
          selectedCustomerMaster.CustomerMasterID,
          0,
          0
        );
      }
    }
  }, [activeTabId]);

  const [addCustomerPopupActive, setAddCustomerPopupActive] = useState({
    IsOpen: false,
    IsEditMode: false,
    EditCustomer: null,
  });

  const toggleAddCustomerPopup = (isOpen, isEdit, editData) => {
    setAddCustomerPopupActive({
      IsOpen: isOpen,
      IsEditMode: isEdit,
      EditCustomer: editData,
    });
  };

  /* Edit Customer */
  const [editCustomerPopupData, setEditCustomerPopupData] = useState(null);

  const toggleEditCustomerPopup = (isOpen) => {
    if (isOpen) {
      setEditCustomerPopupData(selectedCustomerMaster);
    } else {
      setEditCustomerPopupData(null);
    }
  };
  const [filterText, setFilterText] = useState("");
  const onSearchCustomer = (e) => {
    setFilterText(e.target.value);
    let searchValue = e.target.value;
    if (searchValue !== "") {
      const filtereddata = customerList.filter((data) => {
        return Object.values(data)
          .join(" ")
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      setFilteredCustomerList(filtereddata);
    } else {
      setFilteredCustomerList(customerList);
    }
  };

  const updateCustomerList = (customer) => {
    customerList.push(customer);
    setCustomerList([]);
    setCustomerList(customerList);
    setFilteredCustomerList([]);
    setFilteredCustomerList(customerList);
    toggleAddCustomerPopup(false, false, null);
  };
  const updateSelectedCustomerList = (customer) => {
    setSelectedCustomerMaster("");
    setSelectedCustomerMaster(customer);
  };

  const updateAddressList = (data) => {
    CustomerAddressList.unshift(data);
    setCustomerAddressList(CustomerAddressList);
    // getCustomerAddressList(selectedCustomerMaster.CustomerMasterID);
  };

  const updateGSTList = (data) => {
    gstFilteredList.unshift(data);
    setGstFilteredList(gstFilteredList);
  };

  const [gridApi, setGridApi] = useState();

  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  const updategetCustomerSubscriptionList = () => {
    getCustomerSubscriptionList(selectedCustomerMaster.CustomerMasterID, 0, 0);
  };

  const [addCustomerAddressPopupActive, setAddCustomerAddressPopupActive] =
    useState({
      isOpen: false,
      popupData: null,
      IsEditMode: "",
    });
  const toggleAddCustomerAddress = (isEdit, editData) => {
    setAddCustomerAddressPopupActive({
      isOpen: !addCustomerAddressPopupActive.isOpen,
      IsEditMode: isEdit,
      popupData: editData,
    });
  };

  const [addGSTPopupActive, setAddGSTPopupActive] = useState({
    isOpen: false,
    popupData: null,
    IsEditMode: "",
  });
  const toggleAddGSTPopup = (isEdit, editData) => {
    setAddGSTPopupActive({
      isOpen: !addGSTPopupActive.isOpen,
      IsEditMode: isEdit,
      popupData: editData,
    });
  };

  const [resetPasswordPopupActive, setResetPasswordPopupActive] =
    useState(false);
  const toggleResetPasswordPopup = () => {
    setResetPasswordPopupActive(!resetPasswordPopupActive);
  };

  const [addSubscriptionPopupActive, setAddSubscriptionPopupActive] = useState({
    isOpen: false,
    popupData: null,
    IsEditMode: "",
  });
  const toggleAddSubscriptionPopup = (isEdit, editData) => {
    setAddSubscriptionPopupActive({
      isOpen: !addSubscriptionPopupActive.isOpen,
      IsEditMode: isEdit,
      popupData: editData,
    });
  };

  const onClickCustomerMaster = async (data) => {
    console.log("data", data);
    if (
      selectedCustomerMaster &&
      selectedCustomerMaster.CustomerMasterID === data.CustomerMasterID
    ) {
      return;
    }
    if (activeTabId === "AD") {
      getCustomerAddressList(data.CustomerMasterID);
    }
    if (activeTabId === "GST") {
      getCustomerGSTList(data.CustomerMasterID);
    }
    if (activeTabId === "SB") {
      getCustomerSubscriptionList(data.CustomerMasterID, 0, 0);
    }
    setSelectedCustomerMaster(data);
  };

  const deleteCustomer = async (CustomerMasterID) => {
    try {
      let result = await DeleteCusutomerMaster(
        CustomerMasterID,
        `${APIEndpoints.Customer.DeleteCustomer}/${CustomerMasterID}`
      );

      if (result.responseCode === 1) {
        setMsgAlert({
          open: true,
          type: "success",
          msg: result.responseMessage,
        });
        const results = filteredCustomerList.filter((el) => {
          return (
            el.CustomerMasterID !== selectedCustomerMaster.CustomerMasterID
          );
        });
        setFilteredCustomerList(results);
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const handleDeleteCustomer = async (e) => {
    try {
      if (e) {
        e.preventDefault();
      }

      setConfirmAlert({
        open: true,
        title: "Confirmation",
        msg: "Are you sure to Delete ?",
        button: {
          confirmText: "Delete",
          abortText: "Cancel",
          Color: "Danger",
        },
        onConfirm: () =>
          deleteCustomer(selectedCustomerMaster.CustomerMasterID),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };
  return (
    <>
      {msgAlert.open && (
        <ErrorMessage msgAlert={msgAlert} setMsgAlert={setMsgAlert} />
      )}
      {confirmAlert.open && (
        <ConfirmDialog
          confirmAlert={confirmAlert}
          setConfirmAlert={setConfirmAlert}
        />
      )}
      {editCustomerPopupData && (
        <AddCustomerPopup
          editCustomerPopupData={editCustomerPopupData}
          toggleAddCustomerPopup={() => toggleEditCustomerPopup(false)}
          setConfirmAlert={setConfirmAlert}
          setMsgAlert={setMsgAlert}
          updateCustomerList={updateCustomerList}
          setSelectedCustomer={(data) => {
            setSelectedCustomerMaster({ ...selectedCustomerMaster, ...data });
          }}
        />
      )}
      {addCustomerPopupActive.IsOpen ? (
        <AddCustomerPopup
          addCustomerPopupActive={addCustomerPopupActive}
          selectedCustomer={addCustomerPopupActive.EditCustomer}
          isEditMode={addCustomerPopupActive.IsEditMode}
          setConfirmAlert={setConfirmAlert}
          toggleAddCustomerPopup={toggleAddCustomerPopup}
          setMsgAlert={setMsgAlert}
          updateCustomerList={updateCustomerList}
          updateSelectedCustomerList={updateSelectedCustomerList}
        />
      ) : null}
      {addCustomerAddressPopupActive.isOpen ? (
        <AddCustomerAddress
          popupData={addCustomerAddressPopupActive.popupData}
          isEditMode={addCustomerAddressPopupActive.IsEditMode}
          selectedCustomerMaster={selectedCustomerMaster}
          setMsgAlert={setMsgAlert}
          toggleAddCustomerAddress={toggleAddCustomerAddress}
          updateAddressList={updateAddressList}
          setConfirmAlert={setConfirmAlert}
          getCustomerAddressList={getCustomerAddressList}
        />
      ) : null}
      {addGSTPopupActive.isOpen ? (
        <AddGst
          menuData={selectedCustomerMaster}
          selectedCustomerMaster={selectedCustomerMaster}
          setConfirmAlert={setConfirmAlert}
          isEditMode={addGSTPopupActive.IsEditMode}
          toggleAddGSTPopup={toggleAddGSTPopup}
          popupData={addGSTPopupActive.popupData}
          setMsgAlert={setMsgAlert}
          getCustomerGSTList={getCustomerGSTList}
          updateGSTList={updateGSTList}
        />
      ) : null}
      {resetPasswordPopupActive ? (
        <ResetPassword
          menuData={selectedCustomerMaster}
          selectedCustomerMaster={selectedCustomerMaster}
          setConfirmAlert={setConfirmAlert}
          toggleResetPasswordPopup={toggleResetPasswordPopup}
          setMsgAlert={setMsgAlert}
          getCustomerList={getCustomerList}
        />
      ) : null}
      {addSubscriptionPopupActive.isOpen ? (
        <AddSubscription
          menuData={selectedCustomerMaster}
          selectedCustomerMaster={selectedCustomerMaster}
          setConfirmAlert={setConfirmAlert}
          toggleAddSubscriptionPopup={toggleAddSubscriptionPopup}
          setMsgAlert={setMsgAlert}
          popupData={addSubscriptionPopupActive.popupData}
          isEditMode={addSubscriptionPopupActive.IsEditMode}
          updategetCustomerSubscriptionList={updategetCustomerSubscriptionList}
        />
      ) : null}
      <div
        className={
          gridviewActive
            ? "Biz_Listing_PageStart"
            : "DynBiz_ListDetail Biz__PageStart"
        }
      >
        <div
          style={
            !gridviewActive ? { display: "none" } : { display: "contents" }
          }
        >
          <CustomerGridView toggleAddCustomerPopup={toggleAddCustomerPopup} />
        </div>
        <div
          style={
            !gridviewActive
              ? { display: "grid", overflow: "auto" }
              : { display: "none" }
          }
        >
          <>
            <div className="Biz__CustomerDash">
              <div className="DynBiz_ListDetailContent_Div">
                <div className="DynBiz_ListDetailContent_SearchBox">
                  <div className="DynBiz_ListDetail__FormSearchDiv">
                    <input
                      type="text"
                      className="DynBiz_ListDetail__FormSearchBox"
                      onChange={(e) => onSearchCustomer(e)}
                      placeholder="Search..."
                      autoComplete="false"
                      value={filterText}
                    />
                    <a
                      className="DynBiz_ListDetail__FormSearchIcon"
                      onClick={() => getCustomerList()}
                    >
                      Search
                    </a>
                    {/* {alphaFilter.length > 0 ? (
                      <>
                        <a
                          className="DynBiz_ListDetail__FormSearchIcon"
                          onClick={() => getCustomerList()}
                        >
                          Search
                        </a>
                        <a
                          className="DynBiz_ListDetail__CanceSearchlIcon"
                          onClick={() => getCustomerList()}
                        >
                          <IoClose className="Biz__Icon" />{" "}
                        </a>
                      </>
                    ) : (
                      <a
                        className="DynBiz_ListDetail__FormSearchIcon"
                        onClick={() => getCustomerList()}
                      >
                        Search
                      </a>
                    )} */}
                  </div>
                </div>
                <div className="Biz_Bm__CustomerList">
                  <div className="Biz_Bm__CustomerListDiv">
                    {!isLoadingCustomerList ? (
                      filteredCustomerList.length > 0 ? (
                        filteredCustomerList.map((data, i) => {
                          return (
                            <div
                              key={i}
                              className={
                                selectedCustomerMaster &&
                                selectedCustomerMaster.CustomerMasterID ===
                                  data.CustomerMasterID
                                  ? "Biz_Bm__CustomerListBox Active__CustomerListBox"
                                  : "Biz_Bm__CustomerListBox"
                              }
                              onClick={() => onClickCustomerMaster(data)}
                            >
                              <h2>
                                {data.CustomerName
                                  ? `${data.CustomerName}`
                                  : ""}
                              </h2>
                              <h2>{data.Industry ? `${data.Industry}` : ""}</h2>
                              <p>{`${
                                data.LanguageName ? data.LanguageName : ""
                              }  ${data.CountryName ? data.CountryName : ""}  ${
                                data.DOR ? formatDate(data.DOR) : ""
                              }`}</p>
                            </div>
                          );
                        })
                      ) : customerViewResponseMessage !== "" ? (
                        <div className="Biz_Bm_Customer_Empty_dataBox">
                          <p>{customerViewResponseMessage}</p>
                        </div>
                      ) : (
                        <SkeletonCustomerList />
                      )
                    ) : (
                      <Loader />
                    )}
                  </div>
                </div>
              </div>
              <div className="DynBiz_ListDetailDiv">
                <div className="DynBiz_ListDetail__Pagetitle">
                  <div style={{ display: "flex" }}>
                    <h2> Customer Details</h2>
                  </div>
                  <div className="DynBiz_ListDetail__Pagetitle__box">
                    <button
                      className={`DynBiz_ListDetail__Pagetitle_btn DynBiz_ListDetail__Pagetitle_btn${
                        selectedCustomerMaster ? "Secondary" : "Disabled"
                      }`}
                      biz-toltp="Edit"
                      biz-toltp-modifier="bottom"
                      onClick={() => toggleEditCustomerPopup(true)}
                      disabled={!selectedCustomerMaster}
                    >
                      <BiEditAlt />
                    </button>
                    <button
                      className={
                        "DynBiz_ListDetail__Pagetitle_btn DynBiz_ListDetail__Pagetitle_btnSecondary"
                      }
                      biz-toltp="Add"
                      biz-toltp-modifier="bottom"
                      onClick={() => toggleAddCustomerPopup(true, false, null)}
                      disabled={false}
                    >
                      <GoPlus />
                    </button>
                    {/* <button
                      className="DynBiz_ListDetail__Pagetitle_btn DynBiz_ListDetail__Pagetitle_btnSecondary"
                      biz-toltp="View"
                      biz-toltp-modifier="bottom"
                    >
                      <BiExpand />
                    </button> */}
                    <button
                      className={`DynBiz_ListDetail__Pagetitle_btn DynBiz_ListDetail__Pagetitle_btn${
                        selectedCustomerMaster ? "Secondary" : "Disabled"
                      }`}
                      biz-toltp="Delete"
                      biz-toltp-modifier="bottom"
                      onClick={() => handleDeleteCustomer()}
                      disabled={!selectedCustomerMaster}
                    >
                      <BiTrashAlt />
                    </button>
                    <button
                      className={`DynBiz_ListDetail__Pagetitle_btn DynBiz_ListDetail__Pagetitle_btn${
                        selectedCustomerMaster
                          ? selectedCustomerMaster.ActveStatus === 1
                            ? "Secondary"
                            : "Red"
                          : "Disabled"
                      }`}
                      biz-toltp={
                        selectedCustomerMaster.ActveStatus === 1
                          ? "De-Activate Customer"
                          : "Activate Customer"
                      }
                      biz-toltp-modifier="bottom"
                      onClick={() => {
                        setCustomerActiveStatus();
                        // setSelectedCustomerMaster(selectedCustomerMaster);
                        // onClickCustomerMaster(selectedCustomerMaster);
                      }}
                      disabled={!selectedCustomerMaster}
                    >
                      <BiPowerOff />
                    </button>
                    <button
                      className={`DynBiz_ListDetail__Pagetitle_btn DynBiz_ListDetail__Pagetitle_btn${
                        selectedCustomerMaster ? "Secondary" : "Disabled"
                      }`}
                      biz-toltp="Reset Password"
                      biz-toltp-modifier="bottom"
                      onClick={() => toggleResetPasswordPopup()}
                      disabled={!selectedCustomerMaster}
                    >
                      <BiListUl />
                    </button>
                  </div>
                </div>
                <div className="Customer__DetailBox">
                  <div className="Customer__DetailTxt">
                    <div className="Customer__DContentBox">
                      <label>Organisation Name</label>
                      <p>
                        {selectedCustomerMaster &&
                        selectedCustomerMaster.CustomerName ? (
                          selectedCustomerMaster.CustomerName
                        ) : (
                          <SkeletonCustomerDataV1 />
                        )}
                      </p>
                    </div>
                    <div className="Customer__DContentBox">
                      <label>Customer Client ID</label>
                      <p>
                        {" "}
                        {selectedCustomerMaster &&
                        selectedCustomerMaster.CustomerClientID ? (
                          selectedCustomerMaster.CustomerClientID
                        ) : (
                          <SkeletonCustomerDataV1 />
                        )}
                      </p>
                    </div>
                    <div className="Customer__DContentBox">
                      <label>Email Address</label>
                      <p>
                        {" "}
                        {selectedCustomerMaster &&
                        selectedCustomerMaster.EmailAddress ? (
                          selectedCustomerMaster.EmailAddress
                        ) : (
                          <SkeletonCustomerDataV1 />
                        )}
                      </p>
                    </div>
                    <div className="Customer__DContentBox">
                      <label>PAN NO</label>
                      <p>
                        {" "}
                        {selectedCustomerMaster &&
                        selectedCustomerMaster.PANNO ? (
                          selectedCustomerMaster.PANNO
                        ) : (
                          <SkeletonCustomerDataV1 />
                        )}
                      </p>
                    </div>

                    {/* <div className="Customer__DContentBox">
                      <label>Country</label>
                      <p>
                        {" "}
                        {selectedCustomerMaster &&
                        selectedCustomerMaster.Country ? (
                          selectedCustomerMaster.Country
                        ) : (
                          <SkeletonCustomerDataV1 />
                        )}
                      </p>
                    </div> */}
                  </div>

                  {/* <div className="Customer__MainDivBox">
                    <div className="Customer__CummunicationDetails">
                      <div
                        className="Customer__CummunicationTitle"
                        style={{ display: "flex" }}
                      >
                        Communication Details &nbsp;&nbsp;
                        <BiEditAlt style={{ cursor: "pointer" }} />
                        onClick={() => toggleEditCommunicationPopUp(true, null)} 
                      </div>
                      <div className="Customer__CummunicationContent">
                        <div className="Customer__DetailsCBox">
                          <label>Mobile No </label>
                          <p>-----</p>
                        </div>
                        <div className="Customer__DetailsCBox">
                          <label>Email ID </label>
                          <p>-----</p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="Customer__MultiDetailBox">
                  <div className="Customer__MDB_BtnPanel">
                    <ul className="Customer__MDb_TabPanel">
                      <li
                        onClick={() => setActiveTabId("GST")}
                        className={
                          activeTabId === "GST"
                            ? "Customer__MDb_TabList Active__MDb_TabList"
                            : "Customer__MDb_TabList "
                        }
                      >
                        GSTIN
                      </li>
                      {/* <li
                        onClick={() => setActiveTabId("AD")}
                        className={
                          activeTabId === "AD"
                            ? "Customer__MDb_TabList Active__MDb_TabList"
                            : "Customer__MDb_TabList "
                        }
                      >
                        Address
                      </li> */}

                      <li
                        onClick={() => setActiveTabId("SB")}
                        className={
                          activeTabId === "SB"
                            ? "Customer__MDb_TabList Active__MDb_TabList"
                            : "Customer__MDb_TabList "
                        }
                      >
                        Subscription
                      </li>
                    </ul>
                    {activeTabId === "AD" ? (
                      <>
                        <PageSelect ControlTxt="Location Type" />
                        <button
                          className={`Biz__Customer_AddAddressBtn Biz__Customer_AddAddressBtn${
                            selectedCustomerMaster ? "Secondary" : "Disabled"
                          }`}
                          onClick={() => toggleAddCustomerAddress(false, null)}
                          disabled={!selectedCustomerMaster}
                        >
                          Add Address
                        </button>
                      </>
                    ) : activeTabId === "GST" ? (
                      <>
                        <button
                          className={`Biz__Customer_AddAddressBtn Biz__Customer_AddAddressBtn${
                            selectedCustomerMaster ? "Secondary" : "Disabled"
                          }`}
                          onClick={() => toggleAddGSTPopup(false, null)}
                          disabled={!selectedCustomerMaster}
                        >
                          Add GST
                        </button>
                      </>
                    ) : activeTabId === "SB" ? (
                      <>
                        <button
                          className={`Biz__Customer_AddAddressBtn Biz__Customer_AddAddressBtn${
                            selectedCustomerMaster ? "Secondary" : "Disabled"
                          }`}
                          onClick={() =>
                            toggleAddSubscriptionPopup(false, null)
                          }
                          disabled={!selectedCustomerMaster}
                        >
                          Add Subscription
                        </button>
                      </>
                    ) : null}
                  </div>
                  <div className="Customer__MDB_ContentBox">
                    <div className="Customer__MDB_ContentMDiv">
                      <div
                        className={
                          activeTabId === "AD"
                            ? "Customer__MDB_ContentDiv ActiveCustomer__MDB_ContentDiv"
                            : "Customer__MDB_ContentDiv"
                        }
                      >
                        {customerAddressFilterdList &&
                        customerAddressFilterdList.length > 0 ? (
                          customerAddressFilterdList.map((data, i) => {
                            return (
                              <AddressCard
                                key={i}
                                address={data}
                                selectedAddress={selectedAddress}
                                setMsgAlert={setMsgAlert}
                                setConfirmAlert={setConfirmAlert}
                                toggleAddCustomerAddress={
                                  toggleAddCustomerAddress
                                }
                                customerAddressFilterdList={
                                  customerAddressFilterdList
                                }
                                setCustomerAddressFilterdList={
                                  setCustomerAddressFilterdList
                                }
                              />
                            );
                          })
                        ) : (
                          <SkeletonDtboxData />
                        )}
                      </div>
                      <div
                        className={
                          activeTabId === "GST"
                            ? "Customer__MDB_ContentDiv ActiveCustomer__MDB_ContentDiv"
                            : "Customer__MDB_ContentDiv"
                        }
                      >
                        {gstFilteredList ? (
                          gstFilteredList.map((data, i) => {
                            return (
                              <GSTCard
                                key={i}
                                GstData={data}
                                setMsgAlert={setMsgAlert}
                                gstFilteredList={gstFilteredList}
                                setGstFilteredList={setGstFilteredList}
                                toggleAddGSTPopup={toggleAddGSTPopup}
                                setConfirmAlert={setConfirmAlert}
                              />
                            );
                          })
                        ) : (
                          <SkeletonDtboxData />
                        )}
                      </div>
                      <div
                        className={
                          activeTabId === "SB"
                            ? "Customer__MDB_ContentDiv ActiveCustomer__MDB_ContentDiv_sub"
                            : "Customer__MDB_ContentDiv"
                        }
                      >
                        <SubScription
                          gridApi={gridApi}
                          onGridReady={onGridReady}
                          customerSubscriptionList={customerSubscriptionList}
                          /* isLoadingSubscriptionList={isLoadingSubscriptionList} */
                          selectedCustomerMaster={selectedCustomerMaster}
                          getCustomerSubscriptionList={
                            getCustomerSubscriptionList
                          }
                          toggleAddSubscriptionPopup={
                            toggleAddSubscriptionPopup
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer></Footer>
          </>
        </div>
      </div>
    </>
  );
}

export default Customer;

function AddressCard(props) {
  const ref = useRef();
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  useOnClickOutside(ref, () => setAddModalOpen(false));
  let setMsgAlert = props.setMsgAlert;
  function useOnClickOutside(ref, handler) {
    useEffect(() => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    }, [ref, handler]);
  }
  const handleDeleteAddress = async (e) => {
    try {
      if (e) {
        e.preventDefault();
      }

      props.setConfirmAlert({
        open: true,
        title: "Confirmation",
        msg: "Are you sure to Delete ?",
        button: {
          confirmText: "Delete",
          abortText: "Cancel",
          Color: "Danger",
        },
        onConfirm: () => deleteAddress(props.address.CustomerAddressID),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };
  const deleteAddress = async (CustomerAddressID) => {
    try {
      let result = await DeleteAddress(
        CustomerAddressID,
        `${APIEndpoints.Customer.DeleteAddress}/${CustomerAddressID}`
      );

      if (result.responseCode === 1) {
        setMsgAlert({
          open: true,
          type: "success",
          msg: result.responseMessage,
        });
        const results = props.customerAddressFilterdList.filter((el) => {
          return el.CustomerAddressID !== props.address.CustomerAddressID;
        });
        props.setCustomerAddressFilterdList(results);
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  return (
    <div className="Customer__MDB_AddContBox">
      <div className="Customer__MDB_AddContBox_Header">
        <h4>
          <p>
            {props.address && props.address.AddressCode
              ? props.address.AddressCode
              : ""}
          </p>
        </h4>
        <div ref={ref}>
          <BsThreeDots
            className="Biz_Icon"
            onClick={() => setAddModalOpen(!isAddModalOpen)}
          />
          {isAddModalOpen ? (
            <div
              className={
                isAddModalOpen ? "DynBiz_ContextMenu" : "DynBiz_ContextMenu"
              }
            >
              <button
                className="DynBiz_ContextMenu_list"
                // onClick={() => props.toggleAddressAssignmentPopup(address)}
              >
                <MdOutlineLocationOn />
                <span>Location Assignment</span>
              </button>
              <button
                className="DynBiz_ContextMenu_list"
                onClick={() =>
                  props.toggleAddCustomerAddress(true, props.address)
                }
              >
                <MdOutlineModeEdit />
                <span>Edit</span>
              </button>
              <button
                className="DynBiz_ContextMenu_list"
                onClick={() => handleDeleteAddress()}
              >
                <MdDeleteForever />
                <span>Delete</span>
              </button>
              {/* {address.IsDeleted === 0 ? (
                <button
                  className="DynBiz_ContextMenu_list"
                  //   onClick={() => props.onClickDeleteAddress(address, "Disable")}
                >
                  <MdOutlineLocationOn />
                  <span>Disable</span>
                </button>
              ) : (
                <button
                  className="DynBiz_ContextMenu_list"
                  //   onClick={() => props.onClickDeleteAddress(address, "Enable")}
                >
                  <MdOutlineLocationOn />
                  <span>Enable</span>
                </button>
              )} */}
            </div>
          ) : null}
        </div>
      </div>
      <div
        className={
          "Customer__MDB_AddContBox_Body"
          // : "Customer__MDB_AddContBox_Body Customer__MDB_AddContBox_Deleted"
        }
      >
        <p>
          {props.address && props.address.AddressLine1
            ? props.address.AddressLine1
            : ""}
        </p>
        <p>
          {props.address && props.address.AddressLine2
            ? props.address.AddressLine2
            : ""}
        </p>
        <p>
          {props.address && props.address.CityPinCode
            ? props.address.CityPinCode
            : ""}
        </p>
        <div className="Customer__TaxContent"></div>
      </div>
    </div>
  );
}

function GSTCard(props) {
  const ref = useRef();
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  let setMsgAlert = props.setMsgAlert;
  useOnClickOutside(ref, () => setAddModalOpen(false));
  function useOnClickOutside(ref, handler) {
    useEffect(() => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    }, [ref, handler]);
  }

  const deleteGST = async (CustomerGSTID) => {
    try {
      let result = await DeleteGSTMaster(
        CustomerGSTID,
        `${APIEndpoints.Customer.DeleteGST}/${CustomerGSTID}`
      );

      if (result.responseCode === 1) {
        setMsgAlert({
          open: true,
          type: "success",
          msg: result.responseMessage,
        });
        const results = props.gstFilteredList.filter((el) => {
          return el.CustomerGSTID !== props.GstData.CustomerGSTID;
        });
        props.setGstFilteredList(results);
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const handleDeleteGST = async (e) => {
    try {
      if (e) {
        e.preventDefault();
      }

      props.setConfirmAlert({
        open: true,
        title: "Confirmation",
        msg: "Are you sure to Delete ?",
        button: {
          confirmText: "Delete",
          abortText: "Cancel",
          Color: "Danger",
        },
        onConfirm: () => deleteGST(props.GstData.CustomerGSTID),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  return (
    <div className="Customer__MDB_AddContBox">
      <div
        className="Customer__MDB_AddContBox_Header"
        style={{
          backgroundColor:
            props.GstData?.ActiveStatus === 1 ? "#c15d5d" : "#9aa5b6",
        }}
      >
        <h4>
          <p>
            {props.GstData && props.GstData.CustomerName
              ? props.GstData.CustomerName
              : ""}
          </p>
        </h4>
        <div ref={ref}>
          <BsThreeDots
            className="Biz_Icon"
            onClick={() => setAddModalOpen(!isAddModalOpen)}
          />
          {isAddModalOpen ? (
            <div
              className={
                isAddModalOpen ? "DynBiz_ContextMenu" : "DynBiz_ContextMenu"
              }
            >
              {/* <button
                className="DynBiz_ContextMenu_list"
                // onClick={() => props.toggleAddressAssignmentPopup(address)}
              >
                <MdOutlineLocationOn />
                <span>Location Assignment</span>
              </button> */}
              <button
                className="DynBiz_ContextMenu_list"
                onClick={() => props.toggleAddGSTPopup(true, props.GstData)}
              >
                <MdOutlineModeEdit />
                <span>Edit</span>
              </button>
              <button
                className="DynBiz_ContextMenu_list"
                onClick={() => handleDeleteGST()}
              >
                <MdDeleteForever />
                <span>Delete</span>
              </button>
              {/* {address.IsDeleted === 0 ? (
                <button
                  className="DynBiz_ContextMenu_list"
                  //   onClick={() => props.onClickDeleteAddress(address, "Disable")}
                >
                  <MdOutlineLocationOn />
                  <span>Disable</span>
                </button>
              ) : (
                <button
                  className="DynBiz_ContextMenu_list"
                  //   onClick={() => props.onClickDeleteAddress(address, "Enable")}
                >
                  <MdOutlineLocationOn />
                  <span>Enable</span>
                </button>
              )} */}
            </div>
          ) : null}
        </div>
      </div>
      <div
        className={
          "Customer__MDB_AddContBox_Body"
          // : "Customer__MDB_AddContBox_Body Customer__MDB_AddContBox_Deleted"
        }
      >
        <p>
          {props.GstData && props.GstData.StateName
            ? props.GstData.StateName
            : ""}
        </p>
        <p>{props.GstData && props.GstData.GSTNo ? props.GstData.GSTNo : ""}</p>
        <p>
          {" "}
          {props.GstData && props.GstData.GSTUserID
            ? props.GstData.GSTUserID
            : ""}
        </p>

        <div className="Customer__TaxContent"></div>
      </div>
    </div>
  );
}

// const handleKeyDown = (e) => {
//   if (e.keyCode === EnterKeyCode) {
//     e.preventDefault();
//     // onClickSearch();
//   }
// };
