import React, { useState, useRef, useEffect, useCallback } from "react";
import PageTitle, {
  PageButton,
  PageSearch,
  PageSelect,
} from "../../../../../Framework/OldFramework/PageTitle/PageTitle";
import Footer, {
  FooterBox,
} from "../../../../../Framework/OldFramework/Footer/Footer";
import DataGrid from "../../../../../Framework/Components/Common/DataGrid/DataGrid";
import { CustomMsg } from "../../../../../Configration/Utilities/customMessage";
import Loader from "../../../../../Framework/OldFramework/Loader/Loader";
import ConfirmDialog from "../../../../../Framework/OldFramework/ConfirmDialog/ConfirmDialog";
import ErrorMessage from "../../../../../Framework/OldFramework/ErrorAlert/ErrorMessage";
// import { getMasterDataFile, getCompanyMasterList } from "../../../../../APIMethods/Setup/Finance/CompanyMasterAdaptor";
import { Success } from "../../../../../Configration/Utilities/Constants";
import AddCompanyMaster from "./Modal/AddCompanyMaster/AddCompanyMaster";

function CompanyMaster() {
  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [confirmAlert, setConfirmAlert] = useState({
    open: false,
    title: "",
    msg: "",
    onConfirm: null,
    button: { confirmText: "", abortText: "", Color: "" },
  });

  const [gridApi, setGridApi] = useState();
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const [isLoadingMasterList, setIsLoadingMasterList] = useState(false);
  const [masterList, setMasterList] = useState([]);
  //   const getCompanyMasterList = async () => {
  //     try {
  //       let formData = {
  //         masterDataCode: "",
  //       };
  //       setIsLoadingMasterList(true);
  //       let result = await getMasterDataFile(formData);
  //       setIsLoadingMasterList(false);
  //       console.log(result);
  //       if (result.responseCode === Success) {
  //         if (result.responseData && result.responseData) {
  //           setMasterList(result.responseData);
  //         } else {
  //           setMasterList([]);
  //         }
  //       } else {
  //         setMasterList([]);
  //         setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
  //       }
  //     } catch (error) {
  //       setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
  //     }
  //   };

  //   useEffect(() => {
  //     getCompanyMasterList();
  //   }, []);

  //   const updateState = (name, value) => {
  //     setFormValues({ ...formValues, [name]: value });
  //     if (name === "") {
  //       setUpdatedGridName([]);
  //       if (gridApi) {
  //         gridApi.setRowData([]);
  //       }
  //       if (value) {
  //         getCompanyMasterData(value);
  //       }
  //     }
  //   };

  const [formValues, setFormValues] = useState({
    txtCompanyMaster: null,
  });

  const [addCompanyMasterPopup, setAddCompanyMasterPopup] = useState(false);
  const toggleCompanyMasterPopup = () => {
    setAddCompanyMasterPopup(!addCompanyMasterPopup);
  };

  const [searchMappedData, setSearchMappedData] = useState("");
  const onSearchMappingData = (val) => {
    setSearchMappedData(val);
    gridApi.setQuickFilter(val);
    gridApi.refreshCells();
  };

  const onClickSerachMappingData = () => {
    //   if (formValues.txtCompanyMaster) {
    //     if (searchMappedData && searchMappedData.length >= 3) {
    //       getCompanyMasterData(formValues.txtCompanyMaster);
    //     } else {
    //       setMsgAlert({
    //         open: true,
    //         type: "warning",
    //         msg: "Please type at least 3 character.",
    //       });
    //       return;
    //     }
    //   } else {
    //     setMsgAlert({
    //       open: true,
    //       type: "warning",
    //       msg: "Please Select Ledger type First",
    //     });
    //     return;
    //   }
  };

  const gridColumnDefs = [
    {
      field: "#",
      headerName: "Action",
      width: 80,
      pinned: "left",
      lockPosition: "1",
    },
    {
      field: "#",
      headerName: "Sr.No.",
      width: 80,
      pinned: "left",
      lockPosition: "2",
      valueGetter: "node.rowIndex + 1",
    },
    { field: "CompanyCode", headerName: "Company Code", width: 180 },
    { field: "CompanyName", headerName: "Company Name", width: 180 },
    {
      field: "DateOfRegistration",
      headerName: "Date Of Registration",
      width: 200,
    },
    {
      field: "PANNo",
      headerName: "PAN No",
      width: 120,
      cellRenderer: "isMandatoryTemplate",
    },
    {
      field: "InvoiceAPIRequestCount",
      headerName: "Invoice API Request Count",
      width: 200,
      cellRenderer: "isMandatoryTemplate",
    },
    {
      field: "EwayBillAPIRequestCount",
      headerName: "EwayBill API Request Count",
      width: 200,
      cellRenderer: "isMandatoryTemplate",
    },
  ];

  return (
    <React.Fragment>
      {msgAlert.open && (
        <ErrorMessage msgAlert={msgAlert} setMsgAlert={setMsgAlert} />
      )}
      {confirmAlert.open && (
        <ConfirmDialog
          confirmAlert={confirmAlert}
          setConfirmAlert={setConfirmAlert}
        />
      )}
      {addCompanyMasterPopup ? (
        <AddCompanyMaster
          toggleCompanyMasterPopup={toggleCompanyMasterPopup}
          setMsgAlert={setMsgAlert}
          setConfirmAlert={setConfirmAlert}
        />
      ) : null}
      <div className={`Biz_Listing_PageStart`}>
        <PageTitle Title="Company List">
          <PageSearch
            focus={true}
            onClick={() => onClickSerachMappingData()}
            value={searchMappedData}
            onChange={(e) => onSearchMappingData(e.target.value)}
          />
          <PageButton onClick={() => toggleCompanyMasterPopup()}>
            Add
          </PageButton>
        </PageTitle>
        <DataGrid
          rowData={masterList}
          columnDefs={gridColumnDefs}
          onGridReady={onGridReady}
          loader={isLoadingMasterList ? <Loader /> : null}
          getRowStyle={function (data) {
            if (data.data.IsSelected) {
              return { background: "#ffc176" };
            } else {
              if (data.data.IsNewlyAdded) {
                return { background: "#d5a10e" };
              }
              return { background: "" };
            }
          }}
        />

        <Footer>
          <FooterBox Aligned={"right"}></FooterBox>
        </Footer>
      </div>
    </React.Fragment>
  );
}

export default CompanyMaster;
