import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import ErrorMessage from "../../../../Framework/OldFramework/ErrorAlert/ErrorMessage";
import {
  FaPhoneAlt,
  FaEnvelope,
  FaExclamationTriangle,
  FaLock,
  FaUser,
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaGlobe,
} from "react-icons/fa";
import Logo from "../../../../Framework/Assets/images/Logo/BizNextLogo";
import BiznextLogo from "../../../../Framework/Assets/images/Logo/BizNextLogo";
import LoginPageImage from "../../../../Framework/Assets/images/Banner/gst.jpg";
import OtpInput from "react18-input-otp";
import BizClass from "./Login.module.scss";
import AddLoginLogics from "./Logic/AddLoginLogics";
import { useForm } from "react-hook-form";
import { CustomMsg } from "../../../../Configration/Utilities/customMessage";
import { useNavigate } from "react-router-dom";
import { setSessionStorage } from "../../../../APIMethods/Auth/auth";
import { authenticate, newAuthenticate } from "./Services/Method";
import moment from "moment";

function Login(props) {
  const {
    formValues,
    updateState,
    // handleLogin,
    SearchByHandleKeyDown,
    isLoading,
    validationFormError,
    ActiveTab,
    setActiveTab,
    otpFormProps,
  } = AddLoginLogics();
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [revealPassword, setRevealPassword] = useState(false);
  const navigate = useNavigate();
  const togglePassword = () => {
    setRevealPassword(!revealPassword);
  };
  const [msgAlert, setMsgAlert] = useState({ open: false, type: "", msg: "" });
  const [btnloaderActive, setBtnloaderActive] = useState(false);

  const onSubmit = async () => {
    try {
      setBtnloaderActive(true);
      let result = await newAuthenticate(
        formValues.txtLoginId,
        formValues.txtPassword,
        "SPIRAL"
      );
      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        if (result.responseData) {
          if (
            !(
              result.responseData.ApiToken &&
              result.responseData.ApiToken.Token &&
              result.responseData.ApiToken.ExpiryTime
            )
          ) {
            setMsgAlert({
              open: true,
              type: "error",
              msg: "Token is missing in the response",
            });
            return;
          } else if (!result.responseData.UserData) {
            setMsgAlert({
              open: true,
              type: "error",
              msg: "User information is missing in the response",
            });
            return;
          }
          sessionStorage.clear();
          let parameters = {};
          let mappedModule =
            result.responseData && result.responseData.Modules
              ? result.responseData.Modules.map((md, i) => {
                  return {
                    ModuleID:
                      md && md.ModuleMasterID
                        ? md.ModuleMasterID.toString()
                        : "",
                    ModuleName: md && md.ModuleName ? md.ModuleName : "",
                    ModuleSequence:
                      md && md.DisplaySequence ? md.DisplaySequence : i + 1,
                    IconImage: md && md.IconImage ? md.IconImage : "",
                  };
                })
              : [];

          let meppedMenu =
            result.responseData && result.responseData.Menus
              ? result.responseData.Menus.map((mn, i) => {
                  return {
                    ModuleCode:
                      mn && mn.ModuleMasterID
                        ? mn.ModuleMasterID.toString()
                        : "0",
                    MenuID:
                      mn && mn.MenuMasterID ? mn.MenuMasterID.toString() : "0",
                    MenuName: mn && mn.MenuName ? mn.MenuName : "",
                    UnderMenu:
                      mn && mn.UnderMenuID ? mn.UnderMenuID.toString() : "0",
                    MenuSequence:
                      mn && mn.MenuSequence ? mn.MenuSequence : i + 1,
                    WebURL: mn && mn.WebURL ? mn.WebURL : "#",
                    WinURL: mn && mn.WinURL ? mn.WinURL : "#",
                    WPFURL: mn && mn.WPFURL ? mn.WPFURL : "#",
                    ReactURL: mn && mn.ReactURL ? mn.ReactURL : "#",
                  };
                })
              : [];

          if (
            result &&
            result.responseData &&
            result.responseData.Parameters &&
            result.responseData.Parameters.length > 0
          ) {
            result.responseData.Parameters.map((pr) => {
              if (pr.BiznextParameterName === "ACTIVATE_WEIGHT") {
                parameters["ActiveItemWeight"] = pr.ParameterValue;
              }
              if (pr.BiznextParameterName === "DATE_FORMAT_SHORT") {
                parameters["DateShort"] = pr.ParameterValue;
              }
              if (pr.BiznextParameterName === "DATE_FORMAT_LONG") {
                parameters["DateLong"] = pr.ParameterValue;
              }
              if (pr.BiznextParameterName === "ACTIVATE_DIMENSION") {
                parameters["ActiveDimension"] = pr.ParameterValue;
              }
              if (pr.BiznextParameterName === "QUANTITY_TYPE") {
                parameters["QuantityType"] = pr.ParameterValue;
              }
              if (pr.BiznextParameterName === "DECIMAL_POINTS") {
                parameters["InvDecimalPoint"] = pr.ParameterValue;
              }
              if (pr.BiznextParameterName === "EXPENSE_PLANNING_METHOD") {
                parameters["SaleBudgetBy"] = pr.ParameterValue;
              }
            });
          }
          let ApiUrl = result?.responseData?.Settings?.ApiBaseURL || "";

          const user = {
            AppAccessUID: result?.responseData?.UserData?.UserLoginID || "",
            ClientName: result?.responseData?.Settings?.ClientName || "",
            ClientCode: result?.responseData?.Settings?.ClientCode || "",
            BMCAppAccessCode:
              result?.responseData?.UserData?.BMCAppAccessCode || "",
            LoginType: "",
            BaseURL: `${ApiUrl}`,
            ApplicationURL: result?.responseData?.Settings?.WebURL || "",
            ApiToken: {
              ApiToken: result?.responseData?.ApiToken?.Token || "",
              ExpiryDate: new Date(
                moment().add(
                  result?.responseData?.ApiToken?.ExpiryTime || 1800,
                  "seconds"
                )
              ),
              ExpirationTime:
                result?.responseData?.ApiToken?.ExpiryTime || 1800,
            },
            UserData: {
              SessionTime: Math.floor(
                result?.responseData?.ApiToken?.ExpiryTime || 1800
              ),
              ClientLogo: "../Test/Test.png",
              UserID: result?.responseData?.UserData?.CompanyUserID || "",
              UserDisplayName:
                result?.responseData?.UserData?.UserDisplayName || "",
              EmployeeCode:
                result?.responseData?.UserData?.UserRelationID || "",
              ...result?.responseData?.Settings,
              ...parameters,
              APIURL: `${ApiUrl}`,
            },

            Module: mappedModule,
            UserMenu: meppedMenu,
            CompanyCode: "",
            ClientCode: "",
          };

          console.log(user, "user Sesson");
          setSessionStorage("user", user);
          navigate("/home");
          props.loginHandler(true);
        } else {
          setMsgAlert({ open: true, type: "error", msg: "Login Error!" });
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  return (
    <div className={BizClass.MainDiv}>
      {msgAlert.open && (
        <ErrorMessage msgAlert={msgAlert} setMsgAlert={setMsgAlert} />
      )}
      <div className={BizClass.ImgDiv}>
        <div className={BizClass.SupportDiv}>
          <div className={BizClass.SupportCard}>
            <span className={BizClass.supportext}>
              Support<span>24</span>
            </span>
            <a href="tel:9541414155" className={BizClass.supporicon}>
              <FaPhoneAlt className={BizClass.Icon} />
              +91 9541414155
            </a>
            <a
              href="mailto:support@3slogics.com"
              className={BizClass.supporicon}
            >
              <FaEnvelope className={BizClass.Icon} />
              support@3slogics.com
            </a>
          </div>
        </div>
        <div className={BizClass.ImgBox}>
          <img src={LoginPageImage} className={BizClass.SvImg} alt="Banner" />
        </div>
        <div className={BizClass.SocialDiv}>
          <div className={BizClass.socialbtns}>
            <p className={BizClass.socialbtntxt}>
              Visit us on our Social Accounts
            </p>
            <a
              className={classNames(BizClass.facebookbtn, BizClass.socialbtn)}
              href={"https://www.facebook.com/BIZNEXT3S/"}
              target={"_blank"}
              rel={"noreferrer"}
            >
              <FaFacebookF className={BizClass.Icon} />
            </a>
            <a
              className={classNames(BizClass.twitterbtn, BizClass.socialbtn)}
              href={"https://twitter.com/Biznext2"}
              target={"_blank"}
              rel={"noreferrer"}
            >
              <FaTwitter className={BizClass.Icon} />
            </a>
            <a
              className={classNames(BizClass.linkedinbtn, BizClass.socialbtn)}
              href={"https://www.linkedin.com/showcase/biznextbeyonderp/"}
              target={"_blank"}
              rel={"noreferrer"}
            >
              <FaLinkedinIn className={BizClass.Icon} />
            </a>
            <a
              className={classNames(BizClass.websitebtn, BizClass.socialbtn)}
              href={"https://www.mybiznext.in/"}
              target={"_blank"}
              rel={"noreferrer"}
            >
              <FaGlobe className={BizClass.Icon} />
            </a>
          </div>
        </div>
      </div>
      <div className={BizClass.FormDiv}>
        <div className={BizClass.MainForm}>
          <div className={BizClass.logoBar}>
            <div className={BizClass.clientlogo}>
              <BiznextLogo className={BizClass.SvImg} />
            </div>
          </div>
          <div className={BizClass.FormBox}>
            {/* <form onSubmit={(e) => handleLogin(e)}> */}
            <form onSubmit={handleSubmit(onSubmit)}>
              {ActiveTab === "LG" && (
                <>
                  <div
                    className={classNames(
                      BizClass.FormGroup,
                      validationFormError["txtLoginId"]
                        ? BizClass.Form_error
                        : null
                    )}
                  >
                    <span className={BizClass.FormIcon}>
                      <FaUser className={BizClass.Icon} />
                    </span>

                    <input
                      type="text"
                      className={BizClass.Form__control}
                      name="txtLoginId"
                      value={formValues.txtLoginId}
                      onChange={(e) =>
                        updateState(e.target.name, e.target.value)
                      }
                      placeholder="Login ID"
                    />

                    {validationFormError["txtLoginId"] ? (
                      <div className={BizClass.ErrorDiv}>
                        <div className={BizClass.ErrorIcon}>
                          <FaExclamationTriangle className={BizClass.Icon} />
                        </div>
                        <div className={BizClass.ErrorTxt}>
                          {validationFormError["txtLoginId"]}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={classNames(
                      BizClass.FormGroup,
                      validationFormError["txtPassword"]
                        ? BizClass.Form_error
                        : null
                    )}
                  >
                    <span className={BizClass.FormIcon}>
                      <FaLock className={BizClass.Icon} />
                    </span>

                    <input
                      type={revealPassword ? "text" : "password"}
                      className={BizClass.Form__control}
                      value={formValues.txtPassword}
                      name="txtPassword"
                      onKeyDown={(e) => SearchByHandleKeyDown(e)}
                      onChange={(e) =>
                        updateState(e.target.name, e.target.value)
                      }
                      placeholder="Password"
                    />

                    <button
                      type="button"
                      className={BizClass.TooglePasswordBox}
                    >
                      {revealPassword ? (
                        <AiFillEyeInvisible
                          className={classNames(
                            BizClass.pass_icon,
                            BizClass.Bizshowpass
                          )}
                          onClick={() => togglePassword()}
                        />
                      ) : (
                        <AiFillEye
                          className={classNames(
                            BizClass.pass_icon,
                            BizClass.Bizshowpass
                          )}
                          onClick={() => togglePassword()}
                        />
                      )}
                    </button>
                    {validationFormError["txtPassword"] ? (
                      <div className={BizClass.ErrorDiv}>
                        <div className={BizClass.ErrorIcon}>
                          <FaExclamationTriangle className={BizClass.Icon} />
                        </div>
                        <div className={BizClass.ErrorTxt}>
                          {validationFormError["txtPassword"]}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <button
                    // onClick={() => setActiveTab("OTP")}

                    type="submit"
                    className={classNames(
                      BizClass.FormBtn,
                      isLoading ? BizClass.FormBtnActive : null
                    )}
                  >
                    {!isLoading ? (
                      <div className={BizClass.BtnTxt}>Login</div>
                    ) : (
                      <div className={BizClass.btn__spinner}>
                        <div className={BizClass.btn__bounce1} />
                        <div className={BizClass.btn__bounce2} />
                        <div className={BizClass.btn__bounce3} />
                      </div>
                    )}
                  </button>
                </>
              )}
              {/* {ActiveTab === "OTP" && (
                <OTPVerificationPage otpFormProps={otpFormProps} />
              )} */}
            </form>
          </div>
        </div>
        <div className={BizClass.footer}>
          <span>© Product of 3S Logics Pvt. Ltd</span>
        </div>
      </div>
    </div>
  );
}

export default Login;

// function OTPVerificationPage({ otpFormProps }) {
//   const {
//     formValues,
//     updateOTPFormState,
//     onClickOTPVerifiy,
//     isLoading,
//     otpAuthenticationFormError,
//     SearchByHandleKeyDown,
//     otptimer,
//     setOtpTimer,
//     requestOtpAgain,
//   } = otpFormProps;
//   const timeOutCallback = useCallback(
//     () => setOtpTimer((currTimer) => currTimer - 1),
//     []
//   );

//   useEffect(() => {
//     if (otptimer > 0) {
//       setTimeout(timeOutCallback, 1000);
//     }
//   }, [otptimer, timeOutCallback]);

//   return (
//     <>
//       <div
//         className={classNames(
//           BizClass.OtpGroup,
//           otpAuthenticationFormError &&
//             otpAuthenticationFormError["txtotp"] &&
//             BizClass.ErrorOtpGroup
//         )}
//       >
//         <h3>Enter verification code </h3>
//         <OtpInput
//           inputStyle="inputStyle"
//           placeholder="------"
//           numInputs={6}
//           isInputNum={true}
//           shouldAutoFocus
//           value={formValues.txtotp}
//           onChange={(value) => updateOTPFormState("txtotp", value)}
//         />
//         <div className={BizClass.ResendGroup}>
//           <p>Didn&apos;t receive code ?</p>
//           <button
//             type="button"
//             onClick={() => requestOtpAgain()}
//             className={otptimer > 0 && BizClass.disabled}
//           >
//             Request again
//           </button>
//           <span>({otptimer})</span>
//         </div>
//       </div>
//       <div className={BizClass.ButtonGroup}>
//         <button
//           type="button"
//           className={classNames(
//             BizClass.OTPFormBtn,
//             isLoading ? BizClass.OTPFormBtnActive : null
//           )}
//           onClick={() => onClickOTPVerifiy()}
//           disabled={formValues.txtotp.length !== 6 && true}
//           onKeyDown={(e) => SearchByHandleKeyDown(e)}
//         >
//           {!isLoading ? (
//             <div className={BizClass.BtnTxt}> Verify And Continue</div>
//           ) : (
//             <div className={BizClass.btn__spinner}>
//               <div className={BizClass.btn__bounce1} />
//               <div className={BizClass.btn__bounce2} />
//               <div className={BizClass.btn__bounce3} />
//             </div>
//           )}
//         </button>
//       </div>
//     </>
//   );
// }
