import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../../../../../../Framework/Components/Layout/Modal/Modal";
import Form from "../../../../../../../../Framework/Components/Layout/FormGroup/FormGroup";
import Button from "../../../../../../../../Framework/OldFramework/Button/Button";
import { getSessionStorage } from "../../../../../../../../APIMethods/Auth/auth";
import { publicIpv4 } from "public-ip";
import {
  CreateGST,
  getAPIMasterListSelect,
  getStateMasterSelect,
  updateCustomerGST,
} from "../../Resources/CustomerService";
import { CustomMsg } from "../../../../../../../../Configration/Utilities/customMessage";
import APIEndpoints from "../../../../../../../../APIMethods/APIEndpoints/APIEndpoints";

const AddGst = (props) => {
  let isEditMode = props.isEditMode;
  let popupData = props.popupData;
  let menuData = props.menuData;
  let updateGSTList = props.updateGSTList;
  let selectedCustomerMaster = props.selectedCustomerMaster;
  let setMsgAlert = props.setMsgAlert;
  let toggleAddGSTPopup = props.toggleAddGSTPopup;
  let getCustomerGSTList = props.getCustomerGSTList;

  const [editCustomerGST, setEditCustomerGST] = useState();
  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const [formValues, setFormValues] = useState({
    txtCustomerMasterID: 0,
    txtState: null,
    txtGSTNo: "",
    txtGSTUserID: "",
    txtGSTSecurityKey: "",
    txtContactPerson: "",
    txtContactMobileNumber: "",
    txtContactEmail: "",
    txtRemarks: "",
    txtPanNo: "",
    txtStateTaxCode: "",
    isActive: true,
  });

  const firstTextInput = useRef();

  useEffect(() => {
    if (isEditMode) {
      if (menuData) {
        setEditCustomerGST(popupData);
      }
    }
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  }, []);

  useEffect(() => {
    if (editCustomerGST) {
      if (editCustomerGST && editCustomerGST.CustomerGSTID) {
        setFormValues({
          txtState: {
            StateMasterID: popupData,
            StateName: popupData.StateName,
          },
          txtStateTaxCode: editCustomerGST.GSTNo.slice(0, 2),
          txtGSTNo: editCustomerGST.GSTNo.slice(-3),
          txtGSTUserID: editCustomerGST.GSTUserID
            ? editCustomerGST.GSTUserID
            : "",
          txtGSTSecurityKey: editCustomerGST.GSTSecurityKey
            ? editCustomerGST.GSTSecurityKey
            : "",
          txtContactPerson: editCustomerGST.ContactPerson
            ? editCustomerGST.ContactPerson
            : "",
          txtContactMobileNumber: editCustomerGST.ContactMobileNo
            ? editCustomerGST.ContactMobileNo
            : "",
          txtContactEmail: editCustomerGST.ContactEmailAddress
            ? editCustomerGST.ContactEmailAddress
            : "",
          txtRemarks: editCustomerGST.UpdateRemarks || "",
          isActive: editCustomerGST.ActiveStatus === 1 ? true : false,
        });
      }
    }
  }, [editCustomerGST]);

  const [formValidationError, setFormValidationError] = useState({});

  const validateField = (name, value) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    let errorsMsg = "";
    if (name === "txtGSTNo") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Enter last 3 Of GST NO";
      }
    } else if (name === "txtGSTUserID") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Cannot be empty";
      }
    } else if (name === "txtGSTSecurityKey") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Cannot be empty";
      }
    } else if (name === "txtContactPerson") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Cannot be empty";
      }
    } else if (name === "txtContactMobileNumber") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Cannot be empty";
      }
      let r = "^[0-9\b]+$";
      const regex = new RegExp(r);
      if (!regex.test(value)) {
        errorsMsg = "Mobile No is not valid";
      }
    } else if (name === "txtContactEmail") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Cannot be empty";
      } else if (!emailRegex.test(value)) {
        errorsMsg = "Enter valid Email ID";
      }
    } else if (name === "txtState") {
      if (!value || typeof value === "undefined") {
        errorsMsg = "State Cannot be empty";
      }
    }
    return errorsMsg;
  };

  const updateState = (name, value) => {
    setFormValues({
      ...formValues,
      txtPanNo: selectedCustomerMaster.PANNO,
      [name]: value,
    });
    const errorMsg = validateField(name, value);
    setFormValidationError((errors) => ({ ...errors, [name]: errorMsg }));
    if (name === "txtState") {
      if (value && value.StateTaxCode) {
        setFormValues((e) => ({
          ...e,
          txtStateTaxCode: value.StateTaxCode.toString().padStart(2, "0"),
        }));
      }
    }
  };

  const handleValidation = () => {
    let errors = {};
    errors["txtState"] = validateField("txtState", formValues.txtState);
    errors["txtGSTNo"] = validateField("txtGSTNo", formValues.txtGSTNo);
    errors["txtGSTUserID"] = validateField(
      "txtGSTUserID",
      formValues.txtGSTUserID
    );
    errors["txtGSTSecurityKey"] = validateField(
      "txtGSTSecurityKey",
      formValues.txtGSTSecurityKey
    );
    errors["txtContactPerson"] = validateField(
      "txtContactPerson",
      formValues.txtContactPerson
    );
    errors["txtContactMobileNumber"] = validateField(
      "txtContactMobileNumber",
      formValues.txtContactMobileNumber
    );
    errors["txtContactEmail"] = validateField(
      "txtContactEmail",
      formValues.txtContactEmail
    );
    setFormValidationError(() => errors);
    for (let key in errors) {
      if (errors[key]) {
        return false;
      }
    }

    return true;
  };

  const handleSave = async (e) => {
    try {
      if (e) {
        e.preventDefault();
      }
      if (!handleValidation()) {
        return;
      }
      props.setConfirmAlert({
        open: true,
        title: "Confirmation",
        msg: isEditMode ? "Are you sure to Update" : "Are you sure to Save ?",
        button: {
          confirmText: isEditMode ? "Update" : "Save",
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () => onSubmit(),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const onSubmit = async () => {
    try {
      setBtnloaderActive(true);
      let requestData = {};
      if (isEditMode) {
        requestData = {
          CustomerGSTID: editCustomerGST.CustomerGSTID,
          CustomerMasterID: menuData.CustomerMasterID,
          GSTUserID: formValues.txtGSTUserID,
          GSTSecurityKey: formValues.txtGSTSecurityKey,
          ContactPerson: formValues.txtContactPerson,
          ContactMobileNo: formValues.txtContactMobileNumber,
          ContactEmailAddress: formValues.txtContactEmail,
          Remarks: formValues.txtRemarks,
          ActveStatus: formValues.isActive ? 1 : 0,
        };
      } else {
        requestData = {
          CustomerMasterID: menuData.CustomerMasterID,
          StateMasterID:
            formValues.txtState && formValues.txtState.StateMasterID
              ? formValues.txtState.StateMasterID
              : 0,
          GSTNo:
            formValues.txtStateTaxCode.toString().padStart(2, "0") +
            formValues.txtPanNo.toString() +
            formValues.txtGSTNo.toString(),
          GSTUserID: formValues.txtGSTUserID,
          GSTSecurityKey: formValues.txtGSTSecurityKey,
          ContactPerson: formValues.txtContactPerson,
          ContactMobileNo: formValues.txtContactMobileNumber,
          ContactEmailAddress: formValues.txtContactEmail,
          Remarks: formValues.txtRemarks,
          ActveStatus: formValues.isActive ? 1 : 0,
        };
      }

      let fun = isEditMode ? updateCustomerGST : CreateGST;
      let result = await fun(requestData);
      setBtnloaderActive(false);
      if (result.responseCode === 1) {
        setMsgAlert({
          open: true,
          type: "success",
          msg: result.responseMessage,
        });

        if (result) {
          /* let subMenu = {
            CustomerGSTID: isEditMode
              ? editCustomerGST.CustomerGSTID
              : result &&
                result.responseData &&
                result.responseData.CustomerGSTID
              ? result.responseData.CustomerGSTID
              : 0,
            CustomerMasterID: menuData.CustomerMasterID,
            GSTNo: formValues.txtGSTNo,
            GSTUserID: formValues.txtGSTUserID,
            GSTSecurityKey: formValues.txtGSTSecurityKey,
            ContactPerson: formValues.txtContactPerson,
            ContactMobileNo: formValues.txtContactMobileNumber,
            ContactEmailAddress: formValues.txtContactEmail,
            Remarks: formValues.txtRemarks,
            ActveStatus: formValues.isActive ? 1 : 0,
          }; */
          getCustomerGSTList(props.selectedCustomerMaster.CustomerMasterID);

          // updateGSTList(subMenu);
          // getCustomerGSTList(props.selectedCustomerMaster.CustomerMasterID);
          clearForm();
          toggleAddGSTPopup(false, null);
        } else {
          setMsgAlert({
            open: true,
            type: "error",
            msg: result.responseMessage,
          });
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };
  const [stateList, setStateList] = useState([]);
  const [isLoadingStateList, setIsLoadingStateList] = useState(false);
  const getStateList = async () => {
    try {
      setIsLoadingStateList(true);
      // let result = await getStateMasterSelect(
      //   0,
      //   `${
      //     APIEndpoints.Customer.GetStateMasterList
      //   }?apiMasterID=${0}&apiName=${""}`
      // );
      let result = await getStateMasterSelect(
        0,
        APIEndpoints.Customer.GetStateMasterList
      );
      setIsLoadingStateList(false);
      if (result.responseCode === 1) {
        if (result.responseData && result.responseData.length > 0) {
          setStateList(result.responseData);
        } else {
          setStateList([]);
        }
      } else {
        setMsgAlert({ open: true, type: "error", msg: result.responseMessage });
        setStateList([]);
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const clearForm = () => {
    setFormValues({
      txtCustomerMasterID: 0,
      txtGSTNo: "",
      txtGSTUserID: "",
      txtState: null,
      txtGSTSecurityKey: "",
      txtContactPerson: "",
      txtContactMobileNumber: "",
      txtContactEmail: "",
      txtRemarks: "",
      isActive: false,
      txtRemarks: "",
      txtPanNo: "",
      txtStateTaxCode: "",
    });

    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  // useEffect(
  //   (e) => {
  //     if (stateList && stateList.length > 0) {
  //       handleInputChange(e);
  //     }
  //   },
  //   [stateList]
  // );

  useEffect(() => {
    getStateList();
  }, []);

  useEffect(() => {
    if (stateList && stateList.length > 0) {
      if (selectedCustomerMaster && selectedCustomerMaster.PANNO) {
        setFormValues((e) => ({
          ...e,
          // txtStateTaxCode: stateList.stateGSTCode,
          txtPanNo: selectedCustomerMaster.PANNO,
        }));
      }
    }
  }, [stateList]);

  return (
    <Modal
      varient={"center"}
      title={isEditMode ? "Update Customer GST" : "Add Customer GST"}
      onSubmit={handleSave}
      show={() => toggleAddGSTPopup(false, null)}
    >
      <Modal.Body>
        <Form>
          <Form.Group controlwidth={"520px"}>
            <Form.InputGroup
              errorMsg={formValidationError["txtState"]}
              label="State"
              req={true}
            >
              <Form.InputControl
                getOptionLabel={(option) => `${option.StateName}`}
                value={formValues.txtState}
                isDisabled={isEditMode}
                getOptionValue={(option) => `${option}`}
                options={stateList}
                onChange={(e) => updateState("txtState", e)}
                isSearchable={true}
                isLoading={isLoadingStateList}
                label="State"
                control="select"
              />
            </Form.InputGroup>

            <Form.InputGroup
              errorMsg={formValidationError["txtGSTNo"]}
              label="GST NO"
              req={true}
            >
              {/* <Form.InputControl
                control="input"
                disabled={true}
                style={{ width: "50px" }}
                name="txtStateTaxCode"
                label="StateTaxCode"
                type="text"
                value={formValues.txtStateTaxCode}
              /> */}
              <div style={{ display: "flex" }}>
                <input
                  style={{
                    height: "28px",
                    width: "80px",
                    marginLeft: "5px",
                    border: "1px solid #d6dbdf",
                    padding: "6px 8px",
                    fontSize: "12px",
                    backgroundColor: "rgba(200, 200, 200, 0.5);",
                    fontWeight: "400",
                    color: "#3f4254",
                  }}
                  type="text"
                  disabled={true}
                  placeholder="ST Code"
                  value={formValues.txtStateTaxCode}
                  name="txtStateTaxCode"
                />
                <input
                  style={{
                    height: "28px",
                    width: "100px",
                    marginLeft: "5px",
                    border: "1px solid #d6dbdf",

                    padding: "6px 8px",
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#3f4254",
                  }}
                  type="text"
                  disabled={true}
                  placeholder="PAN No"
                  value={formValues.txtPanNo}
                  name="txtPanNo"
                />
                <input
                  style={{
                    height: "28px",
                    width: "80px",
                    marginLeft: "5px",
                    border: "1px solid #d6dbdf",
                    padding: "6px 8px",
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#3f4254",
                  }}
                  type="text"
                  placeholder="GST No"
                  value={formValues.txtGSTNo}
                  name="txtGSTNo"
                  onChange={(e) => updateState(e.target.name, e.target.value)}
                  disabled={editCustomerGST}
                />
              </div>
            </Form.InputGroup>

            <Form.InputGroup
              errorMsg={formValidationError["txtGSTUserID"]}
              label="GST User"
              req={true}
            >
              <Form.InputControl
                control="input"
                label="GST User ID"
                name="txtGSTUserID"
                type="text"
                value={formValues.txtGSTUserID}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup
              errorMsg={formValidationError["txtGSTSecurityKey"]}
              label="GST Security"
              req={true}
            >
              <Form.InputControl
                label="GST Security Key"
                control="input"
                name="txtGSTSecurityKey"
                type="text"
                value={formValues.txtGSTSecurityKey}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup
              errorMsg={formValidationError["txtContactPerson"]}
              req={true}
              label="ContactPerson"
            >
              <Form.InputControl
                label="Contact Person"
                control="input"
                name="txtContactPerson"
                type="text"
                value={formValues.txtContactPerson}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup
              errorMsg={formValidationError["txtContactMobileNumber"]}
              req={true}
              label="Contact Mobile Number"
            >
              <Form.InputControl
                label="Contact Mobile Number"
                maxLength={10}
                control="input"
                name="txtContactMobileNumber"
                type="text"
                value={formValues.txtContactMobileNumber}
                onChange={(e) =>
                  updateState(e.target.name, e.target.value.replace(/\D/g, ""))
                }
              />
            </Form.InputGroup>

            <Form.InputGroup
              errorMsg={formValidationError["txtContactEmail"]}
              req={true}
              label="Contact Email"
            >
              <Form.InputControl
                label="Contact Email"
                control="input"
                name="txtContactEmail"
                type="text"
                value={formValues.txtContactEmail}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup
              errorMsg={formValidationError["txtRemarks"]}
              req={false}
              label="Remarks"
            >
              <Form.InputControl
                label="Remarks"
                control="input"
                name="txtRemarks"
                type="text"
                value={formValues.txtRemarks}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup
              errorMsg={formValidationError["isActive"]}
              label="Active Status"
            >
              <Form.InputControl
                control="switch"
                label="Active Status"
                name="isActive"
                checked={formValues.isActive}
                onChange={(e) => updateState(e.target.name, e.target.checked)}
              />
            </Form.InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" type="submit" trigger={false}>
          {isEditMode ? "Update" : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddGst;
