import React, { useEffect, useState } from "react";
import "./AddCompanyMaster.scss";
import ErrorMessage from "../../../../../../../Framework/OldFramework/ErrorAlert/ErrorMessage";
import ConfirmDialog from "../../../../../../../Framework/OldFramework/ConfirmDialog/ConfirmDialog";
import Modal from "../../../../../../../Framework/Components/Layout/Modal/Modal";
import Form from "../../../../../../../Framework/Components/Layout/FormGroup/FormGroup";

import { Success } from "../../../../../../../Configration/Utilities/Constants";
import { CustomMsg } from "../../../../../../../Configration/Utilities/customMessage";
import {
  dateToCompanyFormat,
  dateToSpecificFormat,
} from "../../../../../../../Configration/Utilities/dateFormat";
import moment from "moment";
import Button from "../../../../../../../Framework/Components/Widgets/Button/Button";
const AddCompanyMaster = (props) => {
  const setMsgAlert = props.setMsgAlert;
  const toggleCompanyMasterPopup = props.toggleCompanyMasterPopup;

  const [formValues, setFormValues] = useState({
    txtCompanyCode: "",
    txtCompanyName: "",
    txtDateOfReg: dateToSpecificFormat(moment(), "YYYY-MM-DD"),
    txtPanNO: "",
    txtInvoiceApiReqCount: "",
    txtEwayBillReqCount: "",
    txtUserName: "",
    txtPassword: "",
    txtAddressCode: "",
    txtAddressTitle: "",
    txtGSTIN: "",
  });

  const [fieldPlaceHolder, setPlaceHolder] = useState({});
  const [requireField, setRequireField] = useState({});
  const [maxRegDate, setmaxRegDate] = useState();

  const validateField = (name, value) => {
    let errorMsg = "";
    if (
      name === "txtCompanyCode" ||
      name === "txtCompanyName" ||
      name === "txtDateOfReg" ||
      name === "txtPanNO" ||
      name === "txtInvoiceApiReqCount" ||
      name === "txtEwayBillReqCount" ||
      name === "txtUserName" ||
      name === "txtPassword" ||
      name === "txtAddressCode" ||
      name === "txtAddressTitle" ||
      name === "txtGSTIN"
    ) {
      if (!value || typeof value === "undefined") {
        errorMsg = "Cannot be empty";
      }
    }
    return errorMsg;
  };

  const [formValidationError, setFormValidationError] = useState({});
  const updateState = (name, value) => {
    console.log("name", name, "value", value);
    setFormValues({ ...formValues, [name]: value });
    formValidationError[name] = validateField(name, value);
  };

  useEffect(() => {
    const todayDate = new Date();
    const date = dateToSpecificFormat(todayDate, "YYYY-MM-DD");
    setmaxRegDate(date);
  }, []);

  const [activeTabId, setActiveTabId] = useState("CM");
  return (
    <Modal
      varient={"center"}
      title="Add Company"
      width={"60vw"}
      height={"60vh"}
      show={() => toggleCompanyMasterPopup()}
    >
      <Modal.Body>
        <div className="Biz__Customer_AddPopup_FormContentSectionHead">
          <ul className="Customer_AddPopup__TabPanel">
            <li
              onClick={() => setActiveTabId("CM")}
              className={
                activeTabId === "CM"
                  ? "Customer_AddPopup__TabList ActiveCustomer_AddPopup__TabList"
                  : "Customer_AddPopup__TabList"
              }
            >
              {" "}
              Company{" "}
            </li>
            <li
              onClick={() => setActiveTabId("DT")}
              className={
                activeTabId === "DT"
                  ? "Customer_AddPopup__TabList ActiveCustomer_AddPopup__TabList"
                  : "Customer_AddPopup__TabList"
              }
            >
              {" "}
              Details{" "}
            </li>
            <li
              onClick={() => setActiveTabId("AD")}
              className={
                activeTabId === "AD"
                  ? "Customer_AddPopup__TabList ActiveCustomer_AddPopup__TabList"
                  : "Customer_AddPopup__TabList"
              }
            >
              {" "}
              Address{" "}
            </li>
          </ul>
        </div>
        <div className="Biz__Customer_AddPopup_FormContentPt3">
          <div
            className={
              activeTabId === "CM"
                ? "Biz__Customer_AddPopup_TabContentBox Biz__Customer_AddPopup_TabContentBoxActive"
                : "Biz__Customer_AddPopup_TabContentBox"
            }
          >
            <Form column="2">
              <Form.InputGroup
                errorMsg={formValidationError["txtCompanyCode"]}
                label="Company Code"
                req={false}
              >
                <Form.InputControl
                  control="input"
                  name="txtCompanyCode"
                  type="text"
                  value={formValues.txtCompanyCode}
                  onChange={(e) => updateState(e.target.name, e.target.value)}
                />
              </Form.InputGroup>
              <Form.InputGroup
                errorMsg={formValidationError["txtCompanyName"]}
                label="Company Name"
                req={false}
              >
                <Form.InputControl
                  control="input"
                  name="txtCompanyName"
                  type="text"
                  value={formValues.txtCompanyName}
                  onChange={(e) => updateState(e.target.name, e.target.value)}
                />
              </Form.InputGroup>
              <Form.InputGroup
                errorMsg={formValidationError["txtDateOfReg"]}
                label="Date Of Registration"
                req={false}
              >
                <Form.InputControl
                  control="input"
                  type="date"
                  name="txtDateOfReg"
                  value={formValues.txtDateOfReg}
                  onChange={(e) => updateState(e.target.name, e.target.value)}
                  max={maxRegDate}
                />
              </Form.InputGroup>
            </Form>
          </div>
          <div
            className={
              activeTabId === "DT"
                ? "Biz__Customer_AddPopup_TabContentBox Biz__Customer_AddPopup_TabContentBoxActive"
                : "Biz__Customer_AddPopup_TabContentBox"
            }
          >
            <Form column="2">
              <Form.InputGroup
                errorMsg={formValidationError["txtPanNO"]}
                label="PAN NO"
                req={false}
              >
                <Form.InputControl
                  control="input"
                  name="txtPanNO"
                  type="text"
                  value={formValues.txtPanNO}
                  onChange={(e) => updateState(e.target.name, e.target.value)}
                />
              </Form.InputGroup>
              <Form.InputGroup
                errorMsg={formValidationError["txtInvoiceApiReqCount"]}
                label="Invoice Api Request Count"
                req={false}
              >
                <Form.InputControl
                  control="input"
                  name="txtInvoiceApiReqCount"
                  type="text"
                  value={formValues.txtInvoiceApiReqCount}
                  onChange={(e) => updateState(e.target.name, e.target.value)}
                />
              </Form.InputGroup>
              <Form.InputGroup
                errorMsg={formValidationError["txtEwayBillReqCount"]}
                label="EwayBill Request Count"
                req={false}
              >
                <Form.InputControl
                  control="input"
                  name="txtEwayBillReqCount"
                  type="text"
                  value={formValues.txtEwayBillReqCount}
                  onChange={(e) => updateState("txtEwayBillReqCount", e)}
                />
              </Form.InputGroup>

              <Form.InputGroup
                errorMsg={formValidationError["txtUserName"]}
                label="User Name"
                req={false}
              >
                <Form.InputControl
                  control="input"
                  name="txtUserName"
                  value={formValues.txtUserName}
                  type="text"
                  onChange={(e) => updateState("txtUserName", e)}
                />
              </Form.InputGroup>
              <Form.InputGroup
                errorMsg={formValidationError["txtPassword"]}
                label="Password"
                req={false}
              >
                <Form.InputControl
                  control="input"
                  name="txtPassword"
                  type="text"
                  value={formValues.txtPassword}
                  onChange={(e) => updateState(e.target.name, e.target.value)}
                />
              </Form.InputGroup>
            </Form>
          </div>
          <div
            className={
              activeTabId === "AD"
                ? "Biz__Customer_AddPopup_TabContentBox Biz__Customer_AddPopup_TabContentBoxActive"
                : "Biz__Customer_AddPopup_TabContentBox"
            }
          >
            <Form column="2">
              <Form.InputGroup
                errorMsg={formValidationError["txtAddressCode"]}
                label="Address Code"
                req={false}
              >
                <Form.InputControl
                  control="input"
                  name="txtAddressCode"
                  type="text"
                  value={formValues.txtAddressCode}
                  onChange={(e) => updateState(e.target.name, e.target.value)}
                />
              </Form.InputGroup>
              <Form.InputGroup
                errorMsg={formValidationError["txtAddressTitle"]}
                label="Address Title"
                req={false}
              >
                <Form.InputControl
                  control="input"
                  name="txtAddressTitle"
                  type="text"
                  value={formValues.txtAddressTitle}
                  onChange={(e) => updateState(e.target.name, e.target.value)}
                />
              </Form.InputGroup>
              <Form.InputGroup
                errorMsg={formValidationError["txtGSTIN"]}
                label="GSTIN"
                req={false}
              >
                <Form.InputControl
                  control="input"
                  name="txtGSTIN"
                  type="text"
                  value={formValues.txtGSTIN}
                  onChange={(e) => updateState("txtGSTIN", e)}
                />
              </Form.InputGroup>
            </Form>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" type="submit" trigger={false}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCompanyMaster;
