import { publicIpv4 } from "public-ip";

export async function getIpAddress() {
  try {
    const ip = await publicIpv4();
    return ip ? ip : "";
  } catch (error) {
    return "0.00.00.000";
  }
}
