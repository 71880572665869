import React, { useState, useEffect, useRef } from "react";
import Modal from "../../../../../../../../Framework/Components/Layout/Modal/Modal";
import Form from "../../../../../../../../Framework/Components/Layout/FormGroup/FormGroup";
import Button from "../../../../../../../../Framework/OldFramework/Button/Button";
import { CustomMsg } from "../../../../../../../../Configration/Utilities/customMessage";
import { publicIpv4 } from "public-ip";
import {
  createCustomerAddress,
  getCity,
  updateCustomerAddress,
  updteCustomerAddress,
} from "../../Resources/CustomerService";
import { getSessionStorage } from "../../../../../../../../APIMethods/Auth/auth";
import { CreateAddress } from "../../Resources/CustomerService";

const AddCustomerAddress = (props) => {
  let isEditMode = props.isEditMode;
  let popupData = props.popupData;
  let selectedLocationType = props.selectedLocationType;
  let selectedCustomerMaster = props.selectedCustomerMaster;
  let getCustomerAddressList = props.getCustomerAddressList;
  let toggleAddCustomerAddress = props.toggleAddCustomerAddress;
  let setMsgAlert = props.setMsgAlert;
  let updateAddressList = props.updateAddressList;

  const [locationTypeList, setLocationTypeList] = useState([]);
  const [addressTypeList, setAddressTypeList] = useState([]);
  const [btnloaderActive, setBtnloaderActive] = useState(false);
  const [maxDateOfReg, setMaxDateOfReg] = useState("");
  const [formValues, setFormValues] = useState({
    txtAddressCode: "",
    txtAddressLine1: "",
    txtAddressLine2: "",
    txtCity: null,
    txtPinCode: 0,
    txtCustomerGSTId: 0,
  });

  const [formValidationError, setFormValidationError] = useState({});
  const [editCustomerAddress, setEditCustomerAddress] = useState();

  // const [cityList, setCityList] = useState([]);
  // const [isLoadingCity, setIsLoadingCity] = useState(false);
  // const getCityList = async (countryId, stateId) => {
  //   try {
  //     setIsLoadingCity(true);
  //     let result = await getCity(countryId, stateId);
  //     setIsLoadingCity(false);
  //     if (result.responseCode === 1) {
  //       if (result && result.responseData.length > 0) {
  //         setCityList(result.responseData);
  //       } else {
  //         setCityList([]);
  //       }
  //     } else {
  //       setCityList([]);
  //       console.log(result.responseMessage);
  //     }
  //   } catch (error) {
  //     setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
  //     console.log(error);
  //   }
  // };

  const firstTextInput = useRef();
  // const [isLoadingEditData, setIsLoadingEditData] = useState(false);

  useEffect(() => {
    if (isEditMode) {
      if (selectedCustomerMaster) {
        setEditCustomerAddress(popupData);
      }
    }
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  }, []);

  useEffect(() => {
    if (editCustomerAddress && editCustomerAddress.CustomerAddressID) {
      console.log(editCustomerAddress, "editCustomerAddress");
      setFormValues({
        txtAddressCode: editCustomerAddress.AddressCode
          ? editCustomerAddress.AddressCode
          : "",
        txtAddressLine1: editCustomerAddress.AddressLine1
          ? editCustomerAddress.AddressLine1
          : "",
        txtAddressLine2: editCustomerAddress.AddressLine2
          ? editCustomerAddress.AddressLine2
          : "",
        txtAddressDisplayName: editCustomerAddress.AddressDisplayName
          ? editCustomerAddress.AddressDisplayName
          : "",
        txtPinCode: editCustomerAddress.CityPinCode
          ? editCustomerAddress.CityPinCode
          : "",
        txtCustomerGSTId: editCustomerAddress.CustomerGSTID
          ? editCustomerAddress.CustomerGSTID
          : "",
        // txtCity:
        //   editCustomerAddress.CityMasterID && cityList.length > 0
        //     ? cityList.find(
        //         (x) => x.CityMasterID === editCustomerAddress.CityMasterID
        //       )
        //     : null,
        // isDefaultAddress:
        //   editCustomerAddress.isDefaultAddress === 0 ? false : true,
      });
    }
    console.log(editCustomerAddress, "editCustomerAddress");
  }, [editCustomerAddress]);

  const updateState = (name, value) => {
    console.log("name, value", name, value);
    setFormValues({ ...formValues, [name]: value });
    formValidationError[name] = validateField(name, value);
  };

  const validateField = (name, value) => {
    let errorsMsg = "";
    if (
      name === "txtAddressCode" ||
      name === "txtAddressLine1" ||
      name === "txtCity" ||
      name === "txtPinCode"
    ) {
      if (!value || typeof value === "undefined") {
        errorsMsg = "Cannot be empty";
      }
    }
    return errorsMsg;
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    errors["txtAddressLine1"] = validateField(
      "txtAddressLine1",
      formValues.txtAddressLine1
    );
    // errors["txtCustomerGSTId"] = validateField(
    //   "txtCustomerGSTId",
    //   formValues.txtCustomerGSTId
    // );
    // errors["txtCity"] = validateField("txtCity", formValues.txtCity);
    // errors["txtPinCode"] = validateField("txtPinCode", formValues.txtPinCode);
    if (Object.values(errors).join("").toString()) {
      formIsValid = false;
    }
    setFormValidationError(errors);
    console.log(errors, "errors");
    return formIsValid;
  };

  const handleSave = async (e) => {
    debugger;
    try {
      if (e) {
        e.preventDefault();
      }
      if (!handleValidation()) {
        return;
      }
      props.setConfirmAlert({
        open: true,
        title: "Confirmation",
        msg: isEditMode ? "Are you sure to Update" : "Are you sure to Save ?",
        button: {
          confirmText: isEditMode ? "Update" : "Save",
          abortText: "Cancel",
          Color: "Success",
        },
        onConfirm: () => onSubmit(),
      });
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
    }
  };

  const onSubmit = async () => {
    debugger;
    try {
      const userData = getSessionStorage("user");
      const ip = await publicIpv4();
      const insertUserID = userData.User.user.AppAccessID;
      setBtnloaderActive(true);

      let requestData = {};
      if (isEditMode) {
        requestData = requestData = {
          CustomerAddressID: editCustomerAddress.CustomerAddressID,
          CustomerMasterID: selectedCustomerMaster.CustomerMasterID,
          AddressCode: formValues.txtAddressCode,
          AddressLine1: formValues.txtAddressLine1,
          AddressLine2: formValues.txtAddressLine2,
          CityMasterID: 0,
          CityPinCode: formValues.txtPinCode,
          CustomerGSTID: 0,
          Common: {
            InsertUserID: insertUserID,
            InsertIPAddress: ip,
          },
        };
      } else {
        requestData = requestData = {
          CustomerMasterID: selectedCustomerMaster.CustomerMasterID,
          AddressCode: formValues.txtAddressCode,
          AddressLine1: formValues.txtAddressLine1,
          AddressLine2: formValues.txtAddressLine2,
          CityMasterID: 0,
          CityPinCode: formValues.txtPinCode,
          CustomerGSTID: 0,
          Common: {
            InsertUserID: insertUserID,
            InsertIPAddress: ip,
          },
        };
      }

      let fun = isEditMode ? updateCustomerAddress : createCustomerAddress;
      let result = await fun(requestData);
      console.log(result, "result");
      setBtnloaderActive(false);

      if (result.responseCode === 1) {
        debugger;
        setMsgAlert({
          open: true,
          type: "success",
          msg: result.responseMessage,
        });
        if (result) {
          debugger;

          let addedCode = {
            CustomerAddressID: isEditMode
              ? editCustomerAddress.CustomerAddressID
              : result &&
                result.responseData &&
                result.responseData.CustomerAddressID
              ? result.responseData.CustomerAddressID
              : 0,
            CustomerMasterID: selectedCustomerMaster.CustomerMasterID,
            AddressCode: formValues.txtAddressCode,
            AddressLine1: formValues.txtAddressLine1,
            AddressLine2: formValues.txtAddressLine1,
            CityMasterID: 0,
            CityPinCode: formValues.txtPinCode,
            CustomerGSTID: 0,
            ActiveStatus: null,
            InsertUserID: null,
            InsertIPAddress: null,
            InsertDateTime: "",
          };
          if (isEditMode) {
            props.getCustomerAddressList(
              selectedCustomerMaster.CustomerMasterID
            );
          } else {
            updateAddressList(addedCode);
          }
          clearForm();
          toggleAddCustomerAddress(false, null);
        } else {
          setMsgAlert({
            open: true,
            type: "error",
            msg: result.responseMessage,
          });
        }
      } else {
        setMsgAlert({
          open: true,
          type: "error",
          msg: result.responseMessage,
        });
      }
    } catch (error) {
      setMsgAlert({ open: true, type: "error", msg: CustomMsg.Error });
      console.log(error);
    }
  };

  const clearForm = () => {
    setFormValues({
      txtAddressCode: "",
      txtAddressLine1: "",
      txtAddressLine2: "",
      txtCity: null,
      txtPinCode: "",
      txtCustomerGSTId: 0,
    });
    if (firstTextInput.current) {
      firstTextInput.current.focus();
    }
  };

  // useEffect(() => {
  //   getAddressTypeList();
  //   getCityList(selectedCompanyMaster.CountryMasterID, 0);
  // }, []);

  const [citySearch, setCitySearch] = useState("");

  return (
    <Modal
      varient={"center"}
      title={isEditMode ? "Update Customer Address" : "Add Customer Address"}
      onSubmit={handleSave}
      show={() => toggleAddCustomerAddress(false, null)}
    >
      <Modal.Body>
        <Form>
          <Form.Group controlwidth={"520px"}>
            <Form.InputGroup
              errorMsg={formValidationError["txtAddressCode"]}
              req={true}
              label="AddressCode"
            >
              <Form.InputControl
                label="AddressCode"
                control="input"
                name="txtAddressCode"
                type="text"
                value={formValues.txtAddressCode}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup
              errorMsg={formValidationError["txtAddressLine1"]}
              label="AddressLine 1"
              req={true}
            >
              <Form.InputControl
                control="input"
                label="AddressLine 1"
                name="txtAddressLine1"
                type="text"
                value={formValues.txtAddressLine1}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup
              errorMsg={formValidationError["txtAddressLine2"]}
              label="AddressLine 2"
            >
              <Form.InputControl
                control="input"
                name="txtAddressLine2"
                label="AddressLine 2"
                type="text"
                value={formValues.txtAddressLine2}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup
              errorMsg={formValidationError["txtCity"]}
              label="City"
              req={true}
            >
              <Form.InputControl
                // control="select"
                // getOptionLabel={(option) => `${option.CityName}`}
                // getOptionValue={(option) => `${option}`}
                // options={cityList}
                // onChange={(e) => updateState("txtCity", e)}
                // isLoading={isLoadingCity}
                control="input"
                value={formValues.txtCity}
                label="Select City"
                name="txtCity"
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup
              errorMsg={formValidationError["txtPinCode"]}
              label="PinCode"
              req={true}
            >
              <Form.InputControl
                label="PinCode"
                control="input"
                name="txtPinCode"
                type="text"
                value={formValues.txtPinCode}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup
              errorMsg={formValidationError["txtBizNextSecurityID"]}
              req={true}
              label="BizNext Security"
            >
              <Form.InputControl
                label="BizNext Security ID"
                control="input"
                name="txtBizNextSecurityID"
                type="text"
                value={formValues.txtBizNextSecurityID}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>

            <Form.InputGroup
              errorMsg={formValidationError["txtGSTToken"]}
              req={true}
              label="GST Token"
            >
              <Form.InputControl
                label="GST Token"
                control="input"
                name="txtGSTToken"
                type="text"
                value={formValues.txtGSTToken}
                onChange={(e) => updateState(e.target.name, e.target.value)}
              />
            </Form.InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button varient="secondary" type="submit" trigger={false}>
          {isEditMode ? "Update" : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCustomerAddress;
