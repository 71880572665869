import React from "react";
import "./index.scss";
import App from "./App";
import ReactDOM from "react-dom/client";
import "./Framework/Assets/Style/root.scss";
import "./Framework/Assets/Fonts/Fonts.css";
import { LicenseManager } from "ag-grid-enterprise";
// LicenseManager.setLicenseKey(
//   "For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-11_December_2021_[v2]_MTYzOTE4MDgwMDAwMA==a894581a9ed6fa423b87a98a93baa304"
// );

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
