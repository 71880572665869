const APIEndpoints = {
  baseUrl: process.env.REACT_APP_APIBASEURL,
  NewAuthentication: " https://identity.biznextcontroller.com/api/auth/login",
  Customer: {
    GetCustomerMaster: "customer/get-customer",
    AddCustomer: "customer/create",
    UpdateCustomerData: "customer/update",
    UpdateCustomer: "customer/update-status",
    DeleteCustomer: "customer/delete",
    AddAddress: "customer/add-address",
    GetAddress: "customer/get-address",
    DeleteAddress: "customer/delete-address",
    UpdateAddress: "customer/update-address",
    UpdateGST: "customer/update-gst-master",
    AddGST: "customer/add-gst-master",
    GetGST: "customer/get-gst-master",
    DeleteGST: "customer/delete-gst-master",
    AddSubscription: "customer/add-subscription",
    GetSubscription: "customer/get-subscription",
    DeleteSubsciption: "customer/delete-subscription",
    UpdateSubscription: "customer/update-subscription",
    GetApiCallingReport: "customer/get-api-calling-report",
    GetCityList: "",
    GetAPIMasterList: "customer/get-api-master",
    GetStateMasterList: "customer/get-state-master",
    GetStateList: "",
    ActivateCustomer: "customer/update-status",
    ResetCustomerPassword: "customer/change-password",
  },
};

export default APIEndpoints;
