import React, { useEffect, useMemo } from "react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Error from "../../Components/Layout/ExceptionHandler/ErrorPage/Error";
import { checkAuthExist, getSessionStorage } from "../../APIMethods/Auth/auth";
import { useNavigate } from "react-router-dom";
import ClientConfig from "../ClientConfig/ClientConfig.json";
import { useLocation } from "react-router-dom";

const loggedInUser = getSessionStorage("user");

Sentry.init({
  dsn: "https://cb7e8f6b8a404887a70614399383c4ee@o960544.ingest.sentry.io/4504140531236864",
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  release: `BizNext-${
    ClientConfig.companyCode ? ClientConfig.companyCode : "SPIRALDEV"
  }@${
    loggedInUser && loggedInUser.UserData && loggedInUser.UserData.AppVersion
      ? loggedInUser.UserData.AppVersion
      : "1.0.0"
  }`,
  beforeSend: (event) => {
    if (window.location.hostname === "localhost") {
      return null;
    }
    return event;
  },
  tracesSampleRate: 1.0,
});

function Page(props) {
  const navigate = useNavigate();
  const { title, component } = props;
  const loggedInUser = getSessionStorage("user");
  const location = useLocation();
  console.log(location.pathname, "PathName");

  useMemo(async () => {
    if (!checkAuthExist()) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (!checkAuthExist()) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    Sentry.setUser(null);

    if (loggedInUser) {
      Sentry.setUser({
        "Company Name": loggedInUser.ClientName ? loggedInUser.ClientName : "",
        "User Name": loggedInUser.AppAccessUID ? loggedInUser.AppAccessUID : "",
        "User ID":
          loggedInUser.UserData && loggedInUser.UserData.UserID
            ? loggedInUser.UserData.UserID
            : "",
        "Employee Name":
          loggedInUser.UserData && loggedInUser.UserData.UserDisplayName
            ? loggedInUser.UserData.UserDisplayName
            : "",
        "Application Version":
          loggedInUser.UserData && loggedInUser.UserData.AppVersion
            ? loggedInUser.UserData.AppVersion
            : "",
        Location: location && location.pathname ? location.pathname : "",
      });
    }
  }, []);

  useEffect(() => {
    document.title =
      (title ? title + " | " : "") +
      "BizNext - An Integrated Business Management Platform";
  }, [title]);

  return (
    <Sentry.ErrorBoundary fallback={<Error />}>
      {component}
    </Sentry.ErrorBoundary>
  );
}

export default Page;
