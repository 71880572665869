import React from 'react';
import './notfound.scss';
import { Link, useParams } from 'react-router-dom';

function Notfound() {
  const params = useParams();
  return (
    <div className="Notfound__Div">
      <div className="Notfound__Box">
        <h1>404</h1>
        <p>OOPS! {params.pageName} Page Not Found</p>
        <Link className="Biz__NotBtn" to="/home">
          Back To Home
        </Link>
      </div>
    </div>
  );
}
export default Notfound;
